import * as React from 'react';
import { IListFilterProps } from './props';
import { FilterValue } from '../../core/api/models/saved-filters';
import { useDataLoader } from 'react-remote-data-hooks';
import { IUserWithRole, RequestedBy } from '../../core/api/models';
import { IFilterRequestedByList } from '../../models/order-models';
import { translate } from '../../utils/text-utils';
import { NoResult } from '../grid/no-result';

interface IMultiSelectFilterProps<T = any> extends IListFilterProps<T> {
  allowSearch?: boolean;
  value?: FilterValue[];
  key?: string;
  data: IFilterRequestedByList[];
  showRole?: boolean;
  labelField?: any;
}

const StaffMultiSelectFilter: React.FunctionComponent<
  IMultiSelectFilterProps
> = (props) => {
  const data = props.data;
  const [search, setSearch] = React.useState('');

  const list = search
    ? data?.filter((us: RequestedBy) =>
        us?.[props.labelField ?? 'userName']?.match(new RegExp(search, 'si'))
      )
    : data;

  const [selected, setSelected] = React.useState<FilterValue[]>(
    props.value || []
  );

  React.useEffect(() => {
    setSelected(props.value || []);
  }, [props.value]);

  const onAllSelected = (isSelected: boolean) => {
    const allValues = !isSelected
      ? []
      : list?.map((ff: RequestedBy) => {
          return {
            label: ff?.[props.labelField ?? 'userName'],
            value: ff.userID,
            role: ff.role,
          };
        });

    setSelected(allValues);
  };

  const onSelect = (value: FilterValue, sel: boolean) => {
    if (sel) {
      setSelected([...(selected || []), value]);
    } else {
      setSelected(selected?.filter((val) => val.value !== value.value) || []);
    }
  };

  const onApply = () => {
    setSearch('');
    props.applyFilter?.(selected);
  };

  const onClose = () => {
    setSearch('');
    props.onClose?.();
  };

  return (
    <>
      <div>
        <div className="d-flex align-items-center justify-content-between bg-body p-3">
          <h6 className="m-0 font-md text-gray-700">
            {translate('common.filterBy')} {props.label}
          </h6>
          <span
            className="cursor-pointer d-inline-flex text-center rounded-circle sub-dropdown-back ml-3"
            onClick={onClose}
          >
            <i className="icon-close font-xs text-gray-700"></i>
          </span>
        </div>
        {/* {props.allowSearch && list?.length > 5 && ( */}
        {props.allowSearch && (
          <div className="search">
            <div className="input-group border-bottom">
              <input
                type="text"
                className="form-control border-0 pr-0"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <div className="input-group-append">
                <span className="form-control-search btn btn-link border-0 bg-transparent px-3 py-0" />
              </div>
            </div>
          </div>
        )}
        <ul className="list-unstyled p-2 m-0 custom-scroll-vertical">
          {!!list?.length && (
            <li key="all-0" className="py-2 mb-2">
              <input
                type="checkbox"
                id={`all-${props.label}`}
                onChange={(e) => onAllSelected(e.target.checked)}
                checked={
                  !list.find(
                    (ff: RequestedBy) =>
                      !selected.find((fff) => ff.userID === fff.value)
                  )
                }
              />
              <label
                htmlFor={`all-${props.label}`}
                className="font-xs font-weight-semibold"
              >
                {translate('orders.Select_All')}
              </label>
            </li>
          )}

          {list?.map((option: RequestedBy, index: number) => {
            const label = option?.[props.labelField ?? 'userName'];
            const value = option.userID;
            const role = option.role;

            return (
              <li key={index} className="py-2">
                <input
                  type="checkbox"
                  id={value || props.label}
                  onChange={(e) =>
                    onSelect({ label, value, role }, e.target.checked)
                  }
                  checked={!!selected?.find((fv) => fv.value === value)}
                />
                <label
                  htmlFor={value || props.label}
                  className="font-xs font-weight-semibold"
                >
                  {label} {role && props.showRole ? `(${role})` : ''}
                </label>
              </li>
            );
          })}
          {list?.length === 0 && (
            <div className=" py-2 pl-2 font-xs text-gray-600 mb-2">
              <span className="text-gray-900">
                {translate('orders.No_Result')}
              </span>
            </div>
          )}
        </ul>
        <div className="p-3 text-right">
          <button
            type="button"
            className="btn btn-primary py-2 px-4 font-weight-semibold font-sm"
            onClick={onApply}
          >
            <span className="px-2">{translate('orders.Apply')}</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default StaffMultiSelectFilter;
