import React from 'react';
// import PropTypes, { InferProps } from 'prop-types';
import { Link } from 'react-router-dom';
import { translate } from '../../utils/text-utils';

import './Button.scss';

interface CustomProps {
  link?: string;
  onClick?: () => void;
  size?: number | string;
  light?: boolean;
  btnlink?: boolean;
  expand?: boolean;
  children?: any;
  type?: 'button' | 'submit';
  disabled?: boolean;
  className?: string;
  align?: string;
  capitalize?: boolean;
}

const Button = (props: CustomProps) => {
  // either link or onClick is allowed
  if (props.link && props.onClick) {
    throw new Error('Both link & onClick is not acceptable. Provide either.');
  }
  const classes: string[] = ['Button', `Button--${props.size}`];
  if (props.light) classes.push(`Button--light`);
  if (props.btnlink) classes.push(`Button__link`);
  if (props.capitalize) classes.push(`Button--capitalize`);
  if (props.expand) classes.push(`Button--block`);
  if (props.align) classes.push(`float-${props.align}`);
  if (props.className) classes.push(props.className);
  const className = classes.join(' ');

  if (props.link) {
    return (
      <Link className={className} to={props.link}>
        {props.children}
      </Link>
    );
  }

  return (
    // eslint-disable-next-line react/button-has-type
    <button
      className={className}
      type={props.type}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
};

// Button.propTypes = {
//   size: PropTypes.oneOf(['xsm', 'sm', 'md', 'lg']),
//   children: PropTypes.node.isRequired,
//   link: PropTypes.string,
//   onClick: PropTypes.func,
//   light: PropTypes.bool,
//   disabled: PropTypes.bool,
//   type: PropTypes.oneOf(['button', 'submit']),
//   expand: PropTypes.bool,
//   btnlink: PropTypes.bool,
// };
Button.defaultProps = {
  size: 'md',
  link: null,
  onClick: null,
  light: false,
  disabled: false,
  type: 'button',
  expand: false,
  btnlink: false,
};

interface ButtonBlockCustomProps {
  children?: any;
  align?: 'left' | 'center' | 'right';
  gap?: 'sm' | 'lg';
}

const ButtonBlock: React.SFC<ButtonBlockCustomProps> = (
  props: ButtonBlockCustomProps
) => {
  return (
    <div
      className={`ButtonBlock ButtonBlock--${props.align} ButtonBlock--gap-${props.gap}`}
    >
      {props.children}
    </div>
  );
};

const IconBlock: React.SFC<ButtonBlockCustomProps> = (
  props: ButtonBlockCustomProps
) => {
  return (
    <>
      <i className="icon-download mr-2"></i>{translate("common.download")}
    </>
  );
};

// ButtonBlock.propTypes = {
//   children: PropTypes.element.isRequired,
//   align: PropTypes.oneOf(['left', 'center', 'right']),
//   gap: PropTypes.oneOf(['sm', 'lg']),
// };
ButtonBlock.defaultProps = {
  align: 'left',
  gap: 'sm',
};

export default Button;
export { ButtonBlock, IconBlock };
