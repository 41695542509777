import React from 'react';
// import PropTypes from 'prop-types';

import './TextArea.scss';

interface CustomProps {
  default?: string;
  name?: string;
  placeholder?: string;
  label?: string;
  id?: string;
  gap?: 'sm' | 'md' | 'lg';
  size?: 'lg' | 'sm';
  noBorder?: boolean;
  onChange: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  error?: string;
  disabled?: boolean;
  stickyStyles?: any;
}

interface CustomSate {
  value?: string;
}

class TextArea extends React.Component<CustomProps, CustomSate> {
  static defaultProps: Partial<CustomProps> = {
    default: '',
    name: '',
    label: '',
    placeholder: '',
    gap: 'md',
    onChange: () => {
      return;
    },
    error: '',
    size: 'sm',
    noBorder: false,
    disabled: false,
    stickyStyles: {},
  };

  constructor(props: CustomProps) {
    super(props);
    this.state = {
      value: props.default,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps: CustomProps) {
    if (
      this.props.default !== undefined &&
      prevProps.default !== this.props.default
    ) {
      this.updateDefault();
    }
  }

  updateDefault() {
    if (this.props.default !== undefined) {
      this.setState({ value: this.props.default });
      this.props.onChange(this.props.default);
    }
  }

  handleChange(e: { target: any }) {
    const { value } = e.target;
    this.setState({ value });
    this.props.onChange(value);
  }

  render() {
    const {
      size,
      noBorder,
      name,
      label,
      placeholder,
      gap,
      stickyStyles,
      error,
      disabled,
      onFocus,
      onBlur,
      id,
    } = this.props;
    const extraClassesInput = [];
    if (size === 'lg') extraClassesInput.push('TextArea__field--lg');
    if (noBorder) extraClassesInput.push('TextArea__field--noborder');

    if (disabled) {
      extraClassesInput.push('TextArea__field--disabled');
    }
    const extraClasses: string = extraClassesInput.join(' ');
    const inputIdCandidate = id || name || label || placeholder || '';
    const inputId = inputIdCandidate.toLowerCase().split(' ').join('-');
    return (
      <div className={`TextArea TextArea--margin-${gap}`} style={stickyStyles}>
        {label && (
          <label className="TextArea__label" htmlFor={inputId}>
            {label}
          </label>
        )}
        <textarea
          id={inputId}
          className={`TextArea__input ${extraClasses}`}
          placeholder={placeholder}
          onChange={this.handleChange}
          value={this.state.value}
          disabled={disabled}
          onFocus={onFocus}
          onBlur={onBlur}
          rows={5}
        />
        {error && <span className="TextArea__validation">{error}</span>}
      </div>
    );
  }
}

export default TextArea;
