import { loadingBarReducer } from 'react-redux-loading-bar';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { combineReducers } from 'redux';

import {
  authUserReducer,
  cartReducer,
  productReducer,
  quoteReducer,
  orderReducer,
  brandBranchReducer,
  settingsReducer,
  countriesReducer,
  userToBrandReducer,
  notificationReducer,
} from './reducers';
import { State } from './interfaces';

export const rootReducer = combineReducers<State>({
  authUser: authUserReducer,
  cart: cartReducer,
  quote: quoteReducer,
  product: productReducer,
  toastr: toastrReducer,
  loadingBar: loadingBarReducer,
  order: orderReducer,
  brandAndBranch: brandBranchReducer,
  countries: countriesReducer,
  userToBrand: userToBrandReducer,
  notification: notificationReducer,
  settings: settingsReducer,
});
