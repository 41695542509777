import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../../../components/button';
import { translate } from '../../../../utils/text-utils';

export const InPageConfirmation = (props: {
  text: string;
  onConfirm: () => void;
}) => {
  const history = useHistory();

  return (
    <div className="font-xs text-gray-700 p-4 bg-white shadow font-weight-medium">
      <h6 className="font-base font-weight-medium">{props.text}</h6>
      <div className="row gutter-5 mt-4">
        <div className="col-auto">
          <Button
            size="md"
            className=" Button__primary-outline Button--radius"
            onClick={() => {
              history.goBack();
            }}
          >
            {translate("cart.Cancel")}
          </Button>
        </div>
        <div className="col-auto">
          <Button
            size="md"
            className=" Button__primary Button--radius"
            onClick={() => {
              props.onConfirm();
            }}
          >
            {translate("cart.confirmProceed")}
          </Button>
        </div>
      </div>
    </div>
  );
};
