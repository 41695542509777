import { FilterTypes } from "../../../containers/orders/components/order-filter-component";
import { ApiResponse, EscalationReport, SalesPersonUsage } from "../models";
import ApiService from "./service"
import * as http from './http';
import { CreditExtension, CreditExtensionDetail, DueCustomerDetails, PayCollection, PayCollectionDetail, PaymentDueReport, PaymentDueReportDetails } from "../models/payment-report";


export class PaymentReportSevice extends ApiService {

  // credit extension report

  public async getCreditExtensionReport(params: any): Promise<CreditExtension[]> {
    const url = `${this.apiDomain}/report/creditExtension/`;
    const data = new FormData();
    // if (params?.salesPerson) data.append('salesPerson', params.salesPerson);
    if (params?.searchTerm) data.append('searchTerm', params.searchTerm);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    // data.append('limitFrom', params.limitFrom );
    // data.append('limitTo', params.limitTo );
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    if (params?.day) {
      data.append('day', 'Yes')
    }
    if (params?.month) {
      data.append('month', 'Yes')
    }
    if (params?.quarter) {
      data.append('quarter', 'Yes')
    }
    if (params?.week) {
      data.append('week', 'Yes')
    }
    data.append('brandID', '');
    data.append('branchID', '');
    if (params?.export) {
      data.append('export', 'Yes');
    }

    if (params?.filter) {
      Object.keys(params?.filter)?.forEach((p) => {
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('brandID', value);
          }
        }
        if (p === FilterTypes.branch) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('branchID', value);
          }
        }
        if (p === FilterTypes.salesPerson) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((sales) => {
            if (sales.value) val.push(sales.value);
            return;
          });
          if (val.length > 0) {
            data.append('salesPerson', val.toString());
          }
        }
        if (p === FilterTypes.supervisor) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('supervisor', value);
          }
        }
      });
    }
    const response = await http.postAsFormData<ApiResponse<CreditExtension[]>>(url, data);
    return response?.data?.result;
  }

  public async getCreditExtensionReportDuplicate(params: any): Promise<ApiResponse<CreditExtension[]>> {
    const url = `${this.apiDomain}/report/creditExtension/`;
    const data = new FormData();
    // if (params?.salesPerson) data.append('salesPerson', params.salesPerson);
    if (params?.searchTerm) data.append('searchTerm', params.searchTerm);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    // data.append('limitFrom', params.limitFrom );
    // data.append('limitTo', params.limitTo );
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    if (params?.day) {
      data.append('day', 'Yes')
    }
    if (params?.month) {
      data.append('month', 'Yes')
    }
    if (params?.quarter) {
      data.append('quarter', 'Yes')
    }
    if (params?.week) {
      data.append('week', 'Yes')
    }
    data.append('brandID', '');
    data.append('branchID', '');
    if (params?.export) {
      data.append('export', 'Yes');
    }

    if (params?.filter) {
      Object.keys(params?.filter)?.forEach((p) => {
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('brandID', value);
          }
        }
        if (p === FilterTypes.branch) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('branchID', value);
          }
        }
        if (p === FilterTypes.salesPerson) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((sales) => {
            if (sales.value) val.push(sales.value);
            return;
          });
          if (val.length > 0) {
            data.append('salesPerson', val.toString());
          }
        }
        if (p === FilterTypes.supervisor) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('supervisor', value);
          }
        }
      });
    }
    const response = await http.postAsFormData<ApiResponse<CreditExtension[]>>(url, data);
    return response?.data;
  }

  //get payment due report

  public async getPaymentDueReport(params: any): Promise<ApiResponse<PaymentDueReport[]>> {
    const url = `${this.apiDomain}/report/paymentDue/`;
    const data = new FormData();
    // if (params?.salesPerson) data.append('salesPerson', params.salesPerson);
    if (params?.searchTerm) data.append('searchTerm', params.searchTerm);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    // data.append('limitFrom', params.limitFrom );
    // data.append('limitTo', params.limitTo );
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    data.append('branchID', '');
    data.append('brandID', '');
    if (params?.export) {
      data.append('export', 'Yes');
    }

    if (params?.filter) {
      Object.keys(params?.filter)?.forEach((p) => {
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('brandID', value);
          }
        }
        if (p === FilterTypes.branch) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('branchID', value);
          }
        }
        if (p === FilterTypes.salesPerson) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((sales) => {
            if (sales.value) val.push(sales.value);
            return;
          });
          if (val.length > 0) {
            data.append('salesPerson', val.toString());
          }
        }
        if (p === FilterTypes.supervisor) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('supervisor', value);
          }
        }
      });
    }
    const response = await http.postAsFormData<ApiResponse<PaymentDueReport[]>>(url, data);
    return response?.data;
  }

  // due detail report

  public async getPaymentDueDetailReport(
    params: any
  ): Promise<ApiResponse<PaymentDueReportDetails>> {
    const url = `${this.apiDomain}/report/paymentDueGetOrders/`;
    const data = new FormData();
    if (params?.salesPerson) data.append('salesPerson', params.salesPerson);
    if (params?.searchTerm) data.append('searchTerm', params.searchTerm);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    // data.append('limitFrom', params.limitFrom );
    // data.append('limitTo', params.limitTo );
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    if (params?.userID) data.append('userID', params.userID);
    data.append('brandID', '');
    data.append('branchID', '');
    if (params?.day) {
      data.append('day', 'Yes')
    }
    if (params?.month) {
      data.append('month', 'Yes')
    }
    if (params?.quarter) {
      data.append('quarter', 'Yes')
    }
    if (params?.week) {
      data.append('week', 'Yes')
    }
    if (params?.export) {
      data.append('export', 'Yes');
    }

    if (params?.filter) {
      Object.keys(params?.filter)?.forEach((p) => {
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('brandID', value);
          }
        }
        if (p === FilterTypes.salesPerson) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((sales) => {
            if (sales.value) val.push(sales.value);
            return;
          });
          if (val.length > 0) {
            data.append('salesPerson', val.toString());
          }
        }
      });
    }

    const response = await http.postAsFormData<ApiResponse<PaymentDueReportDetails>>(
      url,
      data
    );
    return response?.data;
  }

  // credit extension detail report
  public async getCreditExtensionDetailReport(
    params: any
  ): Promise<ApiResponse<CreditExtensionDetail>> {
    const url = `${this.apiDomain}/report/getCreditExtensionRequest/`;
    const data = new FormData();
    if (params?.salesPerson) data.append('salesPerson', params.salesPerson);
    if (params?.searchTerm) data.append('searchTerm', params.searchTerm);
    if (params?.status) data.append('status', params.status);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.specificDate) data.append('specificDate', params.specificDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    if (params?.userID) data.append('userID', params.userID);
    data.append('brandID', '');
    data.append('branchID', '');
    if (params?.day) {
      data.append('day', 'Yes')
    }
    if (params?.month) {
      data.append('month', 'Yes')
    }
    if (params?.quarter) {
      data.append('quarter', 'Yes')
    }
    if (params?.week) {
      data.append('week', 'Yes')
    }
    if (params?.export) {
      data.append('export', 'Yes');
    }

    if (params?.filter) {
      Object.keys(params?.filter)?.forEach((p) => {
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('brandID', value);
          }
        }
        if (p === FilterTypes.salesPerson) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((sales) => {
            if (sales.value) val.push(sales.value);
            return;
          });
          if (val.length > 0) {
            data.append('salesPerson', val.toString());
          }
        }
      });
    }

    const response = await http.postAsFormData<ApiResponse<CreditExtensionDetail>>(
      url,
      data
    );
    return response?.data;
  }

  // payment collection report
  public async getPayCollectionReport(params: any): Promise<PayCollection[]> {
    const url = `${this.apiDomain}/report/paymentCollection/`;
    const data = new FormData();
    // if (params?.salesPerson) data.append('salesPerson', params.salesPerson);
    if (params?.searchTerm) data.append('searchTerm', params.searchTerm);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    // data.append('limitFrom', params.limitFrom );
    // data.append('limitTo', params.limitTo );
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    if (params?.day) {
      data.append('day', 'Yes')
    }
    if (params?.month) {
      data.append('month', 'Yes')
    }
    if (params?.quarter) {
      data.append('quarter', 'Yes')
    }
    if (params?.week) {
      data.append('week', 'Yes')
    }
    data.append('brandID', '');
    data.append('branchID', '');
    if (params?.export) {
      data.append('export', 'Yes');
    }

    if (params?.filter) {
      Object.keys(params?.filter)?.forEach((p) => {
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('brandID', value);
          }
        }
        if (p === FilterTypes.branch) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('branchID', value);
          }
        }
        if (p === FilterTypes.salesPerson) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((sales) => {
            if (sales.value) val.push(sales.value);
            return;
          });
          if (val.length > 0) {
            data.append('salesPerson', val.toString());
          }
        }
        if (p === FilterTypes.supervisor) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('supervisor', value);
          }
        }
      });
    }
    const response = await http.postAsFormData<ApiResponse<PayCollection[]>>(url, data);
    return response?.data?.result;
  }

  public async getPayCollectionReportDuplicate(params: any): Promise<ApiResponse<PayCollection[]>> {
    const url = `${this.apiDomain}/report/paymentCollection/`;
    const data = new FormData();
    // if (params?.salesPerson) data.append('salesPerson', params.salesPerson);
    if (params?.searchTerm) data.append('searchTerm', params.searchTerm);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    // data.append('limitFrom', params.limitFrom );
    // data.append('limitTo', params.limitTo );
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    if (params?.day) {
      data.append('day', 'Yes')
    }
    if (params?.month) {
      data.append('month', 'Yes')
    }
    if (params?.quarter) {
      data.append('quarter', 'Yes')
    }
    if (params?.week) {
      data.append('week', 'Yes')
    }
    data.append('brandID', '');
    data.append('branchID', '');
    if (params?.export) {
      data.append('export', 'Yes');
    }

    if (params?.filter) {
      Object.keys(params?.filter)?.forEach((p) => {
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('brandID', value);
          }
        }
        if (p === FilterTypes.branch) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('branchID', value);
          }
        }
        if (p === FilterTypes.salesPerson) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((sales) => {
            if (sales.value) val.push(sales.value);
            return;
          });
          if (val.length > 0) {
            data.append('salesPerson', val.toString());
          }
        }
        if (p === FilterTypes.supervisor) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('supervisor', value);
          }
        }
      });
    }
    const response = await http.postAsFormData<ApiResponse<PayCollection[]>>(url, data);
    return response?.data;
  }

  // payment collection detail
  public async getPayCollectionDetailReport(params: any): Promise<ApiResponse<PayCollectionDetail[]>> {
    const url = `${this.apiDomain}/report/paymentCollectionGetCustomer/`;
    const data = new FormData();
    if (params?.salesPerson) data.append('salesPerson', params.salesPerson);
    if (params?.searchTerm) data.append('searchTerm', params.searchTerm);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    if (params?.sort) data.append('sort', params.sort);
    if (params?.orderby) data.append('orderby', params.orderby);
    data.append('brandID', '');
    data.append('branchID', '');
    if (params?.export) {
      data.append('export', 'Yes');
    }

    if (params?.filter) {
      Object.keys(params?.filter)?.forEach((p) => {
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('brandID', value);
          }
        }
        if (p === FilterTypes.branch) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('branchID', value);
          }
        }
        if (p === FilterTypes.salesPerson) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((sales) => {
            if (sales.value) val.push(sales.value);
            return;
          });
          if (val.length > 0) {
            data.append('salesPerson', val.toString());
          }
        }
      });
    }
    const response = await http.postAsFormData<ApiResponse<PayCollectionDetail[]>>(url, data);
    return response?.data;
  }
  


}