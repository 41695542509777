import React, { Fragment, useState } from 'react';
import Counter from '../../../components/counter';
import { IQuotation } from '../../../core/api/models';
import { useQuotes } from '../../../core/hooks/useQuotes';
import { InActiveUserPopup } from '../../../components/inactive-user-popup';

export const QuoteCounter = (props: {
  item: IQuotation;
  disabled?: boolean;
  quoteUserStatus?: string;
}) => {
  const item = props.item;
  const isDisabled = props.disabled ?? false;
  const { updateQuotation } = useQuotes();
  const [openModal, setOpenModal] = useState<{ isOpen?: boolean; msg?: string; }>({ isOpen: false });

  const onClose = () => {
    setOpenModal({});
  }

  const handleUpdateQuotation = async (items) => {
    const response = await updateQuotation(items);  
    if (response?.errorMsg?.includes("inactive") && response?.response === 'Failure') {   
      setOpenModal(prevState => ({
        ...prevState,
        isOpen: true,
        msg: response?.errorMsg,
      }));    
    }
  };  
  return (
    <Fragment>      
      <Counter
        isLoading={item.isLoading}
        value={item?.quantity!}
        onChange={(count) => {
          const clone = { ...item };
          clone.quantity = count;
          handleUpdateQuotation(clone);
        }}
        isProductAddedToCart={true}
        className="numberField"
        updateValue={false}
        defaultValue={item.quantity}
        disabled={false}
        counterDisabled={isDisabled}
        userStatus={props?.quoteUserStatus}
      />      
      <InActiveUserPopup isOpen={openModal.isOpen} content={openModal.msg || ''} onClose={()=>onClose()} />
    </Fragment>

  );
};
