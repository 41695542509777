import React, { forwardRef } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
// you will need the css that comes with bootstrap@3. if you are using
// a tool like webpack, you can do the following:
// import 'bootstrap/dist/css/bootstrap.css';
// you will also need the css that comes with bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css';
import '../Input.scss';
import './DateRange.scss';
import { addMinutes } from 'date-fns';

interface CustomProps {
  default?: string | Date;
  placeholder?: string;
  onChange: (value: { startDate: Date; endDate: Date }) => void;
  gap?: 'sm' | 'md' | 'lg' | 'no';
  name?: string;
  label?: string;
  error?: string;
  minDate?: Date;
  maxDate?: Date;
  filterDate?: (date: Date) => boolean;
  disabled?: boolean;
  startDate?: Date;
  endDate?: Date;
}

interface CustomState {
  value?: Date;
}

class DateInput extends React.Component<CustomProps, CustomState> {
  static defaultProps: Partial<CustomProps> = {
    gap: 'md',
    name: '',
    label: '',
    error: '',
    placeholder: '',
  };
  constructor(props: CustomProps) {
    super(props);
    this.state = {
      value: this.props.default ? new Date(this.props.default) : undefined,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleEvent = this.handleEvent.bind(this);
  }
  componentDidMount() {
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state = {
      value: this.props.default ? new Date(this.props.default) : undefined,
    };
  }

  componentDidUpdate(prevProps: CustomProps) {
    if (this.props.default && prevProps.default !== this.props.default) {
      this.updateDefault();
    }
  }

  updateDefault() {
    this.setState({ value: new Date(this.props.default || '') });
  }

  normalizeDate = (date: Date) => {
    const d = new Date();

    return addMinutes(date, -1 * d.getTimezoneOffset());
  };

  handleChange(date: Date) {
    this.setState({
      value: date,
    });
  }

  handleEvent(event: any, picker: { startDate: Date; endDate: Date }) {
    this.props.onChange({
      startDate: new Date(picker.startDate),
      endDate: new Date(picker.endDate),
    });
  }

  render() {
    const {
      name,
      label,
      placeholder,
      gap,
      error,
      minDate,
      maxDate,
      filterDate,
      disabled,
      startDate,
      endDate,
    } = this.props;
    const inputIdCandidate = name || label || placeholder || '';
    const inputId = inputIdCandidate.toLowerCase().split(' ').join('-');
    return (
      <div className={`Input Input--gap-${gap}`}>
        {label && (
          <label htmlFor={inputId} className="Input__label Input__label--full">
            {label}
          </label>
        )}

        {/* <DatePicker
          // className="Input__field"
          id={inputId}
          selected={this.state.value}
          onChange={this.handleChange}
          minDate={minDate}
          maxDate={maxDate}
          placeholderText={placeholder}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          dateFormat="dd/MM/yyyy"
          customInput={<CustomDateInput />}
          filterDate={filterDate}
          disabled={disabled}
        /> */}
        <DateRangePicker
          onApply={this.handleEvent}
          initialSettings={{ startDate, endDate }}
        >
          <input type="text" className="form-control" />
        </DateRangePicker>
        {/* <i className="icon-calendar" /> */}
        {error && <span className="Input__error">{error}</span>}
      </div>
    );
  }
}

// DateInput.propTypes = {
//   default: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
//   placeholder: PropTypes.string,
//   onChange: PropTypes.func.isRequired,
//   gap: PropTypes.oneOf(['sm', 'md', 'lg']),
//   name: PropTypes.string,
//   label: PropTypes.string,
//   error: PropTypes.string,
//   minDate: PropTypes.instanceOf(Date),
//   maxDate: PropTypes.instanceOf(Date),
// };

// DateInput.defaultProps = {
//   gap: 'md',
//   default: null,
//   name: '',
//   label: '',
//   error: '',
//   placeholder: '',
//   minDate: null,
//   maxDate: null,
// };

export default DateInput;

// using forwardRef for this issue:
// https://github.com/Hacker0x01/react-datepicker/issues/862
const CustomDateInput = forwardRef((props, ref) => {
  const { id, placeholder, value, onClick }: { [key: string]: any } = props;
  return (
    <div>
      <input
        id={id}
        className="Input__field Input__field--icon"
        placeholder={placeholder}
        onClick={onClick}
        value={value}
        readOnly
      />
      <i className="icon-calendar-sm" />
    </div>
  );
});

// CustomDateInput.propTypes = {
//   id: PropTypes.string.is,
//   placeholder: PropTypes.string,
//   value: PropTypes.string,
//   onClick: PropTypes.func,
// };

// CustomDateInput.defaultProps = {
//   placeholder: '',
//   value: null,
//   id: '',
//   onClick: () => {},
// };
