import React from 'react';
import ReactDOM from 'react-dom';

import './Modal.scss';

enum Position {
  TOP = 'top',
  CENTER = 'center',
}

interface CustomProps {
  pos?: Position;
  width?: number;
  onClose?: () => boolean;
  isOpen?: boolean;
  ref?: any;
  noPadding?: boolean;
  size?: 'sm' | 'md';
  contentClassName?: string;
}

class Modal extends React.Component<CustomProps> {
  private el: any;

  constructor(props: CustomProps) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
    this.el = buildModal({
      width: props.width,
      onClose: props.onClose,
      pos: props.pos ? props.pos : Position.CENTER,
      noPadding: props.noPadding,
      size: props.size,
      contentClassName: props.contentClassName,
    });
  }

  componentDidMount() {
    document.body.append(this.el.modal);

    if (this.el.modalClose) {
      this.el.modalClose.addEventListener('click', this.closeModal);
    }
    this.el.modal.style.display =
      this.props.isOpen === false ? 'none' : 'block';
    if (this.props.isOpen) {
      document.body.style.overflow = 'hidden';
    }
  }

  componentDidUpdate(prevProps: CustomProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      // this.setState({ close: this.props.close });
      this.el.modal.style.display =
        this.props.isOpen === false ? 'none' : 'block';
      document.body.style.overflow = this.props.isOpen ? 'hidden' : 'auto';
    }
  }

  componentWillUnmount() {
    if (this.el.modalClose) {
      this.el.modalClose.removeEventListener('click', this.closeModal);
    }
    // this.el.modal.remove()
    document.body.removeChild(this.el.modal);
  }

  closeModal() {
    if (this.props.onClose) {
      const shouldClose = this.props.onClose();
      if (shouldClose) {
        document.body.style.overflow = 'auto';
        this.el.modal.style.display = 'none';
      }
    }
  }

  render() {
    //   const modal = (
    //     <div id="modal-root" className="Modal">
    //       <div className="Modal__overlay" />
    //       <div className="Modal__frame">
    //         <span className="Modal__close">X</span>
    //         <div className="Modal__content">{this.props.children}</div>
    //         {/* <div className="Modal__footer" /> */}
    //       </div>
    //     </div>
    //   );
    return ReactDOM.createPortal(this.props.children, this.el.modalContent);
  }
}

export default Modal;

interface ModalOptions {
  pos?: Position;
  width?: number;
  onClose?: () => boolean;
  noPadding?: boolean;
  size?: 'sm' | 'md';
  contentClassName?: string;
}
function buildModal(options: ModalOptions = {}) {
  const modal = document.createElement('div');
  modal.className = 'Modal';
  // overlay
  const modalOverlay = document.createElement('div');
  modalOverlay.className = 'Modal__overlay';
  // frame
  const modalFrame = document.createElement('div');
  modalFrame.className = `Modal__frame Modal__frame--${options.pos}`;
  if (options.width) {
    modalFrame.style.width = `${options.width}px`;
  }
  // content
  const modalContent = document.createElement('div');
  modalContent.className = options.noPadding
    ? 'Modal__contentNoPadding'
    : 'Modal__content';

  if (options.size) {
    modalContent.className = `${modalContent.className} Modal__content--sm`;
  }
  if (options.contentClassName) {
    modalContent.className = `${modalContent.className} ${options.contentClassName}`;
  }
  // close
  let modalClose = null;
  if (options.onClose) {
    modalClose = document.createElement('span');
    modalClose.className = 'Modal__close';
    const modalCloseIcon = document.createElement('i');
    modalCloseIcon.className = 'icon-close';
    modalClose.appendChild(modalCloseIcon);
    modalContent.appendChild(modalClose);
  }
  // frame children
  modalFrame.appendChild(modalContent);
  // modal children
  modal.appendChild(modalOverlay);
  modal.appendChild(modalFrame);

  return {
    modal,
    modalOverlay,
    modalFrame,
    modalClose,
    modalContent,
  };
}
