import { createActionWithPayload, IActionWithPayload } from '../utils';
import { IBranch } from '../../../models/branch';

// action types
// Add

const USER_TO_BRAND_GET_ACTION = 'USER_TO_BRAND/GET_ACTION';

const USER_TO_BRAND_SET_LOADER_ACTION = 'USER_TO_BRAND_SET_LOADER_ACTION';

export interface UserToBrandState {
  userToBrand: any;
  isUserToBrandLoading: boolean;
  isUserToBrandLoaded: boolean;
}

type UserToBrandGetActionType = IActionWithPayload<
  typeof USER_TO_BRAND_GET_ACTION,
  any
>;

type BranchLoaderActionType = IActionWithPayload<
  typeof USER_TO_BRAND_SET_LOADER_ACTION,
  boolean
>;

type Actions = UserToBrandGetActionType | BranchLoaderActionType;

// initial state
const initialState: UserToBrandState = {
  userToBrand: [],
  isUserToBrandLoading: false,
  isUserToBrandLoaded: false,
};

export const userToBrandGetAction = (
  userToBrand: any
): UserToBrandGetActionType => {
  return createActionWithPayload(USER_TO_BRAND_GET_ACTION, userToBrand);
};

// reducer
export const userToBrandReducer = (
  state: UserToBrandState = initialState,
  action: Actions
): UserToBrandState => {
  switch (action.type) {
    case USER_TO_BRAND_GET_ACTION:
      const branch = action.payload;
      branch.forEach((user: IBranch) => {
        user.label = user.brandName + ' ' + user.warehouseName;
        user.value = user.warehouseID;
      });
      return {
        ...state,
        userToBrand: branch,
        isUserToBrandLoaded: true,
      };

    default:
      return state;
  }
};
