import React from 'react';
import Header from '.';
interface CustomProps {
  menuVisible?: boolean;
  mobHeader?: boolean;
  showBrand?: boolean;
}

export const UserHeader = (props: CustomProps) => {
  return (
    <Header
      headerType="logged-in"
      menuVisible={props.menuVisible}
      mobHeader={props.mobHeader}
      showBrand={props.showBrand}
    />
  );
};
