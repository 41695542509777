import api from '../../../api';
import {
  Branch,
  CartDetails,
  IBrandBranchData,
  QuoteDetails,
} from '../../../models/brand';
import { createActionWithPayload, IActionWithPayload } from '../utils';

// action types
// Add

const BRANDANDBRANCH_GET_ACTION = 'BRANDANDBRANCH/GET_ACTION';
const BRANDANDBRANCH_BRAND_SELECT_ACTION = 'BRANDANDBRANCH/BRAND_SELECT';
const BRANDANDBRANCH_BRANCH_SELECT_ACTION = 'BRANDANDBRANCH/BRANCH_SELECT';
const BRANDANDBRANCH_SET_LOADER_ACTION = 'BRANDANDBRANCH_SET_LOADER_ACTION';
const BRANDANDBRANCH_CARTDATA_UPDATE_ACTION = 'BRANDANDBRANCH/CARTDATA_UPDATE';
const BRANDANDBRANCH_QUOTDATA_UPDATE_ACTION = 'BRANDANDBRANCH/QUOTDATA_UPDATE';

export interface BrandBranchState {
  brandAdBranch: IBrandBranchData[];
  isBrandAdBranchLoading: boolean;
  isBrandAdBranchLoaded: boolean;
  isAdding: boolean;
  selectedBrand?: IBrandBranchData;
  selectedBranch?: Branch;
}

type BrandAndBranchGetActionType = IActionWithPayload<
  typeof BRANDANDBRANCH_GET_ACTION,
  IBrandBranchData[]
>;
type BrandSelectionActionType = IActionWithPayload<
  typeof BRANDANDBRANCH_BRAND_SELECT_ACTION,
  IBrandBranchData
>;
type BranchSelectionActionType = IActionWithPayload<
  typeof BRANDANDBRANCH_BRANCH_SELECT_ACTION,
  Branch
>;

type BranchLoaderActionType = IActionWithPayload<
  typeof BRANDANDBRANCH_SET_LOADER_ACTION,
  boolean
>;

type BranchCartUpdateActionType = IActionWithPayload<
  typeof BRANDANDBRANCH_CARTDATA_UPDATE_ACTION,
  { cartDetails: CartDetails; brandId: string; branchId: string }
>;

type BranchQuotUpdateActionType = IActionWithPayload<
  typeof BRANDANDBRANCH_QUOTDATA_UPDATE_ACTION,
  { quotDetails: QuoteDetails; brandId: string; branchId: string }
>;

type Actions =
  | BrandAndBranchGetActionType
  | BrandSelectionActionType
  | BranchSelectionActionType
  | BranchCartUpdateActionType
  | BranchQuotUpdateActionType
  | BranchLoaderActionType;

// initial state
const initialState: BrandBranchState = {
  brandAdBranch: [],
  isBrandAdBranchLoading: false,
  isBrandAdBranchLoaded: false,
  isAdding: false,
  selectedBrand: undefined,
  selectedBranch: undefined,
};

export const brandAndBranchGetAction = (
  brandAdBranch: IBrandBranchData[]
): BrandAndBranchGetActionType => {
  return createActionWithPayload(BRANDANDBRANCH_GET_ACTION, brandAdBranch);
};

export const brandSelectAction = (
  brand: IBrandBranchData
): BrandSelectionActionType => {
  return createActionWithPayload(BRANDANDBRANCH_BRAND_SELECT_ACTION, brand);
};

export const brandSetLoaderAction = (
  isLoading: boolean
): BranchLoaderActionType => {
  return createActionWithPayload(BRANDANDBRANCH_SET_LOADER_ACTION, isLoading);
};

export const branchSelectAction = (
  branch: Branch
): BranchSelectionActionType => {
  return createActionWithPayload(BRANDANDBRANCH_BRANCH_SELECT_ACTION, branch);
};

export const branchCartUpadateAction = (
  cartDetails: any
): BranchCartUpdateActionType => {
  return createActionWithPayload(
    BRANDANDBRANCH_CARTDATA_UPDATE_ACTION,
    cartDetails
  );
};

export const branchQuotUpdateAction = (
  quotDetails: any
): BranchQuotUpdateActionType => {
  return createActionWithPayload(
    BRANDANDBRANCH_QUOTDATA_UPDATE_ACTION,
    quotDetails
  );
};

// reducer
export const brandBranchReducer = (
  state: BrandBranchState = initialState,
  action: Actions
): BrandBranchState => {
  switch (action.type) {
    case BRANDANDBRANCH_GET_ACTION:
      const selectedBrand = state?.selectedBrand
        ? action.payload.find(
            (brand: IBrandBranchData) =>
              brand.brandID === state?.selectedBrand?.brandID
          ) ?? action.payload[0]
        : action.payload[0];
      const selectedBranch = selectedBrand
        ? selectedBrand?.branches?.find(
            (branch: Branch) =>
              branch.warehouseID === state?.selectedBranch?.warehouseID
          ) ?? selectedBrand?.branches[0]
        : action.payload[0]?.branches[0];
      api.setBrandID(selectedBrand?.brandID);
      api.setBranchID(selectedBranch?.warehouseID);
      return {
        ...state,
        brandAdBranch: action.payload,
        isBrandAdBranchLoaded: true,
        isBrandAdBranchLoading: false,
        selectedBrand,
        selectedBranch,
      };
    case BRANDANDBRANCH_BRAND_SELECT_ACTION:
      api.setBrandID(action?.payload?.brandID);
      api.setBranchID(action.payload?.branches[0]?.warehouseID ?? '');
      return {
        ...state,
        selectedBrand: action?.payload,
        selectedBranch: action.payload?.branches[0],
      };
    case BRANDANDBRANCH_SET_LOADER_ACTION:
      return {
        ...state,
        isBrandAdBranchLoading: action.payload,
      };
    case BRANDANDBRANCH_BRANCH_SELECT_ACTION:
      api.setBranchID(action.payload.warehouseID);
      return {
        ...state,
        selectedBranch: action.payload,
      };
    case BRANDANDBRANCH_CARTDATA_UPDATE_ACTION:
      const brands = state.brandAdBranch;
      const sBrand = state.selectedBrand;
      const brandIndx = state.brandAdBranch?.findIndex(
        (item) => item.brandID === action.payload.brandId
      );
      if (brandIndx !== undefined) {
        const branchIndx = brands[brandIndx]?.branches.findIndex(
          (itm) => itm.warehouseID === action.payload.branchId
        );
        if (
          branchIndx !== undefined &&
          brands[brandIndx]?.branches[branchIndx]
        ) {
          brands[brandIndx].branches[branchIndx].cartDetails =
            action.payload.cartDetails;
          if (sBrand) {
            sBrand.branches[branchIndx].cartDetails = {
              ...action.payload.cartDetails,
            };
          }
        }
      }
      const selectedBr = state.selectedBranch;
      if (selectedBr) {
        selectedBr.cartDetails = action.payload.cartDetails;
      }
      return {
        ...state,
        brandAdBranch: brands,
        selectedBrand: sBrand,
        selectedBranch: selectedBr,
      };
    case BRANDANDBRANCH_QUOTDATA_UPDATE_ACTION:
      const brnds = state.brandAdBranch;
      const sqBrand = state.selectedBrand;
      const brndIndx = state.brandAdBranch?.findIndex(
        (item) => item.brandID === action.payload.brandId
      );
      if (brndIndx !== undefined) {
        const branchIndx = brnds[brndIndx]?.branches.findIndex(
          (itm) => itm.warehouseID === action.payload.branchId
        );
        if (branchIndx !== undefined && brnds[brndIndx]?.branches[branchIndx]) {
          brnds[brndIndx].branches[branchIndx].quotDetails =
            action.payload.quotDetails;
          if (sqBrand) {
            sqBrand.branches[branchIndx].quotDetails =
              action.payload.quotDetails;
          }
        }
      }
      const selectedBrn = state.selectedBranch;
      if (selectedBrn) {
        selectedBrn.quotDetails = action.payload.quotDetails;
      }

      return {
        ...state,
        brandAdBranch: brnds,
        selectedBrand: sqBrand,
        selectedBranch: selectedBrn,
      };

    default:
      return state;
  }
};
