import { useEffect } from 'react';
import api from '../../api';
import { useBrandsAndBranches } from './useBrandsAndBranches';
import { useCart } from './useCart';
import { useCountries } from './useCountries';
import { useNotifications } from './useNotifications';
import { useOrder } from './useOrder';
import { useQuotes } from './useQuotes';
import { useUserToBrand } from './useUserToBrand';

export const useInitiate = () => {
  const { getBrandAndBranches } = useBrandsAndBranches();
  const { getCountries } = useCountries();
  const { getUserToBrand } = useUserToBrand();
  const { loadFilterData } = useOrder(api.order);
  const { getCart } = useCart();
  const { getQuotations } = useQuotes();

  const getInitialData = async (userToken?: string) => {
    getBrandAndBranches(userToken);
    getCountries();
    getUserToBrand();
    loadFilterData();
    //  getCart(),
    //  getQuotations()
  };

  return {
    getInitialData,
  };
};
