import i18n from '../i18n';
export const capitalizeFirstLetter = (text: string) => {
  if (!text) {
    return text;
  }
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const capitalizeAllFirstLettersOfWords = (text: string) => {
  if (!text) {
    return text;
  }
  return text.split(' ').map(capitalizeFirstLetter).join(' ');
};

export const convertPascalCaseToWords = (value?: string) => {
  if (!value) {
    return value;
  }
  return (
    value
      ?.toLocaleLowerCase()
      // Look for long acronyms and filter out the last letter
      .replace(/([A-Z]+)([A-Z][a-z])/g, ' $1 $2')
      // Look for lower-case letters followed by upper-case letters
      .replace(/([a-z\d])([A-Z])/g, '$1 $2')
      // Look for lower-case letters followed by numbers
      .replace(/([a-zA-Z])(\d)/g, '$1 $2')
      .replace(/^./, (str) => {
        return str.toUpperCase();
      })
      // Remove any white space left around the word
      .trim()
  );
};

export function isNullOrEmpty(value?: string) {
  return (
    value === undefined || value == null || value === '' || value.length === 0
  );
}

export function translate(key: string, config?: any) {
  return i18n.t(key, config);
}
