import PasswordInput from './password-input';
import TextInput from './text-input';
import PhoneInput from './phone-input';
import DateInput from './date-input/DateInput';
import SwitchInput from './switch-input';
import TextArea from './text-area';
import PhoneInputLimited from './phone-input/PhoneInputLimited';
import SelectInput from './select-input';
import SwitchInputSingleLabel from './switch-input/SwitchInputSingleLabel';
import {RadioSelect} from './radio-select';

export default {
  TextInput,
  PasswordInput,
  PhoneInput,
  DateInput,
  PhoneInputLimited,

  SwitchInput,
  TextArea,
  SelectInput,
  SwitchInputSingleLabel,
  RadioSelect
};

export {
  TextInput,
  PasswordInput,
  PhoneInput,
  PhoneInputLimited,
  DateInput,
  SwitchInput,
  TextArea,
  SelectInput,
  SwitchInputSingleLabel,
  RadioSelect
};
