import api from '../../../api';
import {
  ISearchParams,
  Model,
  MyList,
  Product,
  ProductFilter,
  ProductResponse,
  User,
} from '../../api/models';
import { Dict } from '../../models';
import {
  createAction,
  createActionWithPayload,
  IAction,
  IActionWithPayload,
} from '../utils';

// GET
const PRODUCT_GET_ACTION = 'PRODUCT/GET_ACTION';
const PRODUCT_REQUEST_ACTION = 'PRODUCT/REQUEST_ACTION';
const PRODUCT_RESET_ACTION = 'PRODUCT/RESET_ACTION';
const SET_PRODUCT_SEARCH_PARAM = 'PRODUCT_SEARCH_PARAM';

// GET
const PRODUCT_SET_MODEL = 'PRODUCT/SET_MODEL';
const PRODUCT_SET_FILTER = 'PRODUCT/SET_FILTER';
const PRODUCT_SET_MODEL_PARAM = 'PRODUCT/SET_MODEL_PARAM';
const PRODUCT_SET_BRAND_PARAM = 'PRODUCT/SET_BRAND_PARAM';

export interface ProductState {
  carFilters?: ProductFilter[];
  result?: Product[];
  totalRecord?: number;
  errorMsg?: string;
  status?: string;
  count?: number;
  isProductLoading: boolean;
  isProductLoaded: boolean;
  page: number;
  modelDetails?: Model;
  selectedFilter?: ProductFilter[];
  brand?: string;
  model?: string;
  searchParams?: ISearchParams;
}

type ProductGetActionType = IActionWithPayload<
  typeof PRODUCT_GET_ACTION,
  ProductResponse
>;

type ProductRequestActionType = IActionWithPayload<
  typeof PRODUCT_REQUEST_ACTION,
  boolean
>;

type ProductResetActionType = IAction<typeof PRODUCT_RESET_ACTION>;

type ProductSetModelActionType = IActionWithPayload<
  typeof PRODUCT_SET_MODEL,
  Model
>;

type ProductSetModelParamActionType = IActionWithPayload<
  typeof PRODUCT_SET_MODEL_PARAM,
  string
>;

type ProductSetBrandParamActionType = IActionWithPayload<
  typeof PRODUCT_SET_BRAND_PARAM,
  string
>;

type ProductSetFilterActionType = IActionWithPayload<
  typeof PRODUCT_SET_FILTER,
  ProductFilter[]
>;

type ProductSearchParamActionType = IActionWithPayload<
  typeof SET_PRODUCT_SEARCH_PARAM,
  ISearchParams
>;

type Actions =
  | ProductGetActionType
  | ProductRequestActionType
  | ProductSetModelActionType
  | ProductSetFilterActionType
  | ProductSetModelParamActionType
  | ProductSetBrandParamActionType
  | ProductResetActionType
  | ProductSearchParamActionType;

// initial state
const initialState: ProductState = {
  isProductLoading: false,
  isProductLoaded: false,
  page: 1,
};

export const productGetAction = (
  response: ProductResponse
): ProductGetActionType => {
  return createActionWithPayload(PRODUCT_GET_ACTION, response);
};

export const productSetModelAction = (
  response?: Model
): ProductSetModelActionType => {
  return createActionWithPayload(PRODUCT_SET_MODEL, response!);
};

export const productSetFilterAction = (
  response?: ProductFilter[]
): ProductSetFilterActionType => {
  return createActionWithPayload(PRODUCT_SET_FILTER, response!);
};

export const productRequestAction = (
  loading?: boolean
): ProductRequestActionType => {
  return createActionWithPayload(PRODUCT_REQUEST_ACTION, loading);
};

export const productResetAction = (): ProductResetActionType => {
  return createAction(PRODUCT_RESET_ACTION);
};

export const productSetModelParamAction = (
  response?: string
): ProductSetModelParamActionType => {
  return createActionWithPayload(PRODUCT_SET_MODEL_PARAM, response!);
};

export const productSetBrandParamAction = (
  response?: string
): ProductSetBrandParamActionType => {
  return createActionWithPayload(PRODUCT_SET_BRAND_PARAM, response!);
};

export const setProductSearchParamsAction = (
  params: ISearchParams
): ProductSearchParamActionType => {
  return createActionWithPayload(SET_PRODUCT_SEARCH_PARAM, params);
};

// reducer
export const productReducer = (
  state: ProductState = initialState,
  action: Actions
): ProductState => {
  switch (action.type) {
    case PRODUCT_GET_ACTION:
      const products =
        Number(action.payload.page) <= 1
          ? [...action.payload.result]
          : [...(state.result ? state.result : []), ...action.payload.result];

      return {
        ...state,
        carFilters: action.payload.carFilters,
        result: products,
        totalRecord: action.payload.totalRecord,
        errorMsg: action.payload.errorMsg,
        count: action.payload.count,
        status: action.payload.status,
        isProductLoading: false,
        isProductLoaded: true,
        page: action.payload.page || 1,
        selectedFilter: action.payload.selectedFilter,
      };
    case PRODUCT_REQUEST_ACTION:
      return {
        ...state,
        isProductLoading: action.payload,
        isProductLoaded: !action.payload,
      };
    case PRODUCT_RESET_ACTION:
      return {
        ...state,
        result: [],
        carFilters: [],
        totalRecord: 0,
        page: 1,
        count:0,
        selectedFilter: [],
        isProductLoading: false,
        isProductLoaded: false,
      };
    case PRODUCT_SET_MODEL:
      return {
        ...state,
        modelDetails: action.payload,
      };
    case PRODUCT_SET_FILTER:
      return {
        ...state,
        selectedFilter: action.payload,
      };
    case PRODUCT_SET_MODEL_PARAM:
      return {
        ...state,
        model: action.payload,
      };
    case PRODUCT_SET_BRAND_PARAM:
      return {
        ...state,
        brand: action.payload,
      };
    case SET_PRODUCT_SEARCH_PARAM:
      return {
        ...state,
        searchParams: action.payload,
      };
    default:
      return state;
  }
};
