import api from '../../../api';

import { createActionWithPayload, IActionWithPayload } from '../utils';

// action types
// Add

const COUNTRIES_GET_ACTION = 'COUNTRIES/GET_ACTION';

const COUNTRIES_SET_LOADER_ACTION = 'COUNTRIES_SET_LOADER_ACTION';

export interface CountriesState {
  countries: any;
  isCountriesLoading: boolean;
  isCountriesLoaded: boolean;
}

type CountriesGetActionType = IActionWithPayload<
  typeof COUNTRIES_GET_ACTION,
  any
>;



type BranchLoaderActionType = IActionWithPayload<
  typeof COUNTRIES_SET_LOADER_ACTION,
  boolean
>;


type Actions =
  | CountriesGetActionType
  | BranchLoaderActionType;

// initial state
const initialState: CountriesState = {
  countries: [],
  isCountriesLoading: false,
  isCountriesLoaded: false,
  
};

export const countriesGetAction = (
  countries: any
): CountriesGetActionType => {
  return createActionWithPayload(COUNTRIES_GET_ACTION, countries);
};


// reducer
export const countriesReducer = (
  state: CountriesState = initialState,
  action: Actions
): CountriesState => {
  switch (action.type) {
    case COUNTRIES_GET_ACTION:
      return {
        ...state,
        countries: action.payload,
        isCountriesLoaded: true,
      };

    default:
      return state;
  }
};
