import React, { FC, useState } from 'react';
import Modal from './modal';

interface UserPopUpPropsList {
  isOpen: boolean;
  content: string;
  onClose: () => void;
}

export const InActiveUserPopup: FC<UserPopUpPropsList> = (props) => {
  return (
    <Modal isOpen={props?.isOpen || false} contentClassName="alertPopup p-0">
      <div className="align-items-center d-flex justify-content-end pb-1 pt-3 px-3 text-right">
        {/* <h5 className="m-0 text-warning">Warning</h5> */}
        <span className="p-2">
          <i
            className="icon-close text-gray-600 font-sm font-weight-bold cursor-pointer"
            onClick={props.onClose}
          ></i>
        </span>
      </div>
      <div className="pb-3 px-3 px-sm-4">
        <div className="mb-4 text-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
          >
            <path
              d="M48 24C48 10.7451 37.2551 0 24.0002 0C10.7452 0 0 10.7451 0 24C0 37.2549 10.7452 48 24.0002 48C29.3852 48 34.3556 46.2262 38.3603 43.2314L46.4371 46.3499L44.3617 36.7086C46.6672 33.0234 48 28.6677 48 24ZM23.96 38.0574C21.9368 38.0574 20.2968 36.4859 20.2968 34.5475C20.2968 32.6091 21.9368 31.0376 23.96 31.0376C25.9832 31.0376 27.6234 32.609 27.6234 34.5475C27.6234 36.4859 25.9832 38.0574 23.96 38.0574ZM26.7027 29.12H21.2973L19.4937 8.96H28.4746L26.7027 29.12Z"
              fill="#FCC026"
            ></path>
          </svg>
        </div>
        <div className="Verification__content text-center">
          <h6 className="mb-4 pb-1 font-weight-light">
            {props?.content || ''}
          </h6>
        </div>
      </div>
    </Modal>
  );
};
