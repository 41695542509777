import { useDispatch, useSelector } from 'react-redux';
import { State } from '../store/interfaces';
import api from '../../api';
import {
  markAllAsReadAction,
  onGetNotificationSuccessAction,
  setLoaderForNotificationAction,
} from '../store';
import { toastr } from 'react-redux-toastr';
import { useAuth } from './useAuth';

export const useNotifications = () => {
  const notificationState = useSelector((state: State) => state.notification);
  const { isAuthenticated } = useAuth();
  const dispatch = useDispatch();

  const loadNotifications = async () => {
    if (isAuthenticated) {
      try {
        dispatch(setLoaderForNotificationAction(true));
        const response = await api.notification.loadNotifications();
        if (response.result) {
          dispatch(onGetNotificationSuccessAction(response.result?.list ?? []));
        }
      } catch (error) {
        dispatch(setLoaderForNotificationAction(false));
      } finally {
        dispatch(setLoaderForNotificationAction(false));
      }
    }
  };

  const markAsRead = async (ids: string) => {
    try {
      const isSuccess = await api.notification.markAsRead(ids);
      if (isSuccess) {
        dispatch(markAllAsReadAction());
      } else {
        toastr.error('Error', 'Unable to mark as read');
      }
    } catch (error) {
      toastr.error('Error', 'Unable to mark as read');
    }
  };

  const registerDeviceToken = async (deviceToken: string) => {
    try {
      const isSuccess = await api.notification.registerToken(
        deviceToken,
        'Web'
      );
      if (!isSuccess) {
        toastr.error('Error', 'Unable to register device token');
      }
    } catch (error) {
      toastr.error('Error', 'Unable to register device token');
    }
  };

  return {
    registerDeviceToken,
    notificationState,
    loadNotifications,
    markAsRead,
  };
};
