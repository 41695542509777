import React from 'react';

import './input.scss';
import './SwitchInput.scss';

interface CustomProps {
  default: boolean;
  name: string;
  placeholder: string;
  onChange: (value: boolean) => void;
  gap: string;
  newLine: boolean;
  label: string;
  error: string;
  disabled: boolean;
}
interface CustomState {
  value: any;
}

class Checkbox extends React.Component<CustomProps, CustomState> {
  static defaultProps: Partial<CustomProps> = {
    default: false,
    name: '',
    gap: 'lg',
    newLine: false,
    placeholder: '',
    label: '',
    error: '',
    disabled: false,
  };
  constructor(props: CustomProps) {
    super(props);
    this.state = {
      value: props.default,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange() {
    this.setState((prevState) => {
      return {
        value: !prevState.value,
      };
    });
    this.props.onChange(!this.state.value);
  }

  render() {
    const inputIdCandidate =
      this.props.name || this.props.label || this.props.placeholder;
    const inputId = inputIdCandidate;
    return (
      <div
        className={`textField textField--margin-${this.props.gap} ${
          !this.props.newLine ? 'switch__grow' : ''
        }`}
      >
        {this.props.label && (
          <label htmlFor={inputId} className="textField__label">
            {this.props.label}
          </label>
        )}
        <label className="switch m-0" htmlFor={`switch-checkbox-${this.props.name}`}>
          <input
            className="switch__input"
            id={`switch-checkbox-${this.props.name}`}
            type="checkbox"
            //  checked={this.props.default}
            onChange={this.handleChange}
            disabled={this.props.disabled}
          />
          <span className="label on"></span>
          <span className="slider round"></span>
          <span className="label off"></span>
        </label>

        {this.props.error && (
          <span className="textField__error">{this.props.error}</span>
        )}
      </div>
    );
  }
}

export default Checkbox;
