import React from 'react';
import { translate } from '../../utils/text-utils';
import Button from '../button';
import Modal from '../modal';

interface Props {
  open: boolean;
  title: string;
  message?: string;
  hideCancel?: boolean;
  onConfirm: () => void;
  onDismiss: () => void;
}

const ConfirmationDialog = ({
  open,
  title,
  message,
  onConfirm,
  onDismiss,
  hideCancel,
}: Props) => {
  return (
    <Modal
      isOpen={open}
      size="sm"
      onClose={() => {
        onDismiss();
        return true;
      }}
    >
      <div className="pt-4 text-center">
        <div className="Verification__content h6 mb-4 pb-2">
          {title}
        </div>

        <div className="row gutter-5 justify-content-center">
          <div className="col-auto">
            <Button
              size="md"
              className=" Button__primary-outline Button--radius"
              onClick={() => {
                onDismiss();
              }}
            >
              {translate("common.cancel")}
            </Button>
          </div>
          <div className="col-auto">
            <Button
              size="md"
              className=" Button__primary Button--radius"
              onClick={() => {
                onConfirm();
              }}
            >
              {translate("common.confirm")}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const ConfirmationDialogContext = React.createContext({});

const ConfirmationDialogProvider = ({ children }: any) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogConfig, setDialogConfig] = React.useState<any>({});

  const openDialog = ({ title, message, actionCallback, hideCancel }: any) => {
    setDialogOpen(true);
    setDialogConfig({ title, message, actionCallback, hideCancel });
  };

  const resetDialog = () => {
    setDialogOpen(false);
    setDialogConfig({});
  };

  const onConfirm = () => {
    resetDialog();
    dialogConfig.actionCallback(true);
  };

  const onDismiss = () => {
    resetDialog();
    dialogConfig.actionCallback(false);
  };

  return (
    <ConfirmationDialogContext.Provider value={{ openDialog }}>
      <ConfirmationDialog
        open={dialogOpen}
        title={dialogConfig?.title}
        message={dialogConfig?.message}
        hideCancel={dialogConfig?.hideCancel}
        onConfirm={onConfirm}
        onDismiss={onDismiss}
      />
      {children}
    </ConfirmationDialogContext.Provider>
  );
};

const useConfirmationDialog = () => {
  const { openDialog }: any = React.useContext(ConfirmationDialogContext);

  const getConfirmation = ({
    ...options
  }: {
    title: string;
    message?: string;
    hideCancel?: boolean;
  }) =>
    new Promise((res) => {
      openDialog({ actionCallback: res, ...options });
    });

  return { getConfirmation };
};

export default ConfirmationDialog;
export { ConfirmationDialogProvider, useConfirmationDialog };
