import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toastr } from 'react-redux-toastr';
import { translate } from '../../utils/text-utils';

interface CopyClipboardProps {
  value?: string;
  children?: any;
  onAdd?: () => void;
}
const CopyClipboard: React.SFC<CopyClipboardProps> = (
  props: CopyClipboardProps
) => {
  return (
    <CopyToClipboard
      text={props.value || ''}
      onCopy={() => {
        toastr.success('Success', translate("toast.copiedToClipboard"));
        props.onAdd?.();
      }}
    >
      <span> {props.children}</span>
    </CopyToClipboard>
  );
};

export default CopyClipboard;
