import axios, { AxiosRequestConfig } from 'axios';
import { ApiServiceDataStore } from '../data';
import { saveAs } from 'file-saver';
import api from '../../../api';
import { UserType } from '../models';

export function getAuthHeader() {
  const userToken = api.getUserToken();
  const salespersonToken = api.getSalespersonToken();
  const userType = api.getUserType();
  let token: string | undefined;
  // if (userType) {
  //   if (userType === UserType.Salesperson) {
  //     token = salespersonToken;
  //   } else {
  //     token = userToken;
  //   }
  // }
  token = undefined;
  if (!token) {
    return {};
  }
  return {
    Authorization: `Bearer ${token}`,
  };
}

export async function get<T>(url: string, store?: ApiServiceDataStore) {
  const options = {
    headers: {
      ...getAuthHeader(),
    },
  };
  return axios.get<T>(url, options);
}

export async function post<T = any, U = any>(
  url: string,
  data: U,
  store?: ApiServiceDataStore
) {
  const options: AxiosRequestConfig = {
    data,
    url,
    method: 'POST',
    headers: {
      ...getAuthHeader(),
      'Content-Type': 'application/json',
    },
  };
  return axios.post<T>(url, JSON.stringify(data), options);
}

export async function postAsFormData<T = any, U = any>(
  url: string,
  data: FormData,
  includeUserToken?: boolean
) {
  const userToken = api.getUserToken();
  const salespersonToken = api.getSalespersonToken();
  const options: AxiosRequestConfig = {
    headers: {
      ...getAuthHeader(),
      'Content-Type': 'multipart/form-data',
    },
  };

  const brandID = api.getBrandID();
  const branchID = api.getBranchID();
  const languageID = api.getLanguageID();
  if (!data.has('userToken') && userToken) {
    if (salespersonToken) {
      if (includeUserToken) {
        data.append('userToken', userToken);
      }
    } else {
      data.append('userToken', userToken);
    }
  }
  if (!data.has('salespersonToken') && salespersonToken) {
    data.append('salespersonToken', salespersonToken);
  }
  if (!data.has('brandID') && brandID) {
    data.append('brandID', brandID);
  }
  if (!data.has('branchID') && branchID) {
    data.append('branchID', branchID);
  }
  data.append('languageID', languageID.toString());
  data.append('website_id', '1');
  data.append('subsite_id', '1');
  const filteredData = new FormData();
  for (const [key, value] of data) {
    if (value) {
      filteredData.append(key, value);
    }
  }
  return axios.post<T>(url, filteredData, options);
}

export async function put<T = any, U = any>(
  url: string,
  data: U,
  store?: ApiServiceDataStore
) {
  const options: AxiosRequestConfig = {
    headers: {
      ...getAuthHeader(),
      'Content-Type': 'application/json',
    },
  };
  return axios.put<T>(url, JSON.stringify(data), options);
}

export async function remove<T>(url: string, store?: ApiServiceDataStore) {
  const options: AxiosRequestConfig = {
    headers: {
      ...getAuthHeader(),
    },
  };
  return axios.delete<T>(url, options);
}

export async function upload<T>(
  url: string,
  data: FormData,
  onUploadProgress?: (progressEvent: any) => void,
  store?: ApiServiceDataStore
) {
  const options: AxiosRequestConfig = {
    // onUploadProgress: (progressEvent: any) => {
    // },
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  return axios.post<T>(url, data);
}

export async function download(
  url: string,
  store?: ApiServiceDataStore,
  accept?: string
) {
  const options: AxiosRequestConfig = {
    headers: {
      ...getAuthHeader(),
      Accept: accept,
    },
    responseType: 'arraybuffer',
  };
  return axios.get(url, options);
}

export async function downloadPostData<T>(
  url: string,
  data: T,
  store?: ApiServiceDataStore,
  accept?: string
) {
  const options: AxiosRequestConfig = {
    headers: {
      ...getAuthHeader(),
      Accept: accept,
    },
    responseType: 'arraybuffer',
  };
  return axios.post(url, data, options);
}

export const saveDownloadedFile = (
  buffer: any,
  type: string,
  fileName: string
) => {
  const data = new Blob([buffer], {
    type,
  });
  saveAs(data, fileName);
};
