import { IOrderListParams } from '../../../models/order-models';
import { ApiResponse } from '../models';
import { IDuePaymentListResult } from '../models/payment-models';
import * as http from './http';
import ApiService from './service';

export class CreditlineExtensionRequest extends ApiService {
  public async getCreditlineExtensionRequests(params): Promise<any> {

    const url = `${this.apiDomain}/payment/creditextensionlist/`;
    const data = new FormData();
    if(params?.salesPersonToken)data.append('salespersonToken', params?.salesPersonToken);
    // data.append('salespersonToken', 'dd360c4196870d39da7fd924d9917cc4');
    data.append('status', params.status);
    // data.append('userToken', '');
    
    if (params?.requestID) data.append('searchRequestID', params.requestID);
    if (params?.startDate) data.append('fromDate', params.startDate);
    if (params?.endDate) data.append('toDate', params.endDate);
    if (params?.requestedBy) data.append('requestedBy', params.requestedBy);
    data.append('brandID', params.brand ?? '');
    data.append('branchID', params.branchIDs ?? '');
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);


    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data.result;
  }

  public async getCreditlineExtensionRequestsDownload(params): Promise<any> {

    const url = `${this.apiDomain}/payment/creditextensionlist/`;
    const data = new FormData();
    if(params?.salesPersonToken)data.append('salespersonToken', params?.salesPersonToken);
    // data.append('salespersonToken', 'dd360c4196870d39da7fd924d9917cc4');
    data.append('status', params.status);
    // data.append('userToken', '');
    
    if (params?.export) data.append('export', 'Yes');
    if (params?.requestID) data.append('searchRequestID', params.requestID);
    if (params?.startDate) data.append('fromDate', params.startDate);
    if (params?.endDate) data.append('toDate', params.endDate);
    if (params?.requestedBy) data.append('requestedBy', params.requestedBy);
    data.append('brandID', params.brand ?? '');
    data.append('branchID', params.branchIDs ?? '');
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);


    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data;
  }

  public async getCreditlineExtensionDetails(requestID,salesPersonToken): Promise<any> {
    const url = `${this.apiDomain}/payment/creditextensionlist/`;
    const data = new FormData();
    if(salesPersonToken)data.append('salespersonToken',salesPersonToken);
    // data.append('salespersonToken', 'dd360c4196870d39da7fd924d9917cc4');
    // data.append('status', params.status);
    // data.append('userToken', '');
    
    if (requestID) data.append('requestID', requestID);
    data.append('brandID', '');
    data.append('branchID', '');
    


    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data.result.list;
  }

  public async approveCreditLine(params): Promise<any> {
    const url = `${this.apiDomain}/payment/creditextensionlist/`;
    const data = new FormData();
    // data.append('salespersonToken', 'dd360c4196870d39da7fd924d9917cc4');
    if(params?.salesPersonToken)data.append('salespersonToken', params?.salesPersonToken);
    // data.append('status', params.status);
    // data.append('userToken', '');
    
    if (params?.requestID) data.append('requestID', params.requestID);
    if (params?.action) data.append('action', params.action);
    if (params?.extentionCreditLimit) data.append('extentionCreditLimit', params.extentionCreditLimit);
    if (params?.gracePeriod) data.append('gracePeriod', params.gracePeriod);


    data.append('brandID', '');
    data.append('branchID', '');
    
    
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data;
  }

  public async rejectCreditLine(params): Promise<any> {
    const url = `${this.apiDomain}/payment/creditextensionlist/`;
    const data = new FormData();
    // data.append('salespersonToken', 'dd360c4196870d39da7fd924d9917cc4');
    if(params?.salesPersonToken)data.append('salespersonToken', params?.salesPersonToken);
    // data.append('status', params.status);
    // data.append('userToken', '');
    
    if (params?.requestID) data.append('requestID', params.requestID);
    if (params?.action) data.append('action', params.action);
    if (params?.reasonID) data.append('reason', params.reasonID);
    if (params?.comment) data.append('comment', params.comment);


    data.append('brandID', '');
    data.append('branchID', '');
    


    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data;
  }
}





