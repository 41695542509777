import React, { useEffect, useState } from 'react';
import empty from '../../containers/home/cart/empty.png';
import { translate } from '../../utils/text-utils';

export const AppTable = (props: {
  data: any[];
  columns: IColumn[];
  onRowClick?: (data: any) => void;
  multiSelectionConfig?: IMultiSelectionConfig;
  isLoading?: boolean;
  showEmptyResult?: boolean;
}) => {
  const columns = props.columns;
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  useEffect(() => {
    if (
      props.multiSelectionConfig &&
      props.multiSelectionConfig?.defaultSelections
    ) {
      setSelectedItems(props.multiSelectionConfig?.defaultSelections);
    }
  }, []);
  useEffect(() => {
    props.multiSelectionConfig?.onChange?.(selectedItems);
  }, [selectedItems]);

  const onItemSelectClick = (key: string) => {
    let clone = [...selectedItems];
    if (selectedItems?.indexOf(key) > -1) {
      clone = selectedItems.filter((p) => p !== key);
    } else {
      clone.push(key);
    }
    setSelectedItems(clone);
  };

  const isChecked = (key: string) => {
    return selectedItems.find((p) => p === key) != null;
  };

  const isMasterCheckBoxChecked = () => {
    return selectedItems.length === props.data?.length;
  };

  const onMasterCheck = () => {
    const allItems = props?.data?.map((p) => {
      return p[props?.multiSelectionConfig?.key!];
    });
    if (isMasterCheckBoxChecked()) {
      setSelectedItems([]);
    } else {
      setSelectedItems(allItems);
    }
  };
  return (
    <>
      <table className="table table-bordered ">
        <thead>
          <tr>
            {props.multiSelectionConfig && (
              <th className="check-all">
                <input
                  id={'all'}
                  onClick={() => {
                    onMasterCheck();
                  }}
                  checked={isMasterCheckBoxChecked()}
                  type="checkbox"
                />
                <label htmlFor="all"></label>
              </th>
            )}
            {columns.map((p, index) => {
              return (
                <th
                  key={'table-header-th-' + index + p.key}
                  data-attr={p.title}
                >
                  {p.title}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {props?.data?.map((item: any, index: number) => {
            return (
              <tr
                className={props?.onRowClick != null ? 'cursor-pointer' : ''}
                key={'table-row-' + index}
                onClick={() => {
                  props?.onRowClick?.(item);
                }}
              >
                {props.multiSelectionConfig && (
                  <td>
                    <input
                      id={item[props.multiSelectionConfig?.key]}
                      checked={isChecked(
                        item[props.multiSelectionConfig?.key!]
                      )}
                      onClick={() => {
                        onItemSelectClick(
                          item[props.multiSelectionConfig?.key!]
                        );
                      }}
                      type="checkbox"
                    />
                    <label
                      htmlFor={item[props.multiSelectionConfig?.key]}
                    ></label>
                  </td>
                )}
                {columns.map((p, columnIndex) => {
                  return (
                    <td
                      key={'table-columns-' + index + p.key + columnIndex}
                      data-attr={p.title}
                    >
                      <label className="responsive-label text-gray-600 font-xs mb-1">
                        {p.title}
                      </label>
                      {p.columnRenderer?.(item, index) ?? item[p.key] ?? ''}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {props.data.length === 0 && !!!props.isLoading && props.showEmptyResult && (
        <div>
          <div className="cartBlock__item py-5">
            <div className="cartBlock__itemHead">
              {translate('orders.No_Result')}
            </div>
            <div className="cartBlock__img mt-3">
              <img src={empty} alt="" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export interface IColumn {
  key: string;
  title: string;
  headerRenderer?: (hederText: string) => JSX.Element;
  columnRenderer?: (data: any, index: number) => JSX.Element;
  isSalesPerson?: boolean;
}

export interface IMultiSelectionConfig {
  key: string;
  defaultSelections?: string[];
  onChange?: (selectedValues: string[]) => void;
}
