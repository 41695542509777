import { IQuotation, IQuoteDetails } from '../../api/models';
import {
  createAction,
  createActionWithPayload,
  IAction,
  IActionWithPayload,
} from '../utils';

// action types
// Add
const QUOTE_ADD_ACTION = 'QUOTE/ADD_ACTION';

const QUOTE_SET_LOADER_ACTION = 'QUOTE/set_loader_action';
// Update
const QUOTE_UPDATE_ACTION = 'QUOTE/UPDATE_ACTION';
// GET
const QUOTE_GET_ACTION = 'QUOTE/GET_ACTION';
// RESET
const QUOTE_RESET_DATA = 'QUOTE/RESET_DATA';

export interface QuoteState {
  // quote?: IQuotation[];
  quoteDetails?: IQuoteDetails;
  isQuoteLoading: boolean;
  isQuoteLoaded: boolean;
  isAdding: boolean;
}

type QuoteAddActionType = IActionWithPayload<
  typeof QUOTE_ADD_ACTION,
  IQuoteDetails
>;

type QuoteUpdateActionType = IActionWithPayload<
  typeof QUOTE_UPDATE_ACTION,
  IQuoteDetails
>;

type QuoteGetActionType = IActionWithPayload<
  typeof QUOTE_GET_ACTION,
  IQuoteDetails
>;

type QuoteResetActionType = IAction<typeof QUOTE_RESET_DATA>;

type QuoteLoaderAction = IActionWithPayload<
  typeof QUOTE_SET_LOADER_ACTION,
  boolean
>;

type Actions =
  | QuoteAddActionType
  | QuoteUpdateActionType
  | QuoteGetActionType
  | QuoteResetActionType
  | QuoteLoaderAction;

// initial state
const initialState: QuoteState = {
  quoteDetails: undefined,
  isQuoteLoading: false,
  isQuoteLoaded: false,
  isAdding: false,
};

export const quoteAddAction = (quote: IQuoteDetails): QuoteAddActionType => {
  return createActionWithPayload(QUOTE_ADD_ACTION, quote);
};

export const quoteUpdateAction = (
  quote: IQuoteDetails
): QuoteUpdateActionType => {
  return createActionWithPayload(QUOTE_UPDATE_ACTION, quote);
};

export const quoteGetAction = (quote: IQuoteDetails): QuoteGetActionType => {
  return createActionWithPayload(QUOTE_GET_ACTION, quote);
};
export const quoteSetLoaderAction = (isLoading: boolean): QuoteLoaderAction => {
  return createActionWithPayload(QUOTE_SET_LOADER_ACTION, isLoading);
};

export const quoteResetAction = (): QuoteResetActionType => {
  return createAction(QUOTE_RESET_DATA);
};

export const quoteReducer = (
  state: QuoteState = initialState,
  action: Actions
): QuoteState => {
  switch (action.type) {
    case QUOTE_ADD_ACTION:
      return {
        ...state,
        quoteDetails: action.payload,
      };
    case QUOTE_UPDATE_ACTION:
      return {
        ...state,
        quoteDetails: action.payload,
      };
    case QUOTE_GET_ACTION:
      return {
        ...state,
        quoteDetails: action.payload,
        isQuoteLoaded: true,
      };
    case QUOTE_SET_LOADER_ACTION:
      return {
        ...state,
        isQuoteLoading: action.payload,
      };

    // logout
    case QUOTE_RESET_DATA:
      return {
        ...state,
        quoteDetails: undefined,
      };
    default:
      return state;
  }
};
