import React, {  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { UserPermissionTypes, UserType } from '../../core/api/models';
import { useAuth } from '../../core/hooks';
import { cartResetAction, logoutAction, State } from '../../core/store';
import { translate } from '../../utils/text-utils';
import { RouteKeys } from '../routes/route-keys';
import './SideMenu.scss';
import noProfile from '../../assets/images/no-profile.png';
import { decodeHtmlEntities } from '../../utils/common';

interface CustomProps {
  isExpand?: boolean;
  expandToggle: () => void;
  menuVisible?: boolean;
}

enum subMenus {
  payment = 'payment',
  settings = 'settings',
  salespersonSettings = 'salespersonsettings',
  schedule = 'schedule',
  reports = 'reports',
  sales = 'sales',
}

const SideMenu: React.FunctionComponent<CustomProps> = (props: CustomProps) => {
  const [subMenu, toggleSubMenu] = React.useState<subMenus | null>(null);
  const authState = useSelector((state: State) => state.authUser);
  const { hasRole, isSalesPerson, userToken } = useAuth();

  const subMenuSelection = (e: any, selected: subMenus) => {
    e.preventDefault();
    if (selected === subMenu) {
      toggleSubMenu(null);
    } else {
      toggleSubMenu(selected);
    }
  };
  const dispatch = useDispatch();
  const history = useHistory();
  React.useEffect(() => {
    if (!props.isExpand) {
      toggleSubMenu(null);
    }
  }, [props.isExpand]);
  const { pathname } = useLocation();
  return (
    <>
      <div
        className={`sidemenu bg-white border-right ${
          props.isExpand === true ? 'menuExpand' : ''
        } ${props.menuVisible === true ? 'menuVisible' : ''}`}
      >
        <div className="sidemenu-inner">
          <ul className="list-unstyled p-0 m-0 menu-list py-2">
            <li>
              <div className="row flex-nowrap pr-3 pt-3 pb-2 my-1 mx-0">
                <div className="col-auto profile-pic">
                  <img
                    src={authState?.profile?.userProfilePicture ?? noProfile}
                    width="30"
                    height="30"
                    className="rounded-circle"
                    alt={authState?.profile?.name ?? ''}
                  />
                </div>
                <div className="col minw-0 p-0">
                  <div className="line-height-normal text-nowrap">
                    <h6 className="font-sm text-gray-700 m-0">
                      {decodeHtmlEntities(authState?.profile?.name ?? '')}
                    </h6>
                    <div className="font-xs text-gray-600">
                      <div className="text-truncate">
                        {authState?.profile?.email ?? ''}
                      </div>
                    </div>
                    <div>
                      <span
                        className="text-primary font-xs cursor-pointer"
                        onClick={() => {
                          dispatch(logoutAction());
                          dispatch(cartResetAction());
                          history.push(RouteKeys.Login);
                        }}
                      >
                        {translate('sideMenu.logout')}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            {authState?.userType === UserType.Salesperson && (
              <li>
                <Link
                  to="/dashboard"
                  className={`d-flex align-items-center ${
                    pathname === '/dashboard' ? 'active' : ''
                  }`}
                >
                  <i className="icon-dashboard"></i>
                  {translate('sideMenu.dashboard')}
                </Link>
              </li>
            )}
            {userToken && (
              <li>
                <Link
                  to="/"
                  className={`d-flex align-items-center ${
                    pathname === '/' ? 'active' : ''
                  }`}
                >
                  <i className="icon-catalog"></i>
                  {translate('sideMenu.catalog')}
                </Link>
              </li>
            )}
            {authState?.userType === UserType.Salesperson &&
              hasRole(UserPermissionTypes.place_order_for_customers) && (
                <li>
                  <Link
                    to="/customers"
                    className={`d-flex align-items-center ${
                      pathname === '/customers' ? 'active' : ''
                    }`}
                  >
                    <i className="icon-place-order1"></i>
                    {translate('sideMenu.placeOrder')}
                  </Link>
                </li>
              )}

            {hasRole(UserPermissionTypes.customer_orders) && (
              <li>
                <Link
                  to={'/orders'}
                  className={`d-flex align-items-center ${
                    pathname === '/orders' ? 'active' : ''
                  }`}
                >
                  <i className="icon-my_orders"></i>
                  {!isSalesPerson
                    ? translate('sideMenu.myOrders')
                    : translate('sideMenu.customerOrders')}
                </Link>
              </li>
            )}
            {hasRole(UserPermissionTypes.quotation_list) && (
              <li>
                <Link
                  to="/quotation"
                  className={`d-flex align-items-center ${
                    pathname === '/quotation' ? 'active' : ''
                  }`}
                >
                  <i className="icon-quotation"></i>
                  {translate('sideMenu.quotation')}
                </Link>
              </li>
            )}
            {/* {hasRole(UserRoleTypes.high_order_request) && (
              <li>
                <Link to="/higher" className={`d-flex align-items-center ${pathname === '/higher' ? 'active' : ''}`}>
                  <i className="icon-order-request"></i>
                  {translate('sideMenu.highValue')}
                  Requests
                </Link>
              </li>
            )} */}
            {hasRole(UserPermissionTypes.return_list) && (
              <li>
                <Link
                  to="/returns"
                  className={`d-flex align-items-center ${
                    pathname === '/returns' ? 'active' : ''
                  }`}
                >
                  <i className="icon-return"></i>
                  {translate('sideMenu.returnRequests')}
                </Link>
              </li>
            )}
            {authState?.userType === UserType.Salesperson &&
              (hasRole(UserPermissionTypes.schedule_master_list) ||
                hasRole(UserPermissionTypes.schedule_list)) && (
                <li className={subMenu === subMenus.schedule ? 'active' : ''}>
                  <a
                    href="#"
                    className={`d-flex align-items-center  ${
                      pathname === RouteKeys.ScheduleManagement ||
                      pathname === RouteKeys.Schedules
                        ? 'active'
                        : ''
                    }`}
                    onClick={(e) => {
                      subMenuSelection(e, subMenus.schedule);
                      if (props.isExpand === false && subMenu !== null) {
                        toggleSubMenu(null);
                      }
                      if (props.isExpand === false) {
                        props.expandToggle();
                      }
                    }}
                  >
                    <i
                      className={`icon-schedule-management ${
                        subMenu === subMenus.schedule ? 'text-primary' : ''
                      }`}
                    ></i>
                    {translate('sideMenu.scheduleManagement')}{' '}
                    <span className="icon-arrow-down ml-auto font-xs"></span>
                  </a>
                  {subMenu === subMenus.schedule && (
                    <ul className="submenu list-unstyled pb-2">
                      {hasRole(UserPermissionTypes.schedule_master_list) && (
                        <li>
                          <Link
                            to="/schedule-management"
                            className={`d-flex font-weight-normal py-2 ${
                              pathname === '/schedule-management'
                                ? 'active'
                                : ''
                            }`}
                          >
                            {translate('sideMenu.manageScheduleMasters')}
                          </Link>
                        </li>
                      )}
                      {hasRole(UserPermissionTypes.schedule_list) && (
                        <li>
                          <Link
                            to="/schedules"
                            className={`d-flex font-weight-normal py-2 ${
                              pathname === '/schedules' ? 'active' : ''
                            }`}
                          >
                            {translate('sideMenu.schedules')}
                          </Link>
                        </li>
                      )}
                      {hasRole(UserPermissionTypes.schedule_list) && (
                        <li>
                          <Link
                            to="/visitation-requests"
                            className={`d-flex font-weight-normal py-2 ${
                              pathname === '/visitation-requests' ? 'active' : ''
                            }`}
                          >
                            {translate('sideMenu.newVisitationRequests')}
                          </Link>
                        </li>
                      )}
                    </ul>
                  )}
                </li>
              )}

            {authState?.userType === UserType.Salesperson &&
              hasRole(UserPermissionTypes.customer_request_view) && (
                <li>
                  <Link
                    to="/customer-requests"
                    className={`d-flex align-items-center ${
                      pathname === '/customer-requests' ? 'active' : ''
                    }`}
                  >
                    <i className="icon-new-customer"></i>
                    {translate('sideMenu.newCustomerRequests')}
                  </Link>
                </li>
              )}
            {hasRole(UserPermissionTypes.view_duepayments) && (
              <li className={subMenu === subMenus.payment ? 'active' : ''}>
                <a
                  href="#"
                  className={`d-flex align-items-center  ${
                    pathname === RouteKeys.DueCustomers ||
                    pathname === RouteKeys.DuePayments ||
                    pathname === RouteKeys.PaymentHistory ||
                    pathname === RouteKeys.CreditRequests ||
                    pathname === RouteKeys.CreditlineExtensionRequestScreen
                      ? 'active'
                      : ''
                  }`}
                  onClick={(e) => {
                    subMenuSelection(e, subMenus.payment);
                    if (props.isExpand === false && subMenu !== null) {
                      toggleSubMenu(null);
                    }
                    if (props.isExpand === false) {
                      props.expandToggle();
                    }
                  }}
                >
                  <i
                    className={`icon-payment-center ${
                      subMenu === subMenus.payment ? 'text-primary' : ''
                    }`}
                  ></i>
                  {translate('sideMenu.paymentCenter')}{' '}
                  <span className="icon-arrow-down ml-auto font-xs"></span>
                </a>
                {subMenu === subMenus.payment && (
                  <ul className="submenu list-unstyled pb-2">
                    <li>
                      <Link
                        to={
                          authState?.userType === UserType.Salesperson
                            ? RouteKeys.DueCustomers
                            : RouteKeys.DuePayments
                        }
                        className={`d-flex font-weight-normal py-2 ${
                          pathname === RouteKeys.DueCustomers ||
                          pathname === RouteKeys.DuePayments ||
                          pathname === RouteKeys.PaymentHistory
                            ? 'active'
                            : ''
                        }`}
                      >
                        <i></i>
                        {translate('sideMenu.duePayments')}
                      </Link>
                    </li>
                    {hasRole(UserPermissionTypes.list_paymenthistory) && (
                      <li>
                        <Link
                          to={RouteKeys.PaymentHistory}
                          className={`d-flex font-weight-normal py-2 ${
                            pathname === RouteKeys.PaymentHistory
                              ? 'active'
                              : ''
                          }`}
                        >
                          <i></i>
                          {translate('sideMenu.paymentHistory')}
                        </Link>
                      </li>
                    )}
                    {hasRole(UserPermissionTypes.list_creditrequest) && (
                      <li>
                        <Link
                          to={RouteKeys.CreditRequests}
                          className={`d-flex font-weight-normal py-2 ${
                            pathname === RouteKeys.CreditRequests
                              ? 'active'
                              : ''
                          }`}
                        >
                          <i></i>
                          {translate('credit.creditRequests')}
                        </Link>
                      </li>
                    )}
                    <li>
                      <Link
                        to={RouteKeys.CreditlineExtensionRequestScreen}
                        className={`d-flex font-weight-normal py-2 ${
                          pathname ===
                          RouteKeys.CreditlineExtensionRequestScreen
                            ? 'active'
                            : ''
                        }`}
                      >
                        <i></i>
                        {translate('sideMenu.creditLineExtension')}
                      </Link>
                    </li>
                    {/* <li>
                    <Link to="" className="font-weight-normal d-flex py-2">
                      <i></i>
                      {translate('sideMenu.creditnote')}
                    </Link>
                  </li> */}
                  </ul>
                )}
              </li>
            )}
            {authState?.userType === UserType.Salesperson && (
              <li className={subMenu === subMenus.settings ? 'active' : ''}>
                <a
                  href=""
                  className={`d-flex align-items-center  ${
                    pathname === '/account-settings' ||
                    pathname === RouteKeys.ManageSalesPerson ||
                    pathname === '/change-password'
                      ? 'active'
                      : ''
                  }`}
                  onClick={(e) => {
                    subMenuSelection(e, subMenus.salespersonSettings);
                    if (props.isExpand === false) props.expandToggle();
                  }}
                >
                  <i
                    className={`icon-account-settings ${
                      subMenu === subMenus.salespersonSettings
                        ? 'text-primary'
                        : ''
                    }`}
                  ></i>
                  {translate('sideMenu.accountSettings')}{' '}
                  <span className="icon-arrow-down ml-auto font-xs"></span>
                </a>
                {subMenu === subMenus.salespersonSettings && (
                  <ul className="submenu list-unstyled pb-2">
                    {hasRole(
                      UserPermissionTypes.view_account_personal_info
                    ) && (
                      <li>
                        <Link
                          to="/account-settings"
                          className="font-weight-normal d-flex py-2"
                        >
                          <i></i>
                          {translate('sideMenu.profileInformation')}
                        </Link>
                      </li>
                    )}

                    {hasRole(UserPermissionTypes.list_salespersons) && (
                      <li>
                        <Link
                          to={RouteKeys.ManageSalesPerson}
                          className="font-weight-normal d-flex py-2"
                        >
                          <i></i>
                          {translate('sideMenu.manageSalesPerson')}
                        </Link>
                      </li>
                    )}
                    <li>
                      <Link
                        to="/change-password"
                        className="font-weight-normal d-flex py-2"
                      >
                        <i></i>
                        {translate('common.changePassword')}
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
            )}

            {/* static report menu */}
            {hasRole(UserPermissionTypes.report) && (
              <li className={subMenu === subMenus.sales ? 'active' : ''}>
                <a
                  href="#"
                  className={`d-flex align-items-center  ${
                    pathname === RouteKeys.Sales ? 'active' : ''
                  }`}
                  onClick={(e) => {
                    subMenuSelection(e, subMenus.reports);
                    if (props.isExpand === false && subMenu !== null) {
                      toggleSubMenu(null);
                    }
                    if (props.isExpand === false) {
                      props.expandToggle();
                    }
                  }}
                >
                  <i
                    className={`icon-reports ${
                      subMenu === subMenus.reports ? 'text-primary' : ''
                    }`}
                  ></i>
                  {translate('reports.reports')}
                  <span className="icon-arrow-down ml-auto font-xs"></span>
                </a>
                {subMenu === subMenus.reports && (
                  <ul className="submenu list-unstyled pb-2">
                    {hasRole(UserPermissionTypes.report_sales) && (
                      <li>
                        <Link
                          to="/sales"
                          className={`d-flex font-weight-normal py-2 ${
                            pathname === '/sales' ? 'active' : ''
                          }`}
                        >
                          <i></i>
                          {translate('reports.sales')}
                        </Link>
                      </li>
                    )}
                    {hasRole(UserPermissionTypes.report_payment) && (
                      <li>
                        <Link
                          to="/reports-payment"
                          className={`d-flex font-weight-normal py-2 ${
                            pathname === '/reports-payment' ? 'active' : ''
                          }`}
                        >
                          <i></i>
                          {translate('duePayments.payment')}
                        </Link>
                      </li>
                    )}
                    {hasRole(UserPermissionTypes.report_customer) && (
                      <li>
                        <Link
                          to="/reports-customers"
                          className={`d-flex font-weight-normal py-2 ${
                            pathname === '/reports-customers' ? 'active' : ''
                          }`}
                        >
                          <i></i>
                          {translate('reports.customers')}
                        </Link>
                      </li>
                    )}
                    {hasRole(UserPermissionTypes.report_salesperson) && (
                      <li>
                        <Link
                          to="/sales-person-report"
                          className={`d-flex font-weight-normal py-2 ${
                            pathname === '/sales-person-report' ? 'active' : ''
                          }`}
                        >
                          <i></i>
                          {translate('salesPerson.salesPerson')}
                        </Link>
                      </li>
                    )}
                  </ul>
                )}
              </li>
            )}
            {/* static report menu */}

            {authState.userType !== 'Salesperson' && (
              <li className={subMenu === subMenus.settings ? 'active' : ''}>
                <a
                  href=""
                  className={`d-flex align-items-center  ${
                    pathname === RouteKeys.StaffPersonalInfo ||
                    pathname === '/general-settings' ||
                    pathname === '/staff-member' ||
                    pathname === '/change-password' ||
                    pathname === '/terms-of-use'
                      ? 'active'
                      : ''
                  }`}
                  onClick={(e) => {
                    subMenuSelection(e, subMenus.settings);
                    if (props.isExpand === false) props.expandToggle();
                  }}
                >
                  <i
                    className={`icon-account-settings ${
                      subMenu === subMenus.settings ? 'text-primary' : ''
                    }`}
                  ></i>
                  {translate('sideMenu.accountSettings')}{' '}
                  <span className="icon-arrow-down ml-auto font-xs"></span>
                </a>
                {subMenu === subMenus.settings && (
                  <ul className="submenu list-unstyled pb-2">
                    {authState.userType === UserType.User && (
                      <li>
                        <Link
                          to={RouteKeys.StaffPersonalInfo}
                          className="font-weight-normal d-flex py-2"
                        >
                          <i></i>
                          {translate('sideMenu.profileInformation')}
                        </Link>
                      </li>
                    )}
                    {hasRole(UserPermissionTypes.view_account_general_info) &&
                      authState.userType !== UserType.User && (
                        <li>
                          <Link
                            to="/general-settings"
                            className="font-weight-normal d-flex py-2"
                          >
                            <i></i>
                            {translate('sideMenu.generalSettings')}
                          </Link>
                        </li>
                      )}

                    {hasRole(UserPermissionTypes.staff_list) &&
                      authState.userType === UserType.Customer && (
                        <li>
                          <Link
                            to="/staff-member"
                            className="font-weight-normal d-flex py-2"
                          >
                            <i></i>
                            {translate('sideMenu.staff')}
                          </Link>
                        </li>
                      )}
                    <li>
                      <Link
                        to="/change-password"
                        className="font-weight-normal d-flex py-2"
                      >
                        <i></i>
                        {translate('common.changePassword')}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/terms-of-use"
                        className="font-weight-normal d-flex py-2"
                      >
                        <i></i>
                        {translate('sideMenu.termsOfUse')}
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
            )}
            <li>
              <Link
                to="/faq"
                className={`d-flex align-items-center ${
                  pathname === '/faq' ? 'active' : ''
                }`}
              >
                <i className="icon-faq sm"></i>
                {translate('sideMenu.faq')}
              </Link>
            </li>
            <li>
              <a
                href="#"
                className="d-flex align-items-center"
                onClick={() => {
                  dispatch(logoutAction());
                  dispatch(cartResetAction());
                  history.push(RouteKeys.Login);
                }}
              >
                <i className="icon-logout sm"></i>
                {translate('sideMenu.logOut')}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default SideMenu;
