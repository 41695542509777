import moment from 'moment';
import React from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { DateFormats } from '../utils/date-utils';
import 'bootstrap-daterangepicker/daterangepicker.css';

export const CustomDateRangePicker = (props: {
  label: string;
  value?: IDateRangeValue;
  onChange: (value: IDateRangeValue) => void;
}) => {
  const value = props.value;
  const getDispayText = () => {
    if (value == null) {
      return props.label;
    }
    return (
      moment(value.startDate).format(DateFormats.DayMonthYearFormat) +
      ' - ' +
      moment(value.endDate).format(DateFormats.DayMonthYearFormat)
    );
  };
  return (
    <div className="row">
      <div className="col">
        <DateRangePicker
          onApply={(event: any, picker: { startDate: Date; endDate: Date }) => {
            props.onChange({
              startDate: picker.startDate,
              endDate: picker.endDate,
            });
          }}
          initialSettings={value}
        >
          <div className="form-control d-flex align-items-center justify-content-lg-end justify-content-between text-gray-600 px-lg-0 px-3 py-0 cursor-pointer border-lg-0"
          >
            {getDispayText()}
            {value == null && <i className="icon-calendar-new ml-3"></i>}
          </div>
        </DateRangePicker>
      </div>
      {value != null && (
        <div
          onClick={() => {
            props.onChange(undefined);
          }}
          className="col-auto p-2 cursor-pointer"
        >
          <i className="icon-cancel font-xxxs text-gray-600"></i>
        </div>
      )}
    </div>
  );
};

export interface IDateRangeValue {
  startDate: Date;
  endDate: Date;
}
