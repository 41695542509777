import React, { useEffect, useState } from 'react';
import { UserHeader } from '../header';
import '../../assets/sass/listing.scss';
import '../../assets/sass/grid.scss';
import { OrderListingComponent } from './components/order-listing-component';
import { IColumn } from '../../components/grid/app-table';
import api from '../../api';
import { useOrder } from '../../core/hooks/useOrder';
import { FilterTypes } from './components/order-filter-component';
import { IOrder, IOrderListParams } from '../../models/order-models';
import { PriceItem } from './components/order-items-grid';
import moment from 'moment';
import {
  calculateDaysBetweenDates,
  DateFormats,
  isValidDate,
  formatDate,
} from '../../utils/date-utils';
import { useSelector } from 'react-redux';
import { State } from '../../core/store';
import { UserType } from '../../core/api/models';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { translate } from '../../utils/text-utils';
import { CountDownTimer } from '../../components/count-down-timer';
import { CustomDateRangePicker } from '../../components/custom-date-range-picker';
import { DebounceInput } from 'react-debounce-input';

const MyOrders: React.FunctionComponent = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { tab } = useParams<{
    tab: TabFilters;
  }>();
  const { downlaodOrders } = useOrder(api.order);

  const [search, setSearch] = useState('');

  const [filters, setFilters] = useState<any>({});
  const [date, setDate] = useState<any>();
  const [count, setCount] = useState<any>();
  const [listSearch, toggleListSearch] = useState<boolean>(false);

  const authState = useSelector((state: State) => state.authUser);
  const type = authState.userType;
  const isSalesPerson: Boolean = type !== UserType.Customer ?? false;

  const onDateChange = (value: { startDate: Date; endDate: Date }) => {
    if (value?.startDate && value?.endDate) {
      const d = {
        startDate: formatDate(
          new Date(value.startDate),
          DateFormats.YearMonthFormat
        ),
        endDate: formatDate(
          new Date(value.endDate),
          DateFormats.YearMonthFormat
        ),
      };
      setDate(d);
    } else {
      setDate(undefined);
    }
  };

  const setIdSearch = (value: string) => {
    setSearch(value);
  };

  const col = orderListingColumns;
  const tabs: ITabDataWithColumns[] = [
    {
      tabTitle: t(TabNames.All),
      tabKey: TabFilters.All,
      filterTypes: [
        FilterTypes.requestedBy,
        FilterTypes.rejectedBy,
        FilterTypes.approvedBy,
        FilterTypes.brand,
        FilterTypes.branch,
        FilterTypes.createdFor,
        FilterTypes.paymentMethod,
        FilterTypes.paymentStatus,
      ],
      columns: [
        col().requestId,
        col().customerCode,
        col().customerName,
        col().requestedBy,
        col().salesPerson,
        col().brandAndBranch,
        col().requestedDate,
        col().rejectedBy,
        col().rejectedOn,
        col().approvedBy,
        col().approvedOn,
        col().totalItemsRequested,
        col().quantityRequested,
        col().immediatelyAvailable,
        col().availableInOneToTwoDays,
        col().availableOnBackOrder,
        col().requestedItems,
        col().purchased,
        col().paymentMethodName,
        col().paymentStatus,
        col().totalAmount,
      ],
    },
    {
      tabTitle: t(TabNames.InternallyRequested),
      tabKey: TabFilters.InternallyRequested,
      filterTypes: [
        FilterTypes.requestedBy,
        FilterTypes.brand,
        FilterTypes.branch,
      ],
      columns: [
        col().requestId,
        col().customerCode,
        col().customerName,
        col().requestedBy,
        col().salesPerson,
        col().brandAndBranch,
        col().requestedDate,
        col().requestedItems,
        col().totalAmount,
      ],
    },
    {
      tabTitle: t(TabNames.RequestRejected),
      tabKey: TabFilters.RequestRejected,
      filterTypes: [
        FilterTypes.requestedBy,
        FilterTypes.rejectedBy,
        FilterTypes.brand,
        FilterTypes.branch,
      ],
      columns: [
        col().requestId,
        col().customerCode,
        col().customerName,
        col().requestedBy,
        col().salesPerson,
        col().brandAndBranch,
        col().rejectedBy,
        col().rejectedOn,
        col().requestedItems,
        col().totalAmount,
      ],
    },
    {
      tabTitle: t(TabNames.RequestApproved),
      tabKey: TabFilters.RequestApproved,
      filterTypes: [
        FilterTypes.requestedBy,
        FilterTypes.approvedBy,
        FilterTypes.brand,
        FilterTypes.branch,
      ],
      columns: [
        col().requestId,
        col().customerCode,
        col().customerName,
        col().requestedBy,
        col().salesPerson,
        col().brandAndBranch,
        col().approvedBy,
        col().approvedOn,
        col().requestedItems,
        col().totalAmount,
      ],
    },
    {
      tabTitle: t(TabNames.InventoryReserved),
      tabKey: TabFilters.InventoryReserved,
      filterTypes: [
        FilterTypes.requestedBy,
        FilterTypes.brand,
        FilterTypes.branch,
      ],
      columns: [
        col().expiringOn,
        col().requestId,
        col().wipNumber,
        col().customerCode,
        col().customerName,
        col().requestedBy,
        col().salesPerson,
        col().brandAndBranch,
        col().requestedOn,
        col().totalItemsRequested,
        col().quantityRequested,
        col().immediatelyAvailable,
        col().availableInOneToTwoDays,
        col().availableOnBackOrder,
        col().cancelledReason,
        col().orderedItems,
        col().totalAmount,
      ],
    },
    {
      tabTitle: t(TabNames.OrderCreated),
      tabKey: TabFilters.OrderCreated,
      filterTypes: [
        FilterTypes.requestedBy,
        FilterTypes.createdFor,
        FilterTypes.paymentMethod,
        FilterTypes.paymentStatus,
        FilterTypes.brand,
        FilterTypes.branch,
      ],
      columns: [
        col().orderId,
        col().wipNumber,
        col().createdOn,
        col().customerCode,
        col().customerName,
        col().requestedBy,
        col().salesPerson,
        col().brandAndBranch,
        col().orderStatus,
        // col().deliveryType,
        // col().deliveryAddress,
        col().purchased,
        col().totalAmount,
        col().paymentMethodName,
        col().paymentStatus,
      ],
    },
    {
      tabTitle: t(TabNames.CancelRequest),
      tabKey: TabFilters.CancelRequest,
      filterTypes: [
        FilterTypes.requestedBy,
        FilterTypes.createdFor,
        FilterTypes.paymentMethod,
        FilterTypes.paymentStatus,
        FilterTypes.brand,
        FilterTypes.branch,
      ],
      columns: [
        col().orderId,
        col().wipNumber,
        col().createdOn,
        col().customerCode,
        col().customerName,
        col().requestedBy,
        col().salesPerson,
        col().brandAndBranch,
        col().purchasedItems,
        col().purchasedQuantity,
        col().purchasedItemAmount,
        col().cancelledItems,
        col().cancelledQuantity,
        col().cancelledItemsAmount,
        col().paymentMethodName,
        col().paymentStatus,
        col().cancelledReason,
      ],
    },
    {
      tabTitle: t(TabNames.OrderDelivered),
      tabKey: TabFilters.OrderDelivered,
      filterTypes: [
        FilterTypes.requestedBy,
        FilterTypes.createdFor,
        FilterTypes.paymentMethod,
        FilterTypes.paymentStatus,
        FilterTypes.brand,
        FilterTypes.branch,
      ],
      columns: [
        col().orderId,
        col().wipNumber,
        col().deliveredOn,
        col().customerCode,
        col().customerName,
        col().requestedBy,
        col().salesPerson,
        col().brandAndBranch,
        // col().deliveryType,
        // col().deliveryAddress,
        col().purchased,
        col().paymentMethodName,
        col().paymentStatus,
        col().totalAmount,
      ],
    },
    {
      tabTitle: t(TabNames.CancelledOrders),
      tabKey: TabFilters.CancelledOrders,
      filterTypes: [
        FilterTypes.requestedBy,
        FilterTypes.createdFor,
        FilterTypes.approvedBy,
        FilterTypes.paymentMethod,
        FilterTypes.paymentStatus,
        FilterTypes.brand,
        FilterTypes.branch,
      ],
      columns: [
        col().requestId,
        col().customerCode,
        col().customerName,
        col().requestedBy,
        col().salesPerson,
        col().requestedDate,
        col().brandAndBranch,
        col().approvedBy,
        col().approvedDate,
        col().cancelledReason,
        col().orderedItems,
        col().totalAmount,
      ],
    },
  ];
  const [selectedTab, setSelectedTab] = React.useState<ITabDataWithColumns>(
    tabs[0]
  );

  useEffect(() => {
    const sTab = tabs.find((tb) => tb.tabKey === tab);
    if (sTab) {
      setSelectedTab(sTab);
    }
  }, [tab]);
  if (type === UserType.Salesperson) {
    selectedTab.filterTypes = [
      ...selectedTab?.filterTypes,
      FilterTypes.salesPerson,
    ];
  }

  return (
    <>
      <UserHeader menuVisible showBrand={false} />

      <div className="inner-wrapper pb-5">
        <div className="list-head">
          <div className="px-lg-4 py-lg-2 p-3 border-bottom">
            <div className="row gutter-8 align-items-center py-lg-0 py-1">
              <div className="col-auto d-lg-none d-flex">
                <i
                  className="icon-arrow-left font-xxl icon-rtl font-weight-bold"
                  onClick={() => {
                    history.goBack();
                  }}
                ></i>
              </div>
              <div className="col">
                <h5 className="m-0">
                  {' '}
                  {authState.userType === UserType.Customer
                    ? t('sideMenu.myOrders')
                    : t('sideMenu.customerOrders')}
                </h5>
              </div>
              <div className="col-auto d-lg-none d-flex">
                <span
                  className="font-weight-medium text-primary d-inline-flex cursor-pointer"
                  onClick={() => toggleListSearch(!listSearch)}
                >
                  {listSearch === true ? (
                    <i className="icon-cancel font-sm"></i>
                  ) : (
                    <i className="icon-search"></i>
                  )}
                </span>
              </div>
              <div
                className={`col-lg-auto col-12 mt-lg-0 mt-3 list-search ${
                  listSearch === true ? 'active' : ''
                }`}
              >
                <div className="filter-search">
                  <div className="input-group">
                    <DebounceInput
                      value={search}
                      minLength={1}
                      debounceTimeout={600}
                      className="form-control border-0 pr-0 font-md text-lg-right"
                      placeholder={t('orders.requestId')}
                      onChange={(e) => setIdSearch(e.target.value)}
                    />

                    <div className="input-group-append">
                      <input
                        type="submit"
                        className="form-control-search btn btn-link border-0 bg-transparent p-0"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`col-lg-auto col-12 mt-lg-0 mt-3 list-search ${
                  listSearch === true ? 'active' : ''
                }`}
              >
                <div className="filter-date">
                  <div className="dateRange">
                    <CustomDateRangePicker
                      label={translate('customerScreen.selectDateRange')}
                      value={date}
                      onChange={onDateChange}
                    ></CustomDateRangePicker>
                  </div>
                </div>
              </div>
              {isSalesPerson && (
                <div className={`col-lg-auto col-12 mt-lg-0 mt-3 list-search`}>
                  <span
                    className="font-weight-medium text-primary cursor-pointer"
                    onClick={() => {
                      const param: IOrderListParams = {
                        ...filters,
                        status: selectedTab.tabKey,
                        export: true,
                      };
                      downlaodOrders(param);
                    }}
                  >
                    <i className="icon-download"></i>
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="tab-block border-bottom px-lg-2">
          <ul className="nav flex-nowrap overflow-auto p-0">
            {tabs.map((p, index) => {
              return (
                <li
                  key={'oreder-tab-' + index}
                  className={`d-md-block d-flex align-items-center ${
                    p.tabKey === selectedTab?.tabKey ? 'active' : ''
                  }`}
                  onClick={() => {
                    setSelectedTab(p);
                  }}
                >
                  <h4 className="m-0 p-0 d-lg-block d-none">
                    {count?.[p.tabKey] ?? 0}
                  </h4>
                  <p className="font-sm font-weight-medium m-0 p-0 text-black-50">
                    {p.tabTitle}{' '}
                    <span className="d-lg-none">
                      ({count?.[p.tabKey] ?? 0})
                    </span>
                  </p>
                </li>
              );
            })}
          </ul>
        </div>

        <OrderListingComponent
          filterTypes={selectedTab?.filterTypes ?? []}
          onDataFetch={setCount}
          columns={
            selectedTab.columns?.filter(
              (item) =>
                item.isSalesPerson === undefined ||
                item.isSalesPerson === isSalesPerson
            ) ?? []
          }
          date={date}
          key={
            selectedTab?.tabKey +
            JSON.stringify(filters) +
            search +
            JSON.stringify(date)
          }
          search={search}
          filter={filters[selectedTab?.tabKey]}
          status={selectedTab?.tabKey as any}
          onFilterChange={(status: string, newfilter: any) => {
            const clone = { ...filters };
            clone[status] = newfilter;
            setFilters(clone);
          }}
        />
      </div>
    </>
  );
};

export default MyOrders;

export interface ITabDataWithColumns {
  tabTitle: string;
  tabKey: TabFilters | string;
  content?: (params?: any) => JSX.Element;
  columns?: IColumn[];
  filterTypes?: FilterTypes[];
}
export const TabNames = {
  All: 'common.all',
  InternallyRequested: 'orders.internallyRequested',
  RequestRejected: 'orders.requestRejected',
  RequestApproved: 'orders.requestApproved',
  InventoryReserved: 'orders.inventoryReserved',
  OrderCreated: 'orders.orderCreated',
  CancelRequest: 'orders.cancelRequest',
  OrderDelivered: 'orders.orderDelivered',
  CancelledOrders: 'orders.cancelledOrders',
};

export enum TabFilters {
  All = 'all',
  InternallyRequested = 'approval-pending',
  RequestRejected = 'rejected',
  RequestApproved = 'approved',
  InventoryReserved = 'inventory-reserved',
  OrderCreated = 'verification-pending',
  CancelRequest = 'cancel-request',
  OrderDelivered = 'order-delivered',
  CancelledOrders = 'order-cancelled',
  Returned = 'returned',
}

const orderListingColumns = () => ({
  requestId: {
    key: 'orderNumber',
    title: translate('orders.requestId'),
  },
  wipNumber: {
    key: 'wipNumber',
    title: translate('orders.wipNumber'),
  },
  deliveredOn: {
    key: 'orderDeliverDate',
    title: translate('orders.deliveredOn'),
    columnRenderer: (data: IOrder) => {
      const momentDate = moment(data?.orderDeliverDate);

      const date = momentDate.toDate();

      if (
        !isValidDate(data.expiryTime) ||
        data.orderDeliverDate === '0000-00-00 00:00:00'
      ) {
        return <>-</>;
      }
      return (
        <>
          {moment(data?.orderDeliverDate ?? new Date()).format(
            DateFormats.NormalFromat
          )}
        </>
      );
    },
  },
  requestedBy: {
    key: 'requestedBy',
    title: translate('orders.requestedBy'),
  },
  brandAndBranch: {
    key: 'brandName',
    title: translate('orders.brandBranch'),
    columnRenderer: (data: IOrder) => {
      return <>{`${data.brandName ?? ''} - ${data.branchName ?? ''}`}</>;
    },
  },
  requestedDate: {
    key: 'orderCreatedDate',
    title: translate('higher.requestedDate'),
    columnRenderer: (data: IOrder) => {
      if (data?.orderCreatedDate === '0000-00-00 00:00:00') {
        return <>-</>;
      }
      return (
        <>
          {moment(data?.orderCreatedDate ?? new Date()).format(
            DateFormats.NormalFromat
          )}
        </>
      );
    },
  },
  requestedItems: {
    key: 'items',
    title: translate('orders.requestedItems'),
    columnRenderer: (data: IOrder) => {
      return (
        <>{`${data?.items?.length ?? 0} ${
          data?.items?.length === 1
            ? translate('orders.item')
            : translate('brandSelection.items')
        }`}</>
      );
    },
  },
  totalAmount: {
    key: 'orderTotalAmount',
    title: translate('cart.total'),
    headerRenderer: (title: string) => {
      return <h1>{title}</h1>;
    },
    columnRenderer: (data: IOrder) => {
      return <PriceItem amount={data?.orderTotalAmount} />;
    },
  },
  approvedBy: {
    key: 'approvedByUser',
    title: translate('orders.approvedBy'),
  },
  rejectedBy: {
    key: 'approvedByUser',
    title: translate('orders.rejectedBy'),
  },
  rejectedOn: {
    key: 'orderStatusDate',
    title: translate('orders.rejectedOn'),
    columnRenderer: (data: IOrder) => {
      return (
        <>
          {moment(data?.orderStatusDate ?? new Date()).format(
            DateFormats.NormalFromat
          )}
        </>
      );
    },
  },
  requestedItemsCount: {
    key: 'requestedItemsCount',
    title: translate('orders.requestedItems'),
  },
  approvedOn: {
    key: 'approvedDate',
    title: translate('orders.approvedOn'),
    columnRenderer: (data: IOrder) => {
      return (
        <>
          {moment(data?.approvedDate ?? new Date()).format(
            DateFormats.NormalFromat
          )}
        </>
      );
    },
  },
  expiringOn: {
    key: 'expiryTime',
    title: translate('orders.expiringOn'),
    columnRenderer: (data: IOrder) => {
      const momentDate = moment(data.expiryTime);

      const date: any = momentDate.toDate();
      if (
        data.expiryTime === '0000-00-00 00:00:00' ||
        date === 'Invalid Date'
      ) {
        return <>-</>;
      }
      if (momentDate.isBefore(moment(new Date()))) {
        return (
          <span className="text-danger">{translate('orders.expired')}</span>
        );
      }

      if (calculateDaysBetweenDates(new Date(), date) > 1) {
        return <>{momentDate.format(DateFormats.NormalFromat)}</>;
      }
      return (
        <CountDownTimer date={date} onZeroString={'Expired'}></CountDownTimer>
      );
    },
  },
  totalItemsRequested: {
    key: 'requestedItemsCount',
    title: translate('orders.totalItemsRequested'),
    columnRenderer: (data: IOrder) => {
      return (
        <>{`${data?.requestedItemsCount ?? 0} ${
          data?.requestedItemsCount === 1
            ? translate('orders.item')
            : translate('brandSelection.items')
        }`}</>
      );
    },
  },
  quantityRequested: {
    key: 'totalRequestedQty',
    title: translate('orders.qtyRequested'),
    columnRenderer: (data: IOrder) => {
      return <>{data?.totalRequestedQty ?? 0}</>;
    },
  },
  immediatelyAvailable: {
    key: 'totalImmediatelyAvailable',
    title: translate('orders.immediatelyAvailable'),
    columnRenderer: (data: IOrder) => {
      return <>{data?.totalImmediatelyAvailable ?? 0}</>;
    },
  },
  availableInOneToTwoDays: {
    key: 'totalAvailLaterQty',
    title: translate('orders.availableInDays'),
    columnRenderer: (data: IOrder) => {
      return <>{data?.totalAvailLaterQty ?? 0}</>;
    },
  },
  availableOnBackOrder: {
    key: 'totalBackOrderQty',
    title: translate('orders.availableInBackOrder'),
    columnRenderer: (data: IOrder) => {
      return <>{data?.totalBackOrderQty ?? 0}</>;
    },
  },
  purchasedItems: {
    key: 'orderQty',
    title: translate('orders.purchasedItems'),
    columnRenderer: (data: IOrder) => {
      return (
        <>{`${data?.orderQty ?? '0'} ${
          data?.orderQty === '1'
            ? translate('orders.item')
            : translate('brandSelection.items')
        }`}</>
      );
    },
  },
  purchasedQuantity: {
    key: 'orderQty',
    title: translate('orders.purchasedQty'),
    columnRenderer: (data: IOrder) => {
      return <>{data?.items?.length ?? 0}</>;
    },
  },
  cancelledItemsAmount: {
    key: 'orderTotalAmount',
    title: translate('orders.cancelledItemAmt'),
    columnRenderer: (data: IOrder) => {
      return <PriceItem amount={data?.orderTotalAmount} />;
    },
  },
  paymentStatus: {
    key: 'paymentStatus',
    title: translate('orders.paymentStatus'),
  },
  // deliveryType: {
  //   key: 'deliveryType',
  //   title: translate('orders.deliveryType'),
  // },
  // deliveryAddress: {
  //   key: 'shippingAddress',
  //   title: translate('orders.deliveryAddress'),
  //   columnRenderer: (data: IOrder) => {
  //     return <>{data?.shippingAddress.replaceAll('~*', ', ')}</>;
  //   },
  // },
  paymentMethodName: {
    key: 'orderPaymentMethodName',
    title: translate('orders.paymentMethod'),
  },
  cancelledReason: {
    key: 'cancelReason',
    title: translate('orders.reason'),
  },
  orderStatus: {
    key: 'orderStatus',
    title: translate('orders.orderStatus'),
  },
  requestedOn: {
    key: 'orderCreatedDate',
    title: translate('orders.requestedOn'),
    columnRenderer: (data: IOrder) => {
      if (data?.orderCreatedDate === '0000-00-00 00:00:00') {
        return <>-</>;
      }
      return (
        <>
          {moment(data?.orderCreatedDate ?? new Date()).format(
            DateFormats.NormalFromat
          )}
        </>
      );
    },
  },
  orderId: {
    key: 'orderNumber',
    title: translate('duePayments.orderId'),
    columnRenderer: (data: IOrder) => {
      return <>{data.orderNumber}</>;
    },
  },
  createdOn: {
    key: 'orderCreatedDate',
    title: translate('orders.createdOn'),
    columnRenderer: (data: IOrder) => {
      if (data?.orderCreatedDate === '0000-00-00 00:00:00') {
        return <>-</>;
      }
      return (
        <>
          {moment(data?.orderCreatedDate ?? new Date()).format(
            DateFormats.NormalFromat
          )}
        </>
      );
    },
  },
  purchased: {
    key: 'orderQty',
    title: translate('orders.purchased'),
    columnRenderer: (data: IOrder) => {
      return (
        <>{`${data?.orderQty ?? '0'} ${
          data?.orderQty === '1'
            ? translate('orders.item')
            : translate('brandSelection.items')
        }`}</>
      );
    },
  },
  purchasedItemAmount: {
    key: 'orderTotalAmount',
    title: translate('orders.purchasedItemAmt'),
    columnRenderer: (data: IOrder) => {
      return <PriceItem amount={data?.orderTotalAmount} />;
    },
  },
  cancelledItems: {
    key: 'requestedItemsCount',
    title: translate('orders.cancelledItems'),
    columnRenderer: (data: IOrder) => {
      return (
        <>{`${data?.requestedItemsCount ?? 0} ${
          data?.requestedItemsCount === 1
            ? translate('orders.item')
            : translate('brandSelection.items')
        }`}</>
      );
    },
  },
  cancelledQuantity: {
    key: 'orderQty',
    title: translate('orders.cancelledQty'),
  },
  approvedDate: {
    key: 'approvedDate',
    title: translate('orders.approvedDate'),
    columnRenderer: (data: IOrder) => {
      if (data?.orderCreatedDate === '0000-00-00 00:00:00') {
        return <>-</>;
      }
      return (
        <>
          {moment(data?.approvedDate ?? new Date()).format(
            DateFormats.NormalFromat
          )}
        </>
      );
    },
  },
  orderedItems: {
    key: 'orderQty',
    title: translate('orders.orderedItems'),
    columnRenderer: (data: IOrder) => {
      return (
        <>{`${data?.orderQty ?? '0'} ${
          data?.orderQty === '1'
            ? translate('orders.item')
            : translate('brandSelection.items')
        }`}</>
      );
    },
  },
  customerCode: {
    key: 'customerCode',
    title: translate('brands.accountCode'),
    isSalesPerson: true,
  },
  customerName: {
    key: 'userName',
    title: translate('customerOrders.customerName'),
    isSalesPerson: true,
  },
  salesPerson: {
    key: 'salesPersonName',
    title: translate('customerOrders.salesPerson'),
    isSalesPerson: true,
  },
});
