import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../store/interfaces';
import api from '../../api';
import { brandAndBranchGetAction, brandSetLoaderAction } from '../store';
import { IBrandBranchData } from '../../models/brand';

export const useBrandsAndBranches = () => {
  const brandState = useSelector((state: State) => state.brandAndBranch);
  const [allBrandAndBranch, setBrandAndBranch] = useState<IBrandBranchData[]>(
    []
  );

  const [isLoading, setLoader] = useState(false);
  const dispatch = useDispatch();
  const authState = useSelector((state: State) => state.authUser);

  // useEffect(() => {
  //   if (!brandState.isBrandAdBranchLoading) {
  //     getBrandAndBranches(authState?.userToken ?? '');
  //   }
  // }, [authState?.userToken, authState.salesPersonToken]);

  const getBrandAndBranches = async (userToken?: string) => {
    dispatch(brandSetLoaderAction(true));
    const response = await api.model.getBrandsWithBranches(userToken);
    const re = response?.filter((br) => br.branches.length !== 0);
    dispatch(brandAndBranchGetAction(re));
    dispatch(brandSetLoaderAction(false));
  };

  const getAllBrands = async () => {
    setLoader(true);
    try {
      const response = await api.model.getBrandsWithBranches();
      const re = response?.filter((br) => br.branches.length !== 0);
      setBrandAndBranch(re);
      // return response;
    } catch (error) {
      setBrandAndBranch([]);
      // return [];
    } finally {
      setLoader(false);
    }

    // dispatch(brandAndBranchGetAction(response));
    // dispatch(brandSetLoaderAction(false));
  };

  return {
    getBrandAndBranches,
    getAllBrands,
    isLoading,
    allBrandAndBranch,
    brandAndBranch: brandState.brandAdBranch,
    loaded: brandState.isBrandAdBranchLoaded,
    loading: brandState.isBrandAdBranchLoading,
    brandState,
  };
};
