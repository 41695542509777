import api from '../../../api';
import { TabFilters } from '../../../containers/orders/order-listing-screen';
import {
  ICheckoutData,
  IDeliveryAddress,
  IOrderDetails,
  IOrderFilterData,
  IPreviewData,
} from '../../../models/order-models';
import { createActionWithPayload, IActionWithPayload } from '../utils';
export interface OrderState {
  selectedOrder?: {
    details?: IOrderDetails;
    previewDetails?: IPreviewData;
  };
  checkout?: {
    checkoutData?: ICheckoutData;
  };
  filterData?: IOrderFilterData;
  isLoading: boolean;
  selectedTab?: TabFilters;
}

const initialState = {
  isLoading: false,
};

// reducer
export const orderReducer = (
  state: OrderState = initialState,
  action: IActionWithPayload
): OrderState => {
  switch (action.type) {
    // login
    case OrderActions.getOrderDetailsSuccess:
      return {
        ...state,
        selectedOrder: { details: action.payload },
      };
    case OrderActions.getPreviewDetailsSuccess:
      return {
        ...state,
        selectedOrder: {
          ...state.selectedOrder,
          previewDetails: action.payload,
        },
      };
    case OrderActions.getCheckoutDataSuccess:
      return {
        ...state,
        checkout: {
          ...state.checkout,
          checkoutData: action.payload,
        },
      };
    case OrderActions.addCheckoutDeliveryAddress:
      const checkoutData = state?.checkout?.checkoutData ?? ({} as any);
      const checkoutDataClone = { ...checkoutData };
      if (!checkoutDataClone?.deliveryAddresses) {
        checkoutDataClone.deliveryAddresses = [];
      }
      checkoutDataClone.deliveryAddresses = action.payload;
      return {
        ...state,
        checkout: {
          ...state.checkout,
          checkoutData: checkoutDataClone,
        },
      };
    case OrderActions.clearPreviewDetails:
      return {
        ...state,
        selectedOrder: {
          ...state.selectedOrder,
          previewDetails: undefined,
        },
      };
    case OrderActions.getOrderFilterDataSuccess:
      return {
        ...state,
        filterData: action.payload,
      };
    case OrderActions.setOrderLoader:
      return {
        ...state,
        isLoading: action.payload,
      };

    case OrderActions.setOrderDetailTabSelected:
      return {
        ...state,
        selectedTab: action.payload,
      };

    default:
      return state;
  }
};

export const OrderActions = {
  getOrderDetailsSuccess: `Order-get-order-details-success`,
  getPreviewDetailsSuccess: `Order-get-preview-details-success`,
  getOrderFilterDataSuccess: `Order-get-filter-data-success`,
  getCheckoutDataSuccess: `Order-get-checkout=data-success`,
  addCheckoutDeliveryAddress: `Order-add-checkout-delivery-address`,
  clearPreviewDetails: `Order-clear-preview-details`,
  setOrderLoader: `Order-set-order-loader`,
  setOrderDetailTabSelected: `Order-set-tab-selected`,
};

export const onOrderDetailsSuccess = (
  details: IOrderDetails
): IActionWithPayload<any> => {
  return createActionWithPayload(OrderActions.getOrderDetailsSuccess, details);
};

export const onFilterDataSuccess = (
  filterData: IOrderFilterData
): IActionWithPayload<any> => {
  return createActionWithPayload(
    OrderActions.getOrderFilterDataSuccess,
    filterData
  );
};

export const onCheckoutDataSuccess = (
  details: ICheckoutData
): IActionWithPayload<any> => {
  return createActionWithPayload(OrderActions.getCheckoutDataSuccess, details);
};

export const onAddCheckoutDeliveryAddress = (
  details: IDeliveryAddress[]
): IActionWithPayload<any> => {
  return createActionWithPayload(
    OrderActions.addCheckoutDeliveryAddress,
    details
  );
};

export const onPreviewDetailsSuccess = (
  details: IPreviewData
): IActionWithPayload<any> => {
  return createActionWithPayload(
    OrderActions.getPreviewDetailsSuccess,
    details
  );
};
export const onClearPreviewDetails = (): IActionWithPayload<any> => {
  return createActionWithPayload(OrderActions.clearPreviewDetails, null);
};

export const setOrderLoader = (isLoading: boolean): IActionWithPayload => {
  return createActionWithPayload(OrderActions.setOrderLoader, isLoading);
};

export const setOrderDetailSelectedTab = (
  tab?: TabFilters
): IActionWithPayload => {
  return createActionWithPayload(OrderActions.setOrderDetailTabSelected, tab);
};
