import {
  ApiResponse,
  ICustomerBulkImportResponse,
  Roles,
  TokenData,
} from '../models';
import { SignUpModel, ISignup, ChangePasswordProps } from '../models/signup';
import * as http from './http';
import ApiService from './service';

export class AuthService extends ApiService {
  static loginUrl = '/user/login';
  static signUpUrl = '/user/signup';
  static forgotUrl = '/user/forgotPassword';
  static resetUrl = '/user/reset';
  static createPass = '/user/createpassword';

  public async login(credentials: { username: string; password?: string }) {
    const url = `${this.apiDomain}${AuthService.loginUrl}`;
    const data = new FormData();
    data.append('userLogin', credentials.username);
    data.append('userPassword', credentials.password || '');
    const response = await http.postAsFormData<ApiResponse<TokenData>>(
      url,
      data
    );
    return response.data;
  }

  // public async login(credentials: { username: string ,password:string }) {
  //   const url = `${this.apiDomain}${AuthService.loginUrl}`;

  //   const data = new FormData();
  //   data.append('userLogin', credentials.username);
  //   data.append('userPassword', credentials.password);

  //    const response = await http.postAsFormData<ApiResponse<TokenData>>(
  //     url,
  //     data
  //   );
  //   return response.data;
  // }

  public async signup(data: ISignup) {
    const formData = new FormData();
    formData.append('companyName', data.companyName);
    formData.append('countryID', data.countryID);
    formData.append('userEmail', data.userEmail);
    formData.append('userPhone', data.userPhone);
    // formData.append('userFirstName', data.userFirstName);
    // formData.append('userLastName', data.userLastName);
    formData.append('brandID', '');
    formData.append('branchID', '');
    formData.append('tradeno', data?.tradelicensenumber ?? '');
    formData.append('trn', data?.TRNnumber ?? '');
    formData.append('vatNo', data.VATnumber);
    // formData.append('userCity', data.city);
    formData.append('stateID', data.stateID);
    formData.append('userDialcode', data.userDialcode);
    formData.append('brandBranch', JSON.stringify(data.brandBranch));
    const url = `${this.apiDomain}${AuthService.signUpUrl}`;
    const response = await http.postAsFormData<ApiResponse<TokenData>>(
      url,
      formData
    );
    return response;
  }

  public async forgot(data: any) {
    const formData = new FormData();
    formData.append('userLogin', data.userLogin);

    const url = `${this.apiDomain}${AuthService.forgotUrl}`;

    const response = await http.postAsFormData<ApiResponse<TokenData>>(
      url,
      formData
    );
    return response;
  }

  public async reset(data: any) {
    const formData = new FormData();
    formData.append('userEmail', data.userLogin);
    formData.append('code', data.otp);
    formData.append('userPassword', data.newPassword);

    const url = `${this.apiDomain}${AuthService.resetUrl}`;

    const response = await http.postAsFormData<ApiResponse<TokenData>>(
      url,
      formData
    );
    return response;
  }

  public async verifyOtp(credentials: {
    otp: string;
    token: string;
  }): Promise<any> {
    const url = `${this.apiDomain}/user/verifyOTP/`;
    const data = new FormData();
    data.append('otp', credentials.otp);
    data.append('userToken', credentials.token);
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response;
  }

  public async newPassword(cred: {
    token: string;
    newPassword: string;
  }): Promise<any> {
    const url = `${this.apiDomain}${AuthService.createPass}`;
    const data = new FormData();

    data.append('newPassword', cred.newPassword);
    data.append('confirmPassword', cred.newPassword);

    data.append('userToken', cred.token);
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response;
  }

  public async changePassword(data: ChangePasswordProps) {
    const formData = new FormData();
    formData.append('newPassword', data.newPassword);
    formData.append('oldPassword', data.oldPassword);
    formData.append('confirmPassword', data.confirmPassword);
    formData.append('brandID', '');
    formData.append('branchID', '');
    const url = `${this.apiDomain}/user/changepassword/`;
    const response = await http.postAsFormData<ApiResponse<TokenData>>(
      url,
      formData
    );
    return response;
  }

  public async getRoles(): Promise<ApiResponse<Roles>> {
    const url = `${this.apiDomain}/user/checkRoles/`;
    const data = new FormData();
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data;
  }

  public async bulkUploadPreviewCustomers(
    file: File
  ): Promise<ApiResponse<{ data: ICustomerBulkImportResponse[] }>> {
    const url = `${this.apiDomain}/salesperson/bulkUploadCustomer/`;
    const formData = new FormData();
    formData.append('bulkUserFile', file);
    const response = await http.postAsFormData(url, formData);

    return response?.data;
  }

  public async submitBulkCustomer(ids?: string): Promise<any> {
    const url = `${this.apiDomain}/salesperson/bulkUploadCustomerConfirmation/`;
    const data = new FormData();
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data;
  }

  public async resendOTP(token: string) {
    const formData = new FormData();
    formData.append('userToken', token);

    const url = `${this.apiDomain}/user/resendOTP`;

    const response = await http.postAsFormData<ApiResponse<any>>(url, formData);
    return response?.data;
  }
}
