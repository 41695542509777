import { INotificationItem } from '../../../models/order-models';
import { createActionWithPayload, IActionWithPayload } from '../utils';
export interface NotificationState {
  notifications: INotificationItem[];
  isPopupOpened?: boolean;
  isLoading: boolean;
  isLoaded: boolean;
}

const initialState: NotificationState = {
  notifications: [],
  isLoading: false,
  isLoaded: false,
  isPopupOpened: false,
};

// reducer
export const notificationReducer = (
  state: NotificationState = initialState,
  action: IActionWithPayload
): NotificationState => {
  switch (action.type) {
    case NotificationActions.loadNotificationSuccess:
      return {
        ...state,
        notifications: action.payload,
        isLoaded: true,
      };
    case NotificationActions.setNotificationLoader:
      return {
        ...state,
        isLoading: action.payload,
      };
    case NotificationActions.setNotificationPopupOpen:
      return {
        ...state,
        isPopupOpened: action.payload,
      };
    case NotificationActions.NotificationMarkAllAdRead:
      const notifications = state.notifications ?? [];
      const clone = [...notifications];
      clone.forEach((p) => (p.isRead = true));
      return {
        ...state,
        notifications: clone,
      };

    case NotificationActions.NotificationAddNewItem:
      const existingNotifications = [...state.notifications];
      existingNotifications.unshift(action.payload);
      return {
        ...state,
        notifications: existingNotifications,
      };

    default:
      return state;
  }
};

export const NotificationActions = {
  loadNotificationSuccess: `Notification-load-notifications-success`,
  setNotificationLoader: `Notification-set-loader`,
  NotificationMarkAllAdRead: `Notification-mark-all-as-read`,
  NotificationAddNewItem: `Notification-add-new-item`,
  setNotificationPopupOpen: `Notification-set-notification-popup-open`,
};

export const onGetNotificationSuccessAction = (
  items: INotificationItem[]
): IActionWithPayload<any> => {
  return createActionWithPayload(
    NotificationActions.loadNotificationSuccess,
    items
  );
};

export const setLoaderForNotificationAction = (
  isLoading: boolean
): IActionWithPayload<any> => {
  return createActionWithPayload(
    NotificationActions.setNotificationLoader,
    isLoading
  );
};

export const markAllAsReadAction = (): IActionWithPayload<any> => {
  return createActionWithPayload(
    NotificationActions.NotificationMarkAllAdRead,
    null
  );
};

export const addNewNotificationAction = (
  notification: INotificationItem
): IActionWithPayload<any> => {
  return createActionWithPayload(
    NotificationActions.NotificationAddNewItem,
    notification
  );
};

export const setNotificationPopupVisibilityAction = (
  isOpen: boolean
): IActionWithPayload<any> => {
  return createActionWithPayload(
    NotificationActions.setNotificationPopupOpen,
    isOpen
  );
};
