import React, { useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';
import api from '../../../api';
import { FullPageLoader } from '../../../components/loader';
import { useCart } from '../../../core/hooks';
import { useQuotes } from '../../../core/hooks/useQuotes';
import { translate } from '../../../utils/text-utils';
import { UserHeader } from '../../header';
import { RouteKeys } from '../../routes/route-keys';
import { QuoteComponent } from './Cart';
import { InPageConfirmation } from './components/cart-confirm-popup';
import { ConfirmSummary } from './components/confirm-summary';

const QuoteConfirmationScreen: React.SFC = () => {
  const { quoteState, quotations, getQuotations } = useQuotes();
  const [loader, setLoader] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>();

  const onSubmit = async () => {
    try {
      setLoader(true);
      const response = await api.model.submitRequest2();
      await getQuotations();
      setIsSubmitted(true);
    } catch (err) {
      toastr.error('Error', 'Somethign went wrong');
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <UserHeader />
      <div className={`container ${isSubmitted ? 'px-0' : ''}`}>
        {!isSubmitted && (
          <div className="row pt-4">
            <div className="col-12 col-lg-8 pb-4">
              {(quotations?.length ?? 0) > 0 && (
                <InPageConfirmation
                  onConfirm={onSubmit}
                  text={`Verify the items and quanitity to place your quotation request. 
                    MyNaghi team will review and revert with an estimate for the same.`}
                ></InPageConfirmation>
              )}
            </div>
            <div className="col-12 col-lg-4">
              <div className="cartBlock bg-white">
                <QuoteComponent
                  disableSubmit={true}
                  showHeader={true}
                  quoteList={quotations ?? []}
                />
              </div>
            </div>
          </div>
        )}
        {isSubmitted && (
          <>
            <ConfirmSummary
              header={translate('quotation.quoteSubmitted')}
              text={translate('quotation.weWillNotify')}
              buttonTitle={translate('quotation.viewMyQuotes')}
              redirectionRoute={RouteKeys.Quotation}
            ></ConfirmSummary>
          </>
        )}
      </div>

      {(loader || quoteState.isQuoteLoading) && (
        <FullPageLoader></FullPageLoader>
      )}
    </>
  );
};

export default QuoteConfirmationScreen;
