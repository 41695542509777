import { INotificationResult } from '../../../models/order-models';
import { ApiResponse } from '../models';
import * as http from './http';
import ApiService from './service';

export class NotificationService extends ApiService {
  public async loadNotifications(
    params?: any
  ): Promise<ApiResponse<INotificationResult>> {
    const url = `${this.apiDomain}/user/notificationList/`;
    const data = new FormData();
    data.append('brandID', '');
    data.append('branchID', '');
    const response = await http.postAsFormData<ApiResponse<any>>(url, data);
    return response?.data;
  }

  public async markAsRead(ids: string): Promise<boolean> {
    const url = `${this.apiDomain}/user/read/`;
    const data = new FormData();
    data.append('brandID', '');
    data.append('branchID', '');
    data.append('notificationId', ids);
    const response = await http.postAsFormData<any>(url, data);
    return response?.data?.response === 'Success';
  }

  public async registerToken(
    deviceToken: string,
    deviceType: string
  ): Promise<boolean> {
    const url = `${this.apiDomain}/user/insertdevice/`;
    const data = new FormData();
    data.append('brandID', '');
    data.append('branchID', '');
    data.append('deviceToken', deviceToken);
    data.append('deviceType', deviceType);
    const response = await http.postAsFormData<any>(url, data);
    return response?.data?.response === 'Success';
  }
}
