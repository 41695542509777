import moment from 'moment';
import 'moment-timezone';
import { isNullOrEmpty } from './text-utils';

const DubaiTimezone = 'Asia/Dubai';

export const convertStringToDate = (dateString: string) => {
  if (isNullOrEmpty(dateString) || dateString === '0000-00-00 00:00:00') {
    return '-';
  }
  return moment(dateString).format(DateFormats.NormalFromat);
};

export const formatDate = (date: string | Date, dateFormat: string): string => {
  return moment(date).format(dateFormat);
};
export const formatUTCDate = (
  date: string | Date,
  dateFormat: string
): string => {
  return moment(date).utc().format(dateFormat);
};

export const getNormalDateformat = (date?: string | Date) => {
  return moment(date ?? new Date()).format(DateFormats.NormalFromat);
};

export const convertToTimezone = (
  date: Date,
  timezone: string = DubaiTimezone
) => {
  return moment(date).tz(timezone).toDate();
};

export const formatDateWithTimeZone = (
  date: string | Date,
  timezone: string = DubaiTimezone,
  dateFormat: string = DateFormats.NormalFromat
) => {
  return moment(date).tz(timezone).format(dateFormat);
};

export const tryFormatDate = (
  date: string | Date,
  dateFormat: string = DateFormats.NormalFromat
): string | Date => {
  try {
    return formatDate(date, dateFormat);
  } catch (e) {
    return date;
  }
};

export const tryFormatDateWithTimezone = (
  date: string | Date,
  timezone: string = DubaiTimezone,
  dateFormat: string = DateFormats.NormalFromat
): string | Date => {
  try {
    return formatDateWithTimeZone(date, timezone, dateFormat);
  } catch (e) {
    return date;
  }
};

export class DateFormats {
  static NormalFromat = 'DD-MM-YYYY, h:mm A';
  static MonthNameFormat = 'DD-MMM-YYYY';
  static DayMonthFormat = 'DD-MM-YYYY';
  static YearMonthFormat = 'YYYY-MM-DD';
  static DayMonthYearFormat = 'DD/MM/YYYY';
  static MonthNameOnly = 'MMM';
  static TimeOnlyFormat = 'h:mm A';
  static MonthWithDay = 'MMM DD';
  static NormalDateFormatWithoutHiphons = 'DD MMM YYYY';
}

export const addDays = (date: Date, days: number) => {
  return moment(date).add(days, 'days').toDate();
};

export const getUTCStartOfDate = (date: Date): Date => {
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );
};


export interface DataWithCount {
  date: Date | string;
  week?: number;
  month?: number;
  year?: number;
  count: number;
  key: string;
  displayText: string;
  index?: number;
  name?: string;
  leadIds?: string[];
  appointmentIds?: string[];
  customerIds?: string[];
  configIds?: string[];
}

export const calculateDaysBetweenDates = (date1: Date, date2: Date) => {
  const oneDay = 24 * 60 * 60 * 1000;
  const date1InMillis = date1.getTime();
  const date2InMillis = date2.getTime();
  const days = Math.round(Math.abs(date2InMillis - date1InMillis) / oneDay);
  return days;
};

export const isValidDate = (date: string) => {
  return !isNullOrEmpty(date) && date !== '0000-00-00 00:00:00';
};
