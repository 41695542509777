import React, { useState } from 'react';

import CopyClipboard from '../../../components/copy-to-clipboard';
import '../../../containers/search-tab/search-box.scss';
import { useQuotes } from '../../../core/hooks/useQuotes';
import { QuoteCounter } from './quote-counter';
import { IQuotation } from '../../../core/api/models';
import { useConfirmationDialog } from '../../../components/confirm-popup/confirm-popup';
import { toastr } from 'react-redux-toastr';
import { translate } from '../../../utils/text-utils';

export const QuoteItem = (props: {
  item: IQuotation;
  disabled?: boolean;
  quoteUserStatus?: string;
}) => {
  const { getConfirmation } = useConfirmationDialog();
  const pdt = props.item;
  const [loading, setLoading] = useState(false);
  const { removeQuote } = useQuotes();
  const removeFromQuote = async () => {
    const granded = await getConfirmation({
      title: translate('common.areYouSureToDeleteThisItem'),
    });
    if (granded) {
      try {
        setLoading(true);
        await removeQuote(pdt);
      } catch (err) {
        toastr.error('Error', 'Somethign went wrong');
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <div className="cartItem">
        <div className="cartItem__items">
          <div className="cartItem__product-count">
            {!props?.disabled && (
              <div className="cartItem__remove position-absolute font-md cursor-pointer z-9">
                <div className="tooltip left sm delete">
                  <i
                    onClick={() => {
                      if (props?.quoteUserStatus !== 'Inactive') {
                        removeFromQuote();
                      }
                    }}
                    className="icon-delete"
                    style={{
                      color:
                        props?.quoteUserStatus === 'Inactive' ? '#999' : '',
                    }}
                  ></i>
                  {props?.quoteUserStatus === 'Inactive' &&
                    <span className="tooltiptext position-absolute bg-white rounded text-left px-3 py-2">
                      <p className="font-weight-medium my-1 text-gray-900 font-sm">
                        {translate('common.inactive_user_message')}
                      </p>
                    </span>
                  }
                </div>
              </div>
            )}
            <div className="font-sm text-gray-900 font-weight-medium">
              {pdt.productName}
            </div>
            <div className="font-xs text-gray-600 w-100">
              <CopyClipboard value={pdt.partNumber}>
                <div className="copy copy--sm d-inline-block cursor-pointer">
                  {pdt.partNumber} <i className="icon-copy ml-1 font-xxxs"></i>
                </div>
              </CopyClipboard>
            </div>
          </div>
          <div className="cartItem__action-block">
            <div className="cartItem__price-block">
              <div className="tooltip position-relative sm">
                <QuoteCounter
                  item={pdt}
                  disabled={props.disabled}
                  quoteUserStatus={props?.quoteUserStatus}
                ></QuoteCounter>

                {props?.quoteUserStatus === 'Inactive' &&
                  <span className="tooltiptext position-absolute bg-white rounded text-left px-3 py-2">
                    <p className="font-weight-medium my-1 text-gray-900 font-sm">
                      {translate('common.inactive_user_message')}
                    </p>
                  </span>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <div className="numberField__loading">
          <img
            src={require('../../../components/counter/loading.gif')}
            alt="loading..."
          />
        </div>
      )}
    </div>
  );
};
