import moment from 'moment';
import React from 'react';
import Countdown from 'react-countdown';
import { translate } from '../utils/text-utils';

export const CountDownTimer = (props: {
  date: string;
  onZeroString: string;
}) => {
  const momentDate = moment(props.date);

  if (!momentDate.isValid()) {
    return <>{props.onZeroString}</>;
  }
  if (momentDate.isBefore(moment())) {
    return <>{props.onZeroString}</>;
  }
  const date = momentDate.toDate();

  return <Countdown renderer={CountdownRenderer} date={date} />;
};

export const CountdownRenderer = ({
  days,
  hours,
  minutes,
  seconds,
  completed,
}: any) => {
  if (completed) {
    return <span className="text-danger">{translate('orders.expired')}</span>;
  }

  return (
    <span className={hours < 1 && days < 1 ? 'text-danger' : ''}>
      {days > 1 ? `${days} Days ` : ''}{' '}
      {`${hours}h : ${minutes}m : ${seconds}s`}
    </span>
  );
};
