import React from 'react';
import Currency from 'react-currency-formatter';
import { translate } from '../utils/text-utils';
export const CurrencyFormatter = (props: { quantity?: any }) => {
  const amount = props.quantity ?? '0';
  return (
    <>
      {translate('DashBoard.SAR')}{' '}
      <Currency
        quantity={amount}
        // quantity={Math.round(Number(amount)).toFixed(0).split('.')[0]}
        currency=""
        locale="en"
        // pattern="##,### !"
      />
    </>
  );
};
