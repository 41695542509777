import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../store/interfaces';
import api from '../../api';
import { countriesGetAction, brandSetLoaderAction } from '../store';

export const useCountries = () => {
  const countriesState = useSelector((state: State) => state.countries);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (
  //     !countriesState.isCountriesLoaded &&
  //     !countriesState.isCountriesLoading
  //   ) {
  //     getCountries();
  //   }
  // }, []);

  const getCountries = async () => {
    dispatch(brandSetLoaderAction(true));
    const response = await api.model.getCountryList();
    dispatch(countriesGetAction(response));
    dispatch(brandSetLoaderAction(false));
  };

  return {
    getCountries,
    countries: countriesState.countries,
    loaded: countriesState.isCountriesLoaded,
    loading: countriesState.isCountriesLoading,
    countriesState,
  };
};
