import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import Select from 'react-select';
import api from '../../../api';
// import { CrudListRequestModel } from '../../../api/models';

export interface Dict<T = any> {
  [key: string]: T;
}

export interface CrudRequestModel {
  resource?: string;
}

export interface CrudListRequestModel extends CrudRequestModel {
  pagination: { page?: number; perPage?: number };
  sort: { field?: string; order?: string };
  filter?: {};
  queryParams?: Dict;
}

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    height: '46px',
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: '10',
  }),
  option: (provided: any, state: any) => {
    return {
      ...provided,
      background: state.isFocused ? '#f2f9f9' : '#fff',
      color: '#1d1d1d',
    };
  },
};

interface CustomProps {
  default?: string | number;
  api?: string;
  data?: any[];
  placeholder?: string;
  label?: string;
  id?: string;
  optionLabel?: string;
  optionValue?: string;
  onChange: (value: any, selected: any) => void;
  gap?: 'sm' | 'md' | 'lg' | 'no';
  // size: PropTypes.oneOf(['lg']),
  error?: string;
  name?: string;
  defaultIfNotFound?: string | number;
  disabled?: boolean;
  stickyStyles?: {};
  isSearchable?: boolean;
  isRtl?: boolean;
  refreshOption?: boolean;
  isMulti?: boolean
}

interface CustomState {
  isLoading?: boolean;
  isRtl?: boolean;
  isSearchable?: boolean;
  selected: any;
  options: any[];
  selectedValue?: string | number;
}

class SelectInput extends Component<CustomProps, CustomState> {
  static defaultProps: Partial<CustomProps> = {
    default: '',
    gap: 'md',
    placeholder: 'Select...',
    error: '',
    name: '',
    label: '',
    data: [],
    disabled: false,
    optionLabel: 'label',
    optionValue: 'value',
    stickyStyles: {},
    isSearchable: true,
    isRtl: false,
    refreshOption: false,
  };

  constructor(props: CustomProps) {
    super(props);
    this.state = {
      isLoading: false,
      isRtl: props.isRtl,
      isSearchable: props.isSearchable,
      selected: '',
      options: [],
      selectedValue: props.default,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    // const options = [];
    // if (this.props.api) {
    //   const request: CrudListRequestModel = {
    //     resource: this.props.api,
    //     pagination: {},
    //     sort: {},
    //   };
    //   this.toggleLoading();
    //   const { data } = await api.crud.getList(request);
    //   this.toggleLoading();

    //   if (
    //     this.props.default &&
    //     data.result.filter(
    //       (el: any) => el[this.props.optionValue || ''] === this.props.default
    //     ).length === 0
    //   ) {
    //     this.setState(() => ({
    //       selectedValue: this.props.defaultIfNotFound,
    //     }));
    //     this.handleChange({
    //       [this.props.optionValue || '']: this.props.defaultIfNotFound,
    //     });
    //   }
    //   this.setState(() => ({
    //     options: data.result,
    //   }));
    // } else {
    //   this.setState(
    //     (prevState: CustomState): CustomState => ({
    //       ...prevState,
    //       options: this.props.data || [],
    //     })
    //   );
    // }

    this.setState(
      (prevState: CustomState): CustomState => ({
        ...prevState,
        options: this.props.data || [],
      })
    );
  }

  componentDidUpdate(prevProps: CustomProps) {
    if (prevProps.default !== this.props.default) {
      this.updateDefault();
    }
  }

  toggleLoading = () =>
    this.setState((state) => ({ isLoading: !state.isLoading }));

  toggleRtl = () => this.setState((state) => ({ isRtl: !state.isRtl }));

  toggleSearchable = () =>
    this.setState((state) => ({ isSearchable: !state.isSearchable }));

  updateDefault() {
    if (this.props.default !== undefined) {
      this.setState({ selectedValue: this.props.default });
    }
  }

  handleChange(selected: any) {
    // const value = event.value;
    this.setState({
      selected,
      selectedValue: selected[this.props.optionValue || ''],
    });
    this.props.onChange(selected[this.props.optionValue || ''], selected);
  }

  showValue() {
    return this.state.options.filter((option) => {
      if (this.state.selectedValue !== undefined) {
        return (
          option[this.props.optionValue || ''] === this.state.selectedValue
        );
      }
      return (
        option[this.props.optionValue || ''] ===
        this.state.selected[this.props.optionValue || '']
      );
    });
  }

  render() {
    const {
      gap,
      name,
      label,
      placeholder,
      stickyStyles,
      error,
      id,
    } = this.props;
    const { isSearchable, isLoading, isRtl } = this.state;
    const inputIdCandidate = id || name || label || placeholder;
    const inputId = inputIdCandidate;
    return (
      <div
        className={`textField textField--margin-${gap}`}
        style={stickyStyles}
      >
        {label && (
          <label
            htmlFor={inputId}
            className="textField__label textField__label--full"
          >
            {label}
          </label>
        )}
        <Select
          styles={customStyles}
          className="basic-single"
          classNamePrefix="select"
          isLoading={isLoading}
          isRtl={isRtl}
          isSearchable={isSearchable}
          placeholder={this.props.placeholder}
          getOptionLabel={(option) => option[this.props.optionLabel || '']}
          getOptionValue={(option) => option[this.props.optionValue || '']}
          options={this.state.options}
          onChange={this.handleChange}
          value={this.showValue()}
          isDisabled={this.props.disabled ? this.props.disabled : false}
          id={inputId}
          isMulti={this.props.isMulti}
        />
        {error && <span className="textField__validation">{error}</span>}
      </div>
    );
  }
}

export default SelectInput;
