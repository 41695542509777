import React, { Component } from 'react';
import TextInput from '../text-input';
import '../Input.scss';
import './PhoneInput.scss';
import './flag.scss';

import countries from './countries.json';

interface CustomProps {
  data: any[];
  optionLabel?: string;
  optionValue?: string;
  onChange: (value: any) => void;
  disabled?: boolean;
  disableCountryCode?: boolean;
  error?: string;
  name?: string;
  label?: string;
  placeholder?: string;
  type?: 'text' | 'number';
  gap?: 'sm' | 'md' | 'lg';
  defaultCountryCode?: string | undefined;
  defaultDialCode?: string | undefined;
  defaultEntered?: string | number;
  zIndex?: number;
  width?: {
    code?: number;
    select?: number;
    text?: number;
  };
  enableSelectSearch?: boolean;
  disableSelected?: boolean;
  disableNumberScrolls?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
}

interface CustomState {
  value: {
    dialCode: string | undefined;
    countryCode: string | undefined;
    entered?: string | number;
  };
  showDropdown: boolean;
}

class SelectPhoneInput extends Component<CustomProps, CustomState> {
  static defaultProps: Partial<CustomProps> = {
    placeholder: '',
    error: '',
    name: '',
    label: '',
    optionLabel: 'label',
    optionValue: 'value',
    type: 'text',
    gap: 'md',
    defaultCountryCode: '',
    defaultDialCode: '',
    defaultEntered: '',
    zIndex: 1,
    width: { code: 15, select: 31, text: 55 },
    enableSelectSearch: false,
    disableSelected: false,
    disableNumberScrolls: false,
    disabled: false,
    disableCountryCode: false
  };
  constructor(props: CustomProps) {
    super(props);
    this.state = {
      value: {
        dialCode: props.defaultDialCode,
        countryCode: props.defaultCountryCode,
        entered: props.defaultEntered,
      },
      showDropdown: false,
    };
    this.handlerFlagClick = this.handlerFlagClick.bind(this);
    this.handlerFlagItemClick = this.handlerFlagItemClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps: CustomProps) {
    if (
      this.props.defaultEntered &&
      prevProps.defaultEntered !== this.props.defaultEntered
    ) {
      this.updateDefault();
    }
  }

  updateDefault() {
    if (this.props.defaultEntered !== undefined) {
      this.setState((prevState) => {
        const value = {
          dialCode: this.props.defaultDialCode,
          countryCode: this.props.defaultCountryCode,
          entered: this.props.defaultEntered,
        };
        this.props.onChange(value);
        return { value };
      });
    }
  }

  handlerFlagClick() {
    if (this.props.disabled) {
      return;
    }
    const { showDropdown } = this.state;
    this.setState({ showDropdown: !showDropdown });
  }

  handlerFlagItemClick(code: string, countryCode: string) {
    this.setState((prevState) => {
      const value = {
        dialCode: code,
        countryCode,
        entered: prevState.value.entered,
      };
      this.props.onChange(value);
      return { value, showDropdown: false };
    });
  }

  handleChange(e: { target: HTMLInputElement }) {
    const fieldValue: string | number = e.target.value;
    this.setState((prevState) => {
      const value = {
        dialCode: prevState.value.dialCode,
        countryCode: prevState.value.countryCode,
        entered: fieldValue,
      };
      this.props.onChange(value);
      return { value };
    });
  }

  render() {
    const {
      data,
      optionLabel,
      optionValue,
      error,
      name,
      label,
      placeholder,
      gap,
      type,
      zIndex,
      width,
      enableSelectSearch,
      disabled,
      disableCountryCode,
      onFocus,
      onBlur,
    } = this.props;

    const { showDropdown, value } = this.state;
    const selectProps = { data, optionLabel, optionValue };
    const textInputProps = { placeholder, type };
    const inputIdCandidate = name || label || placeholder;
    const inputId = inputIdCandidate;
    const countryCode = countries?.find((x) => x.dial_code === value.dialCode)
      ?.code;
    return (
      <div className={`textField textField--margin-${gap} PhoneInput`}>
        {label && (
          <label
            className="textField__label"
            htmlFor={`${inputId}-country-code`}
          >
            {label}
          </label>
        )}

        <div className="flag__dropdown">
          <div className="flag-container">
            <div
              className="flag__selected-flag"
              onClick={() => {
                if(!disableCountryCode) {
                  this.handlerFlagClick();
                }
              }}
            >
              <div
                className={`flag flag__${
                  value.countryCode
                    ? value.countryCode.toLowerCase()
                    : countryCode?.toLowerCase()
                  }`}
              ></div>
              <div className="country__code">{value.dialCode}</div>
              <div className="flag__arrow"></div>
            </div>
          </div>
          {showDropdown && (
            <ul className="country-list ">
              {countries.map(
                (cntry: { dial_code: string; name: string; code: string }) => {
                  return (
                    <li
                      className="country"
                      data-dial-code={cntry.dial_code}
                      data-country-code="us"
                      onClick={() => {
                        this.handlerFlagItemClick(cntry.dial_code, cntry.code);
                      }}
                    >
                      <div className="country__flag-box">
                        <div
                          className={`flag flag__${cntry.code.toLowerCase()}`}
                        ></div>
                      </div>
                      <div>
                        <span className="country__name">{cntry.name}</span>
                        <span className="country__code">{cntry.dial_code}</span>
                      </div>
                    </li>
                  );
                }
              )}
            </ul>
          )}
          <input
            id={inputId}
            className={`textField__input`}
            type={type}
            placeholder={placeholder}
            onChange={this.handleChange}
            value={value.entered}
            disabled={disabled}
            onWheel={(e) =>
              this.props.disableNumberScrolls && e.preventDefault()
            }
            onScroll={(e) =>
              this.props.disableNumberScrolls && e.preventDefault()
            }
            onKeyDown={(e) =>
              this.props.disableNumberScrolls &&
              (e.keyCode === 38 || e.keyCode === 40) &&
              e.preventDefault()
            }
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </div>
        {error && <span className="textField__validation">{error}</span>}
      </div>
    );
  }
}

export default SelectPhoneInput;
