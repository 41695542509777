import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDataLoader } from 'react-remote-data-hooks';
import { useHistory } from 'react-router-dom';
import api from '../../../api';
import { AppTable, IColumn } from '../../../components/grid/app-table';

import { FilterValueType } from '../../../components/list-filters/saved-filters';
import { ContainerLoader } from '../../../components/loader';
import Pagination from '../../../components/pagination/Pagination';
import { Dict } from '../../../models';
import { IOrder, IOrderListParams } from '../../../models/order-models';
import { translate } from '../../../utils/text-utils';
import { TabFilters } from '../order-listing-screen';
import { FilterTypes, OrderFilterComponent } from './order-filter-component';

export const OrderListingComponent = (props: {
  status: TabFilters;
  filter: Dict<FilterValueType>;
  filterTypes: FilterTypes[];
  search: string;
  onDataFetch?: any;
  date: any;
  onFilterChange: any;
  columns: IColumn[];
}) => {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const brandID = (props?.filter?.[FilterTypes.brand] as any)?.value;
  const branchIDs = (props?.filter?.[FilterTypes.branch] as any)
    ?.map((p: any) => p.value)
    ?.join(',');
  const payamentID = (props?.filter?.[FilterTypes.paymentMethod] as any)
    ?.map((p: any) => p.value)
    ?.join(',');
  const paymentStatus = (props?.filter?.[FilterTypes.paymentStatus] as any)
    ?.value;
  const requestedBy = (props?.filter?.[FilterTypes.requestedBy] as any)?.map(
    (p: any) => {
      return {
        userID: p.value,
        role: p.role,
      };
    }
  );
  const approvedBy = (props?.filter?.[FilterTypes.approvedBy] as any)?.map(
    (p: any) => {
      return {
        userID: p.value,
        role: p.role,
      };
    }
  );
  const cancelledBy = (props?.filter?.[FilterTypes.cancelledBy] as any)?.map(
    (p: any) => {
      return {
        userID: p.value,
        role: p.role,
      };
    }
  );
  const rejectedBy = (props?.filter?.[FilterTypes.rejectedBy] as any)?.map(
    (p: any) => {
      return {
        userID: p.value,
        role: p.role,
      };
    }
  );
  const salesperson = (props?.filter?.[FilterTypes.salesPerson] as any)
    ?.map((p: any) => p.value)
    ?.join(',');

  const createdFor = (props?.filter?.[FilterTypes.createdFor] as any)
    ?.map((p: any) => p.value)
    ?.join(',');

  const { data, loading, loaded, reload } = useDataLoader<any>(() => {
    let requestedByString;
    if (requestedBy) {
      requestedByString = JSON.stringify(requestedBy);
    }

    let approvedByString;
    if (approvedBy) {
      approvedByString = JSON.stringify(approvedBy);
    }
    let rejectedByString;
    if (rejectedBy) {
      rejectedByString = JSON.stringify(rejectedBy);
    }
    let cancelledByString: string;
    if (cancelledBy) {
      cancelledByString = JSON.stringify(cancelledBy);
    }
    let startDate;
    let endDate;
    if (props.date?.startDate && props.date?.endDate) {
      startDate = moment(props.date?.startDate).format('YYYY-MM-DD');
      endDate = moment(props.date?.endDate).format('YYYY-MM-DD');
    }
    const param: IOrderListParams = {
      status: props.status,
      brand: brandID,
      branchIDs,
      requestID: props.search,
      requestedBy: requestedByString,
      approvedBy: approvedByString,
      paymentID: payamentID,
      paymentStatus,
      cancelledBy: cancelledByString,
      rejectedBy: rejectedByString,
      createdFor,
      salesperson,
      startDate,
      endDate,
      perPage,
      page,
    };
    return api.order.getOrderRequestsDuplicate(param);
  });
  const [rendered, setRendered] = useState(false);
  useEffect(() => {
    setRendered(true);
  }, []);
  useEffect(() => {
    if (rendered) {
      reload();
    }
  }, [page]);
  useEffect(() => {
    if (data?.ordersCountByStatus) {
      props.onDataFetch(data.ordersCountByStatus);
    }
  }, [data]);
  const getTotalPages = () => {
    const count = data?.count ?? 0;

    if (count > 0) {
      const pageCount = count / perPage;

      return Math.trunc(pageCount) === pageCount
        ? pageCount
        : Math.trunc(pageCount) + 1;
    }
    return 0;
  };

  return (
    <>
      <div className="list-head">
        <OrderFilterComponent
          filter={props.filter}
          onFilterChange={(filter) => {
            props.onFilterChange(props.status, filter);
          }}
          filterTypes={props.filterTypes}
        ></OrderFilterComponent>
      </div>
      <div>
        <div className="table-block grid-table table-hover">
          <AppTable
            onRowClick={(orderData: IOrder) => {
              history.push(`/order-detail/${orderData.masterOrderID}`);
            }}
            isLoading={loading}
            data={data?.result ?? []}
            columns={props.columns}
            showEmptyResult={true}
          ></AppTable>
        </div>
        {loading && (
          <div className="py-5">
            <ContainerLoader />
          </div>
        )}
        <Pagination
          currentPage={page}
          totalPages={getTotalPages()}
          onPageChange={(pg: number) => {
            setPage(pg);
          }}
          perPage={perPage}
          onPerPageChange={(pg: number) => {
            setPerPage(pg);
          }}
          count={data?.count ?? 0}
          label={translate('customerScreen.report')}
        />
      </div>
    </>
  );
};
