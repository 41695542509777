import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../store/interfaces';
import api from '../../api';
import { userToBrandGetAction, brandSetLoaderAction } from '../store';

export const useUserToBrand = () => {
  const userToBrandState = useSelector((state: State) => state.userToBrand);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (
  //     !userToBrandState.isUserToBrandLoaded &&
  //     !userToBrandState.isUserToBrandLoading
  //   ) {
  //     getUserToBrand();
  //   }
  // }, []);

  const getUserToBrand = async () => {
    dispatch(brandSetLoaderAction(true));
    const response = await api.model.getUserBrands();
    dispatch(userToBrandGetAction(response));
    dispatch(brandSetLoaderAction(false));
  };

  return {
    getUserToBrand,
    userToBrand: userToBrandState.userToBrand,
    loaded: userToBrandState.isUserToBrandLoaded,
    loading: userToBrandState.isUserToBrandLoading,
    userToBrandState,
  };
};
