import React from 'react';
import { translate } from '../../utils/text-utils';
import empty from '../../containers/home/cart/empty.png';

export const NoResult = () => {
  return (
    <div className="cartBlock__item py-5">
      <div className="cartBlock__itemHead">
        {translate('orders.No_Result')}
      </div>
      <div className="cartBlock__img mt-3">
        <img src={empty} alt="" />
      </div>
    </div>
  );
};
