import { FilterTypes } from "../../../containers/orders/components/order-filter-component";
import { IBrandWiseTotal, ISalesManReport, ISalesPerformanceReport, SKUReportData, TopSalesPersonData } from "../models/report";
import { ApiResponse, EscalationReport, SalesPersonActivity, SalesPersonActivityDetail, SalesPersonUsage, VisitReport, VisitReportCustomer, YearPerformanceCustomer, YearToYearPerformance } from "../models";
import ApiService from "./service"
import * as http from './http';
import { da } from "date-fns/locale";
import { CustomerUsage, CustomerUsageDetail, DeletedCartData, DeletedProductCartCustomer, InactiveCustomer } from "../models/customer";


export class SalesPersonReportSevice extends ApiService {

  // get escalation report

  public async getEscalationReport(params: any): Promise<ApiResponse<EscalationReport[]>> {
    const url = `${this.apiDomain}/report/escalation/`;
    const data = new FormData();
    // if (params?.salesPerson) data.append('salesPerson', params.salesPerson);
    if (params?.searchTerm) data.append('searchTerm', params.searchTerm);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    // data.append('limitFrom', params.limitFrom );
    // data.append('limitTo', params.limitTo );
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    if (params?.sort) data.append('sort', params.sort);
    if (params?.orderby) data.append('orderby', params.orderby);
    data.append('brandID', '');
    data.append('branchID', '' );
    if (params?.export) {
      data.append('export', 'Yes');
    }
    

    if (params?.filter) {
      Object.keys(params?.filter)?.forEach((p) => {
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('brandID', value);
          }
        }
        if (p === FilterTypes.salesPerson) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((sales) => {
            if (sales.value) val.push(sales.value);
            return;
          });
          if (val.length > 0) {
            data.append('salesPerson', val.toString());
          }
        }
        if (p === FilterTypes.ageing) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('escDays', value);
          }
        }
        if (p === FilterTypes.escalationType) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('escType', value);
          }
        }
      });
    }
    const response = await http.postAsFormData<ApiResponse<EscalationReport[]>>(url, data);
    return response?.data;
  }

  // get sales person usage details
  public async getSalesPersonUsageReport(params: any): Promise<ApiResponse<SalesPersonUsage[]>> {
    const url = `${this.apiDomain}/report/salespersonUsage/`;
    const data = new FormData();
    // if (params?.salesPerson) data.append('salesPerson', params.salesPerson);
    if (params?.searchTerm) data.append('searchTerm', params.searchTerm);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    // data.append('limitFrom', params.limitFrom );
    // data.append('limitTo', params.limitTo );
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    if (params?.sort) data.append('sort', params.sort);
    if (params?.orderby) data.append('orderby', params.orderby);
    data.append('brandID', '');
    data.append('branchID', '' );
    if (params?.export) {
      data.append('export', 'Yes');
    }

    if (params?.filter) {
      Object.keys(params?.filter)?.forEach((p) => {
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('brandID', value);
          }
        }
        if (p === FilterTypes.salesPerson) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((sales) => {
            if (sales.value) val.push(sales.value);
            return;
          });
          if (val.length > 0) {
            data.append('salesPerson', val.toString());
          }
        }
      });
    }
    const response = await http.postAsFormData<ApiResponse<SalesPersonUsage[]>>(url, data);
    return response?.data;
  }

  // salesperson activity report
  public async getSalesPersonActivityReport(params: any): Promise<ApiResponse<SalesPersonActivity[]>> {
    const url = `${this.apiDomain}/report/salespersonActivityReport/`;
    const data = new FormData();
    // if (params?.salesPerson) data.append('salesPerson', params.salesPerson);
    if (params?.searchTerm) data.append('searchTerm', params.searchTerm);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    // data.append('limitFrom', params.limitFrom );
    // data.append('limitTo', params.limitTo );
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    if (params?.sort) data.append('sort', params.sort);
    if (params?.orderby) data.append('orderby', params.orderby);
    data.append('brandID', '');
    data.append('branchID', '' );
    if (params?.export) {
      data.append('export', 'Yes');
    }

    if (params?.filter) {
      Object.keys(params?.filter)?.forEach((p) => {
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('brandID', value);
          }
        }
        if (p === FilterTypes.salesPerson) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((sales) => {
            if (sales.value) val.push(sales.value);
            return;
          });
          if (val.length > 0) {
            data.append('salesPerson', val.toString());
          }
        }
      });
    }
    const response = await http.postAsFormData<ApiResponse<SalesPersonActivity[]>>(url, data);
    return response?.data;
  }

  // salesperson activity view report
  public async getSalesPersonActivityViewReport(params: any): Promise<SalesPersonActivityDetail> {
    const url = `${this.apiDomain}/report/salespersonActivityReportDetails/`;
    const data = new FormData();
    if (params?.searchUserID) data.append('searchUserID', params.searchUserID);
    if (params?.detailDate) data.append('detailDate', params.detailDate);
    if (params?.searchTerm) data.append('searchTerm', params.searchTerm);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    // data.append('limitFrom', params.limitFrom );
    // data.append('limitTo', params.limitTo );
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    if (params?.sort) data.append('sort', params.sort);
    if (params?.orderby) data.append('orderby', params.orderby);
    data.append('brandID', '');
    data.append('branchID', '' );
    if (params?.export) {
      data.append('export', 'Yes');
    }

    if (params?.filter) {
      Object.keys(params?.filter)?.forEach((p) => {
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('brandID', value);
          }
        }
        if (p === FilterTypes.salesPerson) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((sales) => {
            if (sales.value) val.push(sales.value);
            return;
          });
          if (val.length > 0) {
            data.append('salesPerson', val.toString());
          }
        }
      });
    }
    const response = await http.postAsFormData<ApiResponse<SalesPersonActivityDetail>>(url, data);
    return response?.data?.result;
  }

  // customer activity
  public async getCustomerActivityReport(params: any): Promise<ApiResponse<SalesPersonActivity[]>> {
    const url = `${this.apiDomain}/report/customerActivityReport/`;
    const data = new FormData();
    // if (params?.salesPerson) data.append('salesPerson', params.salesPerson);
    if (params?.searchTerm) data.append('searchTerm', params.searchTerm);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    // data.append('limitFrom', params.limitFrom );
    // data.append('limitTo', params.limitTo );
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    if (params?.sort) data.append('sort', params.sort);
    if (params?.orderby) data.append('orderby', params.orderby);
    data.append('brandID', '');
    data.append('branchID', '' );
    if (params?.export) {
      data.append('export', 'Yes');
    }

    if (params?.filter) {
      Object.keys(params?.filter)?.forEach((p) => {
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('brandID', value);
          }
        }
        if (p === FilterTypes.salesPerson) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((sales) => {
            if (sales.value) val.push(sales.value);
            return;
          });
          if (val.length > 0) {
            data.append('salesPerson', val.toString());
          }
        }
      });
    }
    const response = await http.postAsFormData<ApiResponse<SalesPersonActivity[]>>(url, data);
    return response?.data;
  }

  // customer activity view
  public async getCustomerActivityViewReport(params: any): Promise<SalesPersonActivityDetail> {
    const url = `${this.apiDomain}/report/customerActivityReportDetails/`;
    const data = new FormData();
    if (params?.searchUserID) data.append('searchUserID', params.searchUserID);
    if (params?.detailDate) data.append('detailDate', params.detailDate);
    if (params?.searchTerm) data.append('searchTerm', params.searchTerm);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    // data.append('limitFrom', params.limitFrom );
    // data.append('limitTo', params.limitTo );
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    if (params?.sort) data.append('sort', params.sort);
    if (params?.orderby) data.append('orderby', params.orderby);
    data.append('brandID', '');
    data.append('branchID', '' );
    if (params?.export) {
      data.append('export', 'Yes');
    }

    if (params?.filter) {
      Object.keys(params?.filter)?.forEach((p) => {
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('brandID', value);
          }
        }
        if (p === FilterTypes.salesPerson) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((sales) => {
            if (sales.value) val.push(sales.value);
            return;
          });
          if (val.length > 0) {
            data.append('salesPerson', val.toString());
          }
        }
      });
    }
    const response = await http.postAsFormData<ApiResponse<SalesPersonActivityDetail>>(url, data);
    return response?.data?.result;
  }

  // year to year performance
  public async getYearPerformanceReport(params: any): Promise<YearToYearPerformance[]> {
    const url = `${this.apiDomain}/report/yearToYearPerformance/`;
    const data = new FormData();
    // if (params?.salesPerson) data.append('salesPerson', params.salesPerson);
    if (params?.searchTerm) data.append('searchTerm', params.searchTerm);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    // data.append('limitFrom', params.limitFrom );
    // data.append('limitTo', params.limitTo );
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    if (params?.year) data.append('year', params.year);
    if (params?.day) {
      data.append('day', 'Yes')
    }
    if (params?.month) {
      data.append('month', 'Yes')
    }
    if (params?.quarter) {
      data.append('quarter', 'Yes')
    }
    if (params?.week) {
      data.append('week', 'Yes')
    }
    data.append('brandID', '');
    data.append('branchID', '');
    if (params?.export) {
      data.append('export', 'Yes');
    }

    if (params?.filter) {
      Object.keys(params?.filter)?.forEach((p) => {
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('brandID', value);
          }
        }
        if (p === FilterTypes.branch) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('branchID', value);
          }
        }
        if (p === FilterTypes.salesPerson) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((sales) => {
            if (sales.value) val.push(sales.value);
            return;
          });
          if (val.length > 0) {
            data.append('salesPerson', val.toString());
          }
        }
        if (p === FilterTypes.supervisor) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('supervisor', value);
          }
        }
      });
    }
    const response = await http.postAsFormData<ApiResponse<YearToYearPerformance[]>>(url, data);
    return response?.data?.result;
  }

  public async getYearPerformanceReportDuplicate(params: any): Promise<ApiResponse<YearToYearPerformance[]>> {
    const url = `${this.apiDomain}/report/yearToYearPerformance/`;
    const data = new FormData();
    // if (params?.salesPerson) data.append('salesPerson', params.salesPerson);
    if (params?.searchTerm) data.append('searchTerm', params.searchTerm);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    // data.append('limitFrom', params.limitFrom );
    // data.append('limitTo', params.limitTo );
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    if (params?.year) data.append('year', params.year);
    if (params?.day) {
      data.append('day', 'Yes')
    }
    if (params?.month) {
      data.append('month', 'Yes')
    }
    if (params?.quarter) {
      data.append('quarter', 'Yes')
    }
    if (params?.week) {
      data.append('week', 'Yes')
    }
    data.append('brandID', '');
    data.append('branchID', '');
    if (params?.export) {
      data.append('export', 'Yes');
    }

    if (params?.filter) {
      Object.keys(params?.filter)?.forEach((p) => {
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('brandID', value);
          }
        }
        if (p === FilterTypes.branch) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('branchID', value);
          }
        }
        if (p === FilterTypes.salesPerson) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((sales) => {
            if (sales.value) val.push(sales.value);
            return;
          });
          if (val.length > 0) {
            data.append('salesPerson', val.toString());
          }
        }
      });
    }
    const response = await http.postAsFormData<ApiResponse<YearToYearPerformance[]>>(url, data);
    return response?.data;
  }


  public async getYearPerformanceDetailReport(params: any): Promise<YearPerformanceCustomer[]> {
    const url = `${this.apiDomain}/report/yearToYearGetCustomer/`;
    const data = new FormData();
    if (params?.salesPerson) data.append('salesPerson', params.salesPerson);
    if (params?.searchTerm) data.append('searchTerm', params.searchTerm);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    // data.append('limitFrom', params.limitFrom );
    // data.append('limitTo', params.limitTo );
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    if (params?.year) data.append('year', params.year);
    if (params?.day) {
      data.append('day', 'Yes')
    }
    if (params?.month) {
      data.append('month', 'Yes')
    }
    if (params?.quarter) {
      data.append('quarter', 'Yes')
    }
    if (params?.week) {
      data.append('week', 'Yes')
    }
    data.append('brandID', '');
    data.append('branchID', '');
    if (params?.export) {
      data.append('export', 'Yes');
    }

    if (params?.filter) {
      Object.keys(params?.filter)?.forEach((p) => {
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('brandID', value);
          }
        }
        if (p === FilterTypes.branch) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('branchID', value);
          }
        }
        if (p === FilterTypes.salesPerson) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((sales) => {
            if (sales.value) val.push(sales.value);
            return;
          });
          if (val.length > 0) {
            data.append('salesPerson', val.toString());
          }
        }
      });
    }
    const response = await http.postAsFormData<ApiResponse<YearPerformanceCustomer[]>>(url, data);
    return response?.data?.result;
  }

  //visit report
  public async getVisitReport(params: any): Promise<VisitReport[]> {
    const url = `${this.apiDomain}/report/visitReport/`;
    const data = new FormData();
    if (params?.salesPerson) data.append('salesPerson', params.salesPerson);
    if (params?.searchTerm) data.append('searchTerm', params.searchTerm);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    // data.append('limitFrom', params.limitFrom );
    // data.append('limitTo', params.limitTo );
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    if (params?.year) data.append('year', params.year);
    if (params?.day) {
      data.append('day', 'Yes')
    }
    if (params?.month) {
      data.append('month', 'Yes')
    }
    if (params?.quarter) {
      data.append('quarter', 'Yes')
    }
    if (params?.week) {
      data.append('week', 'Yes')
    }
    data.append('brandID', '');
    data.append('branchID', '');
    if (params?.export) {
      data.append('export', 'Yes');
    }

    if (params?.filter) {
      Object.keys(params?.filter)?.forEach((p) => {
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('brandID', value);
          }
        }
        if (p === FilterTypes.branch) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('branchID', value);
          }
        }
        if (p === FilterTypes.salesPerson) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((sales) => {
            if (sales.value) val.push(sales.value);
            return;
          });
          if (val.length > 0) {
            data.append('salesPerson', val.toString());
          }
        }
      });
    }
    const response = await http.postAsFormData<ApiResponse<VisitReport[]>>(url, data);
    return response?.data?.result;
  }

  public async getVisitReportDuplicate(params: any): Promise<ApiResponse<VisitReport[]>> {
    const url = `${this.apiDomain}/report/visitReport/`;
    const data = new FormData();
    if (params?.salesPerson) data.append('salesPerson', params.salesPerson);
    if (params?.searchTerm) data.append('searchTerm', params.searchTerm);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    // data.append('limitFrom', params.limitFrom );
    // data.append('limitTo', params.limitTo );
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    if (params?.year) data.append('year', params.year);
    if (params?.day) {
      data.append('day', 'Yes')
    }
    if (params?.month) {
      data.append('month', 'Yes')
    }
    if (params?.quarter) {
      data.append('quarter', 'Yes')
    }
    if (params?.week) {
      data.append('week', 'Yes')
    }
    data.append('brandID', '');
    data.append('branchID', '');
    if (params?.export) {
      data.append('export', 'Yes');
    }

    if (params?.filter) {
      Object.keys(params?.filter)?.forEach((p) => {
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('brandID', value);
          }
        }
        if (p === FilterTypes.branch) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('branchID', value);
          }
        }
        if (p === FilterTypes.salesPerson) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((sales) => {
            if (sales.value) val.push(sales.value);
            return;
          });
          if (val.length > 0) {
            data.append('salesPerson', val.toString());
          }
        }
      });
    }
    const response = await http.postAsFormData<ApiResponse<VisitReport[]>>(url, data);
    return response?.data;
  }
  
  // visit report customer detail
  public async getVisitReportDetailReport(
    params: any
  ): Promise<ApiResponse<VisitReportCustomer>> {
    const url = `${this.apiDomain}/report/visitReportGetCustomer/`;
    const data = new FormData();
    if (params?.salesPerson) data.append('salesPerson', params.salesPerson);
    if (params?.searchTerm) data.append('searchTerm', params.searchTerm);
    if (params?.status) data.append('status', params.status);
    if (params?.page) data.append('page', params.page);
    if (params?.perPage) data.append('perPage', params.perPage);
    if (params?.fromDate) data.append('fromDate', params.fromDate);
    if (params?.specificDate) data.append('specificDate', params.specificDate);
    if (params?.toDate) data.append('toDate', params.toDate);
    if (params?.userID) data.append('userID', params.userID);
    data.append('brandID', '');
    data.append('branchID', '');
    if (params?.day) {
      data.append('day', 'Yes')
    }
    if (params?.month) {
      data.append('month', 'Yes')
    }
    if (params?.quarter) {
      data.append('quarter', 'Yes')
    }
    if (params?.week) {
      data.append('week', 'Yes')
    }
    if (params?.export) {
      data.append('export', 'Yes');
    }

    if (params?.filter) {
      Object.keys(params?.filter)?.forEach((p) => {
        if (p === FilterTypes.brand) {
          const value = (params.filter as any)[p]?.value;
          if (value) {
            data.append('brandID', value);
          }
        }
        if (p === FilterTypes.salesPerson) {
          const val: string[] = [];
          (params.filter as any)[p]?.map((sales) => {
            if (sales.value) val.push(sales.value);
            return;
          });
          if (val.length > 0) {
            data.append('salesPerson', val.toString());
          }
        }
      });
    }

    const response = await http.postAsFormData<ApiResponse<VisitReportCustomer>>(
      url,
      data
    );
    return response?.data;
  }

}