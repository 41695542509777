import { ICartData } from '../../../models/order-models';
import {
  createAction,
  createActionWithPayload,
  IAction,
  IActionWithPayload,
} from '../utils';

// action types
// Add
const CART_ADD_ACTION = 'CART/ADD_ACTION';
// Update
const CART_UPDATE_ACTION = 'CART/UPDATE_ACTION';
// GET
const CART_GET_ACTION = 'CART/GET_ACTION';
// RESET
const CART_RESET_DATA = 'CART/RESET_DATA';
// CART OPEN
const CART_OPEN = 'CART/OPEN';
// QUOTE OPEN
const QUOTE_OPEN = 'QUOTE/OPEN';

export interface CartState {
  cart?: ICartData;
  isCartLoading: boolean;
  isCartLoaded: boolean;
  isAdding: boolean;
  cartOpen: boolean;
  quoteOpen: boolean;
}

type CartAddActionType = IActionWithPayload<typeof CART_ADD_ACTION, any>;

type CartGetActionType = IActionWithPayload<typeof CART_GET_ACTION, any>;

type CartResetActionType = IAction<typeof CART_RESET_DATA>;

type Actions = CartAddActionType | CartGetActionType | CartResetActionType;

type CartOpenType = IActionWithPayload<typeof CART_OPEN, any>;

type QuoteOpenType = IActionWithPayload<typeof QUOTE_OPEN, any>;

// initial state
const initialState: CartState = {
  cart: undefined,
  isCartLoading: false,
  isCartLoaded: false,
  isAdding: false,
  cartOpen: false,
  quoteOpen: false,
};

export const cartAddAction = (cart: any): CartAddActionType => {
  return createActionWithPayload(CART_ADD_ACTION, cart);
};

export const cartGetAction = (cart: ICartData): CartGetActionType => {
  return createActionWithPayload(CART_GET_ACTION, cart);
};

export const cartResetAction = (): CartResetActionType => {
  return createAction(CART_RESET_DATA);
};

export const cartOpen = (cartopen: boolean): CartOpenType => {
  return createActionWithPayload(CART_OPEN, cartopen);
};

export const quoteOpen = (quoteopen: boolean): QuoteOpenType => {
  return createActionWithPayload(QUOTE_OPEN, quoteopen);
};

// reducer
export const cartReducer = (
  state: CartState = initialState,
  action: any
): CartState => {
  switch (action.type) {
    // login
    case CART_ADD_ACTION:
      return {
        ...state,
        cart: action.payload,
      };

    case CART_GET_ACTION:
      return {
        ...state,
        cart: action.payload,
        isCartLoaded: true,
      };

    // logout
    case CART_RESET_DATA:
      return {
        ...state,
        cart: undefined,
      };

    // Cart Open
    case CART_OPEN:
      return {
        ...state,
        cartOpen: action.payload,
      };
    // Quote Open
    case QUOTE_OPEN:
      return {
        ...state,
        quoteOpen: action.payload,
      };
    default:
      return state;
  }
};
