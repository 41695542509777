import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  ArabicLocale,
  cartResetAction,
  changeLanguageAction,
  EnglishLocale,
  ILocale,
  logoutAction,
  State,
} from '../../core/store';
import { RouteKeys } from '../routes/route-keys';
import { UserType, UserPermissionTypes } from '../../core/api/models';
import noImg from '../../assets/images/no-company.png';
import './SideMenu.scss';
import { useAuth } from '../../core/hooks';
import { translate } from '../../utils/text-utils';
import { useNotifications } from '../../core/hooks/useNotifications';
interface CustomProps {
  isExpand?: boolean;
  expandToggle: () => void;
  menuVisible?: boolean;
}

enum subMenus {
  payment = 'payment',
  settings = 'settings',
}

const SideMenuMob: React.FunctionComponent<CustomProps> = (
  props: CustomProps
) => {
  const authState = useSelector((state: State) => state.authUser);
  const [subMenu, toggleSubMenu] = React.useState<subMenus | null>(null);
  const { hasRole } = useAuth();
  const subMenuSelection = (e: any, selected: subMenus) => {
    e.preventDefault();
    if (selected === subMenu) {
      toggleSubMenu(null);
    } else {
      toggleSubMenu(selected);
    }
  };
  const settings = useSelector((state: State) => state.settings);
  const dispatch = useDispatch();
  const history = useHistory();
  const { notificationState } = useNotifications();
  const unreadNotificationCount =
    notificationState.notifications?.filter((p) => !p.isRead)?.length ?? 0;
  const onLanguageChange = (language: ILocale) => {
    //  i18n.changeLanguage(language.languageKey);
    dispatch(changeLanguageAction(language.languageID));
    // const h1 = document.getElementsByTagName('html')[0]; // Get the first <h1> element in the document
    // const att = document.createAttribute('dir'); // Create a "class" attribute
    // att.value = language.isRtl ? 'rtl' : 'ltr'; // Set the value of the class attribute
    // h1.setAttributeNode(att);
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };
  return (
    <>
      <div
        className={`position-fixed top-0 left-0 bg-body zIndex-9 w-100 h-100 overflow-auto pb-5`}
      >
        <header className="d-lg-none header-mobile py-4 position-relative mb-4">
          <div className="container-fluid py-3">
            <div className="row gutter-12">
              <div className="col">
                <img
                  src={noImg}
                  className="img-fluid img-cover rounded-circle"
                ></img>
              </div>
              <div className="col-auto">
                <div className="pt-3 mt-1 d-flex align-items-center">
                  <i className="icon-social-media h5 mb-0 text-white mr-2 position-relative">
                    {unreadNotificationCount > 0 && (
                      <span className="noti-count font-xs text-white position-absolute rounded-pill text-center">
                        {unreadNotificationCount}
                      </span>
                    )}
                  </i>
                  <div
                    className={`language-switch rounded-pill d-inline-flex align-items-center text-center position-relative ${
                      settings.locale.languageID === 1 ? 'en' : 'ar'
                    }`}
                    onClick={() => {
                      if (settings.locale.languageID === 1) {
                        onLanguageChange(new ArabicLocale());
                      } else {
                        onLanguageChange(new EnglishLocale());
                      }
                    }}
                  >
                    <span className="en">EN</span>
                    <span className="ar">AR</span>
                  </div>
                </div>
              </div>
              <div className="col-12 py-2">
                <h6 className="text-white mb-0 font-weight-bold">
                  {authState?.profile?.name}
                </h6>
                <p className="text-white font-md mb-0">
                  {authState?.profile?.email}
                </p>
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              history.push(RouteKeys.Offers);
            }}
            className="mob-search position-absolute w-100 bg-white rounded d-flex no-gutters align-items-center p-0 overflow-hidden shadow"
          >
            <div className="px-3 py-2 offer-img">
              <img
                src={require('../../assets/images/offer-img.svg')}
                className="img-fluid"
              />
            </div>
            <div className="pl-2">
              <h6 className="font-base m-0 text-gray-700">
                {translate('Header.Offers')}
              </h6>
              <p className="font-xs mb-0 text-gray-600">
                {translate('Header.AvailLatest')}
              </p>
            </div>
            <i className="icon-chevron-right icon-rtl ml-auto text-gray-700 pr-3 font-weight-bold font-xxs"></i>
          </div>
        </header>
        <div className="header-mob-bottom container-fluid pt-3 pb-5">
          <div className="bg-white shadow p-3 mb-2 rounded">
            <div className="font-xxs mb-3 text-uppercase text-gray-400">
              {translate('Header.requestsOrders')}
            </div>
            {authState?.userType === UserType.Salesperson && (
              <Link
                to="/dashboard"
                className="d-flex align-items-center text-gray-700 mb-3"
              >
                <i className="icon-dashboard mr-3 h5 mb-0"></i>
                {translate('Header.dashboard')}
                <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-xxs"></i>
              </Link>
            )}
            {authState?.userType === UserType.Salesperson &&
              hasRole(UserPermissionTypes.customers_list) && (
                <Link
                  to="/customers"
                  className="d-flex align-items-center text-gray-700 mb-3"
                >
                  <i className="icon-place-order1 mr-3 h5 mb-0"></i>
                  {translate('Header.placeOrderCustomers')}
                  <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-xxs"></i>
                </Link>
              )}
            {authState?.userType === UserType.Customer && (
              <Link
                to="/"
                onClick={() => window.location.reload()}
                className="d-flex align-items-center text-gray-700 mb-3"
              >
                <i className="icon-catalog mr-3 h5 mb-0"></i>
                {translate('sideMenu.catalog')}
                <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-xxs"></i>
              </Link>
            )}
            {hasRole(UserPermissionTypes.customer_orders) && (
              <Link
                to={'/orders'}
                className="d-flex align-items-center text-gray-700 mb-3"
              >
                <i className="icon-my_orders mr-3 h5 mb-0"></i>
                {authState?.userType === UserType.Customer
                  ? translate('sideMenu.myOrders')
                  : translate('sideMenu.customerOrders')}
                <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-xxs"></i>
              </Link>
            )}
            {hasRole(UserPermissionTypes.quotation_list) && (
              <Link
                to="/quotation"
                className="d-flex align-items-center text-gray-700 mb-3"
              >
                <i className="icon-quotation mr-3 h5 mb-0"></i>
                {translate('sideMenu.quotation')}
                <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-xxs"></i>
              </Link>
            )}
            {hasRole(UserPermissionTypes.return_list) && (
              <Link
                to="/returns"
                className="d-flex align-items-center text-gray-700 mb-3"
              >
                <i className="icon-return mr-3 h5 mb-0"></i>
                {translate('sideMenu.returnRequests')}
                <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-xxs"></i>
              </Link>
            )}
            {authState?.userType === UserType.Salesperson &&
              hasRole(UserPermissionTypes.customer_request_view) && (
                <Link
                  to="/customer-requests"
                  className="d-flex align-items-center text-gray-700 mb-3"
                >
                  <i className="icon-new-customer mr-3 h5 mb-0"></i>
                  {translate('sideMenu.newCustomerRequests')}
                  <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-xxs"></i>
                </Link>
              )}
            {/* {authState?.userType === UserType.Salesperson && (
              <Link
                to="/schedule-management"
                className="d-flex align-items-center text-gray-700 mb-3"
              >
                <i className="icon-schedule-management mr-3 h5 mb-0"></i>
                {translate('sideMenu.scheduleManagement')}
                <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-xxs"></i>
              </Link>
            )} */}
            {hasRole(UserPermissionTypes.bulk_order) && (
              <Link
                to="/bulk-order"
                className="d-flex align-items-center text-gray-700"
              >
                <i className="icon-bulk mr-3 h5 mb-0"></i>
                {translate('sideMenu.bulkOrder')}
                <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-xxs"></i>
              </Link>
            )}
          </div>
          {hasRole(UserPermissionTypes.view_duepayments) && (
            <div className="bg-white shadow p-3 mb-2 rounded">
              <div className="font-xxs mb-3 text-uppercase text-gray-400">
                {translate('sideMenu.paymentCenter')}
              </div>
              <Link
                to="/due-payments"
                className="d-flex align-items-center text-gray-700 mb-3"
              >
                <i className="icon-payment-center mr-3 h5 mb-0"></i>
                {translate('sideMenu.duePayments')}
                <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-xxs"></i>
              </Link>
              {hasRole(UserPermissionTypes.list_paymenthistory) && (
                <Link
                  to="/payment-history"
                  className="d-flex align-items-center text-gray-700 mb-3"
                >
                  <i className="icon-payment-history mr-3 h5 mb-0"></i>
                  {translate('sideMenu.paymentHistory')}
                  <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-xxs"></i>
                </Link>
              )}
              {hasRole(UserPermissionTypes.list_creditrequest) && (
                <Link
                  to="/credit-requests"
                  className="d-flex align-items-center text-gray-700 mb-3"
                >
                  <i className="icon-credit-request mr-3 h5 mb-0"></i>
                  {translate('credit.creditRequests')}
                  <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-xxs"></i>
                </Link>
              )}
              <Link
                to="/creditline-extension-request"
                className="d-flex align-items-center text-gray-700"
              >
                <i className="icon-creditline mr-3 h5 mb-0"></i>
                {translate('sideMenu.creditLineExtension')}
                <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-xxs"></i>
              </Link>
              {/* <Link to="" className="d-flex align-items-center text-gray-700">
              <i className="icon-payment-center mr-3 h5 mb-0"></i>
              {translate('sideMenu.creditnote')}
              <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-xxs"></i>
            </Link> */}
            </div>
          )}
          <div className="bg-white shadow p-3 mb-2 rounded">
            <div className="font-xxs mb-3 text-uppercase text-gray-400">
              {translate('sideMenu.accountSettings')}
            </div>
            {authState.userType !== 'Salesperson' && (
              <>
                <Link
                  to="/general-settings"
                  className="d-flex align-items-center text-gray-700 mb-3"
                >
                  <i className="icon-account-settings mr-3 h5 mb-0"></i>
                  {translate('sideMenu.generalSettings')}
                  <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-xxs"></i>
                </Link>
                <Link
                  to="/staff-member"
                  className="d-flex align-items-center text-gray-700 mb-3"
                >
                  <i className="icon-boy mr-3 h5 mb-0"></i>
                  {translate('sideMenu.staff')}
                  <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-xxs"></i>
                </Link>
              </>
            )}
            {hasRole(UserPermissionTypes.view_account_personal_info) &&
              authState.userType === 'Salesperson' && (
                <>
                  <Link
                    to="/account-settings"
                    className="d-flex align-items-center text-gray-700 mb-3"
                  >
                    <i className="icon-account-settings mr-3 h5 mb-0"></i>
                    {translate('sideMenu.profileInformation')}
                    <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-xxs"></i>
                  </Link>
                </>
              )}
            {hasRole(UserPermissionTypes.list_salespersons) && (
              <Link
                to={RouteKeys.ManageSalesPerson}
                className="d-flex align-items-center text-gray-700 mb-3"
              >
                <i className="icon-lock mr-3 h5 mb-0"></i>
                {translate('sideMenu.manageSalesPerson')}
                <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-xxs"></i>
              </Link>
            )}
            <Link
              to="/change-password"
              className="d-flex align-items-center text-gray-700 mb-3"
            >
              <i className="icon-lock mr-3 h5 mb-0"></i>
              {translate('common.changePassword')}
              <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-xxs"></i>
            </Link>
            <Link
              to="/terms-of-use"
              className="d-flex align-items-center text-gray-700"
            >
              <i className="icon-terms mr-3 h5 mb-0"></i>
              {translate('sideMenu.termsOfUse')}
              <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-xxs"></i>
            </Link>
          </div>
          <div className="bg-white shadow p-3 mb-2 rounded">
            <Link to="/faq" className="d-flex align-items-center text-gray-700">
              <i className="icon-faq mr-3 h5 mb-0"></i>
              {translate('sideMenu.faq')}
              <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-xxs"></i>
            </Link>
          </div>
          <div className="bg-white shadow p-3 mb-2 rounded">
            <a
              href="#"
              className="d-flex align-items-center text-gray-700"
              onClick={() => {
                dispatch(logoutAction());
                dispatch(cartResetAction());
                history.push(RouteKeys.Login);
              }}
            >
              <i className="icon-logout mr-3 h5 mb-0"></i>
              {translate('sideMenu.logOut')}
              <i className="icon-chevron-right icon-rtl ml-auto font-weight-bold font-xxs"></i>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideMenuMob;
