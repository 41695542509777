import React, { useEffect } from 'react';
import './Higher.scss';
import '../../assets/sass/listing.scss';
import { UserHeader } from '../header';
import api from '../../api';
import classNames from 'classnames';
import { translate } from '../../utils/text-utils';
import { useHistory } from 'react-router-dom';
export enum TabNames {
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
}
const Higher: React.FunctionComponent = () => {
  const history = useHistory();
  const [higherRequest, setHigherRequest] = React.useState<any[]>([]);
  const [higherEstRequest, setHigherEstRequest] = React.useState<any[]>([]);
  const [higherRejRequest, setHigherRejRequest] = React.useState<any[]>([]);

  const [tabName, setTabName] = React.useState(TabNames.Pending);
  const [listSearch, toggleListSearch] = React.useState<boolean>(false);

  const setHigherData = () => {
    api.model
      .getHigherRequest('Pending')
      .then((items: any) => setHigherRequest(items));
  };
  const sethigherEstData = () => {
    api.model
      .getHigherRequest('Approved')
      .then((items: any) => setHigherEstRequest(items));
  };
  const setHigherRejData = () => {
    api.model
      .getHigherRequest('rejected')
      .then((items: any) => setHigherRejRequest(items));
  };
  useEffect(() => {
    setHigherData();
    sethigherEstData();
    setHigherRejData();
  }, []);

  return (
    <>
      <UserHeader menuVisible />

      <div className="inner-wrapper">
        <div className="list-head">
          <div className="px-lg-4 px-3 py-2 border-bottom">
            <div className="row gutter-10 align-items-center py-2">
              <div className="col-auto d-md-none pr-0">
                <i className="icon-arrow-left font-xxl icon-rtl font-weight-bold" onClick={() => { history.goBack(); }}></i>
              </div>
              <div className="col">
                <h5 className="m-0">{translate("higher.High_Value_Order_Requests")} </h5>
              </div>
              <div className="col-auto d-lg-none">
                <span className="font-weight-medium text-primary cursor-pointer" onClick={() => toggleListSearch(!listSearch)}>
                  {listSearch === true ? (
                    <i className="icon-cancel font-sm"></i>
                  ) : (
                      <i className="icon-search"></i>
                    )}
                </span>
              </div>
              <div className={`col-md-auto col-12 mt-md-0 mt-3 list-search ${listSearch === true ? 'active' : ''}`}>
                <div className="filter-search">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control border-0 pr-0 font-md text-lg-right"
                      placeholder="User Name/Request ID"
                    />
                    <div className="input-group-append">
                      <input
                        type="submit"
                        className="form-control-search btn btn-link border-0 bg-transparent p-0"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={`col-md-auto col-12 mt-md-0 mt-3 list-search ${listSearch === true ? 'active' : ''}`}>
                <div className="filter-date">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control border-0 pr-0 font-md text-lg-right"
                      placeholder="Select Date Range"
                    />
                    <div className="input-group-append">
                      <input
                        type="submit"
                        className="form-control-date btn btn-link border-0 bg-transparent px-md-3 px-4 py-0 "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tab-block border-bottom px-lg-2">
          <ul className="nav flex-nowrap overflow-auto p-0">
            <li
              className={classNames({
                active: tabName === TabNames.Pending,
                'd-md-block d-flex align-items-center': true
              })}
              onClick={() => {
                setTabName(TabNames.Pending);
              }}
            >
              <h4 className="m-0 p-0 d-lg-block d-none">{higherRequest.length}</h4>
              <p className="font-sm font-weight-medium m-0 p-0 text-black-50">
                {TabNames.Pending} <span className="d-lg-none">({higherRequest.length})</span>
              </p>
            </li>
            <li
              className={classNames({
                active: tabName === TabNames.Approved,
                'd-md-block d-flex align-items-center': true
              })}
              onClick={() => {
                setTabName(TabNames.Approved);
              }}
            >
              <h4 className="m-0 p-0 d-lg-block d-none">{higherEstRequest.length}</h4>
              <p className="font-sm font-weight-medium m-0 p-0 text-black-50">
                {TabNames.Approved} <span className="d-lg-none">({higherEstRequest.length})</span>
              </p>
            </li>
            <li
              className={classNames({
                active: tabName === TabNames.Rejected,
                'd-md-block d-flex align-items-center': true
              })}
              onClick={() => {
                setTabName(TabNames.Rejected);
              }}
            >
              <h4 className="m-0 p-0 d-lg-block d-none">{higherRejRequest.length}</h4>
              <p className="font-sm font-weight-medium m-0 p-0 text-black-50">
                {TabNames.Rejected} <span className="d-lg-none">({higherRejRequest.length})</span>
              </p>
            </li>
          </ul>
        </div>
        {/* <div className="px-lg-4 px-3 pb-lg-0 pb-2 filter-selected">
                            <div className="row gutter-12 align-items-center justify-content-end">
                                <div className="col-lg-auto col-12 order-lg-last">
                                    <div className="row gutter-12 align-items-center">
                                        <div className="col d-lg-none d-flex">
                                            <div className="text-primary font-xs d-flex align-items-center mr-3 py-md-3 py-2">
                                                <i className="icon-tool font-sm"></i><span className="ml-2">Filter:<span className="font-weight-bold text-uppercase ml-1">All</span></span>
                                            </div>
                                            <div className="filter-add">
                                                <div className="dropdown dropdown-add-filter">
                                                    <span className="dropdown-click cursor-pointer text-uppercase font-xs font-weight-bold py-3 d-inline-block">Add
                                                        Filter</span>
                                                        <div className="dropdown-menu dropdown-shadow border-0 w-100 p-0">
                                                            <div className="position-relative">
                                                            <div className="d-flex filter-head justify-content-between p-3">
                                                                <p className="font-md font-weight-medium m-0">Filter by Status</p>
                                                                <span className="font-sm cursor-pointer"><i className="icon-cancel"></i></span>
                                                            </div>
                                                                <ul className="list-unstyled pt-3 px-3 m-0 font-weight-semibold">
                                                                    <li className="custom-checkbox py-2">
                                                                        <input type="checkbox" value="" id="checkone1"/>
                                                                        <label className="font-md font-weight-medium form-check-label text-body" htmlFor="checkone1">
                                                                        Pending
                                                                        </label>
                                                                    </li>
                                                                    <li className="custom-checkbox py-2">
                                                                        <input type="checkbox" value="" id="checktwo2"/>
                                                                        <label className="font-md font-weight-medium form-check-label text-body" htmlFor="checktwo2">
                                                                        Approved
                                                                        </label>
                                                                    </li>
                                                                    <li className="custom-checkbox py-2">
                                                                        <input type="checkbox" value="" id="checkthree3"/>
                                                                        <label className="font-md font-weight-medium form-check-label text-body" htmlFor="checkthree3">
                                                                        Rejected
                                                                        </label>
                                                                    </li>
                                                                </ul>
                                                                <div className="px-3 pb-3 pt-2 text-right">
                                                                    <input className="btn btn-primary btn-add" value="APPLY" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                </div>
                                            </div>
                                        </div>
                                    
                                    </div>
                                </div>
                                <div className="col-lg col-12 overflow-lg-auto">
                                    <div className="row gutter-5 align-items-start">
                                        <div className="col-md-auto col-12 d-lg-block d-none">
                                            <div className=" font-xs d-flex align-items-center mr-2 py-md-3 py-2">
                                                <i className="icon-tool font-md text-primary"></i><span className="ml-2 text-uppercase">Filter:<span className="font-weight-bold text-uppercase ml-1">All</span></span>
                                            </div>
                                        </div>
                                        <div className="col-md col-12">
                                            <ul className="filter-choices row gutter-5 align-items-center flex-lg-wrap flex-nowrap list-unstyled p-0 mb-0">
                                                <li className="col-auto filter-choice py-1">
                                                    <div className="font-xs bg-primary rounded-pill text-white text-nowrap font-weight-semibold d-flex align-items-center p-2">
                                                        <span className="filter-hd px-1">filter Type: </span>Filter<span className="filter-choice-delete icon-error font-base pl-2 cursor-pointer"></span>
                                                    </div>
                                                </li>
                                                <li className="col-auto filter-choice py-1">
                                                    <div className="font-xs bg-primary rounded-pill text-white text-nowrap font-weight-semibold d-flex align-items-center p-2">
                                                        <span className="filter-hd px-1">filter Type: </span>Filter<span className="filter-choice-delete icon-error font-base pl-2 cursor-pointer"></span>
                                                    </div>
                                                </li>
                                                <li className="col-auto filter-add d-lg-block d-none">
                                                    <div className="dropdown dropdown-add-filter">
                                                        <span className="dropdown-click cursor-pointer text-uppercase font-xs font-weight-semibold py-3 d-inline-block text-primary font-weight-medium">Add
                                                            Filter</span>
                                                        <div className="dropdown-menu dropdown-shadow border-0 w-100 p-0">
                                                            <div className="position-relative">
                                                            <div className="d-flex filter-head justify-content-between p-3">
                                                                <p className="font-md font-weight-medium m-0">Filter by Status</p>
                                                                <span className="font-sm cursor-pointer"><i className="icon-cancel"></i></span>
                                                            </div>
                                                                <ul className="list-unstyled pt-3 px-3 m-0 font-weight-semibold">
                                                                    <li className="custom-checkbox py-2">
                                                                        <input type="checkbox" value="" id="checkone"/>
                                                                        <label className="font-md font-weight-medium form-check-label text-body" htmlFor="checkone">
                                                                        Pending
                                                                        </label>
                                                                    </li>
                                                                    <li className="custom-checkbox py-2">
                                                                        <input type="checkbox" value="" id="checktwo"/>
                                                                        <label className="font-md font-weight-medium form-check-label text-body" htmlFor="checktwo">
                                                                        Approved
                                                                        </label>
                                                                    </li>
                                                                    <li className="custom-checkbox py-2">
                                                                        <input type="checkbox" value="" id="checkthree"/>
                                                                        <label className="font-md font-weight-medium form-check-label text-body" htmlFor="checkthree">
                                                                        Rejected
                                                                        </label>
                                                                    </li>
                                                                </ul>
                                                                <div className="px-3 pb-3 pt-2 text-right">
                                                                    <input className="btn btn-primary btn-add" value="APPLY" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}

        {higherRequest.length > 0 && (
          <>
            {tabName === TabNames.Pending && (
              <div className="table-block grid-table">
                <table className="table table-bordered ">
                  <tr>
                    <th>{translate("higher.requestID")}</th>
                    <th>{translate("higher.Product")}</th>
                    <th>{translate("higher.Part_Number")}</th>
                    <th>{translate("higher.Quantity_Requested")}</th>
                    <th></th>
                  </tr>
                  {higherRequest.map((pdt, ind) => {
                    return (
                      <tr>
                        <td>{pdt.highOrderID}</td>
                        <td>{pdt.productTitle}</td>
                        <td>{pdt.productID}</td>
                        <td>{pdt.orderQty} {translate("higher.Items")}</td>
                        <td></td>
                      </tr>
                    );
                  })}

                  <tr></tr>
                </table>
              </div>
            )}
          </>
        )}
        {higherEstRequest.length > 0 && (
          <>
            {tabName === TabNames.Approved && (
              <div className="table-block grid-table">
                <table className="table table-bordered ">
                  <tr>
                    <th>{translate("higher.requestID")}</th>
                    <th>{translate("higher.Requested_By_(userID)")}</th>
                    <th>{translate("higher.Requested_Date")}</th>
                    <th>{translate("higher.Items")}</th>
                    <th></th>
                  </tr>
                  {higherRequest.map((pdt, ind) => {
                    return (
                      <tr>
                        <td>{pdt.quotationRequestID}</td>
                        <td>{pdt.userID}</td>
                        <td>{pdt.createdDate}</td>
                        <td>{pdt.totalQty}{translate("higher.Items")}</td>
                        <td></td>
                      </tr>
                    );
                  })}

                  <tr></tr>
                </table>
              </div>
            )}
          </>
        )}
        {higherRejRequest.length > 0 && (
          <>
            {tabName === TabNames.Rejected && (
              <div className="table-block grid-table">
                <table className="table table-bordered ">
                  <tr>
                    <th>{translate("higher.requestID")}</th>
                    <th>{translate("higher.Requested_By_(userID)")}</th>
                    <th>{translate("higher.Requested_Date")}</th>
                    <th>{translate("higher.Items")}</th>
                    <th></th>
                  </tr>
                  {higherRequest.map((pdt, ind) => {
                    return (
                      <tr>
                        <td>{pdt.quotationRequestID}</td>
                        <td>{pdt.userID}</td>
                        <td>{pdt.createdDate}</td>
                        <td>{pdt.totalQty} {translate("higher.Items")}</td>
                        <td></td>
                      </tr>
                    );
                  })}

                  <tr></tr>
                </table>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Higher;
