import * as React from 'react';
import { DropdownList } from 'react-widgets';
import { ProductFilter, ProductFilterValues } from '../../core/api/models';
import { useProduct } from '../../core/hooks';
import { useTranslation } from 'react-i18next';

interface ActionModalProps {
  onClose?: () => void;
  onSelect?: (filter: ProductFilter[]) => void;
  productFilters?: ProductFilter[];
  selectedFilter?: ProductFilter[];
  filterOpen?: boolean;
  // onPopupClose: () => void;
}

const ProductFilterPopUp: React.FunctionComponent<ActionModalProps> = (
  props
) => {
  const { t } = useTranslation();
  const {
    setProductFilter,
    selectedFilter: proFilter,
    isProductLoading,
  } = useProduct();
  const [selectedFilter, setSelectedFilter] = React.useState<
    ProductFilter[] | undefined
  >(props.productFilters);

  React.useEffect(() => {
    setSelectedFilter(props.productFilters);
  }, [props.productFilters]);

  React.useEffect(() => {
    setSelectedFilter(proFilter);
  }, [proFilter]);

  return (
    <>
      <div
        className={`custom-scroll-vertical detail-poupup bg-white position-fixed top-0 w-100 h-100 overflow-auto in ${
          props?.filterOpen && !!props?.productFilters?.length ? 'active' : ''
          }`}
      >
        <div className="position-sticky top-0 zIndex-9 detail-poupup__head bg-white px-lg-4 px-3 py-4">
          <div className="row align-items-center">
            <div className="col">
              <h6 className="mb-0">{t('common.filterBy')}</h6>
            </div>
            <div
              className="col-auto"
              onClick={() => {
                props.onClose();
                // window.location.reload();
              }}
            >
              <i className="icon-close text-gray-700 font-md cursor-pointer"></i>
            </div>
          </div>
        </div>
        <div className="px-lg-4 p-3">
          <div className="row py-1">
            {!!props?.productFilters?.length &&
              props?.productFilters?.map((filter, index) => {
                return (
                  <div
                    className="col-xl-12 col-sm-12 col-12"
                    key={`${filter.key}_${index}`}
                  >
                    <label
                      htmlFor=""
                      className="font-md font-weight-medium text-gray-900 mb-1 text-capitalize"
                    >
                      {filter.name}
                    </label>
                    <div className="textField">
                      <DropdownList
                        data={filter?.values}
                        textField={'value'}
                        valueField={'idx'}
                        placeholder={`Select ${filter.name}`}
                        value={
                          props.selectedFilter?.find(
                            (x) => x.key === filter.key
                          )?.value
                        }
                        onChange={(val: ProductFilterValues) => {
                          const selectedFil: ProductFilter[] = [
                            ...(selectedFilter || []),
                          ];
                          setSelectedFilter(
                            selectedFil?.map((p: ProductFilter) => {
                              return {
                                ...p,
                                value: p.key === filter.key ? val.idx : p.value,
                              };
                            })
                          );

                          props.onSelect?.(
                            selectedFil?.map((p: ProductFilter) => {
                              return {
                                ...p,
                                value: p.key === filter.key ? val.idx : p.value,
                              };
                            })
                          );
                        }}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="position-sticky bottom-0 px-4 py-2 border-top d-lg-none bg-white">
          <button
            className="btn btn-primary btn-block"
            onClick={() => {
              props.onClose();
              // window.location.reload();
            }}
          >
            Close
          </button>
        </div>
      </div>
      {/* <div className="modal-backdrop fade show"></div> */}
    </>
  );
};

export default ProductFilterPopUp;
